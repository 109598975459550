import {Component, Inject} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from "../../modules/material/material.module";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {CaseService} from "../../services/case.service";
import {Router} from "@angular/router";
import {MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBar} from "@angular/material/snack-bar";


@Component({
  selector: 'app-confirm-case-delete',
  standalone: true,
  imports: [CommonModule, MaterialModule],
  providers: [
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {
      duration: 2500}}
  ],
  templateUrl: './confirm-case-delete.component.html',
  styleUrls: ['./confirm-case-delete.component.scss']
})
export class ConfirmCaseDeleteComponent {
  public snackBarDuration: number = 5000; // seconds
  public title: string = (this?.data?.title ?? 'No title');
  public description: string = (this?.data?.description ?? 'No title');
  private pk_case_id: number = (this?.data?.pk_case_id ?? -1);

  constructor (public dialogRef: MatDialogRef<ConfirmCaseDeleteComponent>,
               @Inject(MAT_DIALOG_DATA) public data: {pk_case_id: number, title: string, description: string},
               private caseService: CaseService, private router: Router, private snackBar: MatSnackBar) { }

  public deleteCase(): void {
    try {
      const snackbarRef = this.snackBar.open('Deleting case', '', {
        duration: 3000
      });
      this.caseService.deleteCase(this.data.pk_case_id).subscribe( (deleteResult: any) => {
        if (deleteResult.hasOwnProperty('msg') && deleteResult.msg === 'error') {
          snackbarRef.dismiss();
          this.snackBar.open('Error Deleting the Case', '', {
            duration: 5000
          })
        }
      });
      this.dialogRef.close();
      this.router.navigate(['/search-cases']);

    } catch (e) {
      this.snackBar.open('Error Deleting the Case', '', {
        duration: 5000
      });
    }
  }


}
