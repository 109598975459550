<div class="add-case-container" [class.white-background]="spinnerShow">
    <ng-template cdkConnectedOverlay [cdkConnectedOverlayOpen]="spinnerShow">
      <mat-progress-spinner *ngIf="spinnerShow"
                            class="spinner-overlay"
                            [color]="color"
                            [mode]="mode">
      </mat-progress-spinner>
    </ng-template>

    <p class="flex-row margin-sm-bottom">
      <button mat-stroked-button routerLink="/search-cases" color="primary">Back to My Cases</button>
    </p>
    <section class="margin-sm-bottom margin-sm-right case-details-heading">
        <h1 class="bold-headline--serif">Add a New Case</h1>
          <h2 class="institution-heading"  *ngIf="!currentUserService.canAddAssignAllCase$.value && !currentUserService.canAssignOwnCase$.value && !currentUserService.canAddEditAllUI$.value">
            <i>{{ currentUserService.currentUser.institution_value }}</i>
          </h2>
    </section>

    <!-- Case Classification -->
    <section class="case-details margin-top" [formGroup]="detailsGroup">
        <h2>Case Classification</h2>
        <hr>
        <div class="flex-column">
          <section>
            <h3 class="offscreen">Case Classification</h3>
            <mat-form-field class="width-md-50">
              <mat-label>Case Classification</mat-label>
              <mat-select formControlName="classification">
                <mat-option *ngFor="let data of listDataService.classifications$.value" [value]="data.value"
                            (click)="updateField(detailsGroup,'classification', data.value, data.name)"
                >
                  {{ data.name }}
                </mat-option>
              </mat-select>
              <mat-error>Required</mat-error>
            </mat-form-field>
          </section>
          <section>
            <h3 class="offscreen">{{ typesAvailable ? 'Type' : 'No Types Available'}}</h3>
            <mat-form-field class="width-md-50">
              <mat-label>{{ typesAvailable ? 'Case Type' : 'No Types Available'}}</mat-label>
              <mat-select
                formControlName="type"
              >
                <mat-option
                  *ngFor="let data of listDataService.typeValues$ | async"
                  [value]="data.value"
                  (click)="updateField(detailsGroup,'type', data.value, data.name)"
                >
                  {{ data.name }}
                </mat-option>
              </mat-select>
              <mat-error>Required</mat-error>
            </mat-form-field>
          </section>
          <section *ngIf="currentUserService.canAddEditUsers$ | async">
            <h3 class="offscreen">{{ subTypesAvailable ? 'Subtype(s)' : 'No Subtypes Available'}}</h3>
          <mat-form-field class="width-md-50">
            <mat-label>{{ subTypesAvailable ? 'Subtype(s)' : 'No Subtypes Available'}}</mat-label>
            <mat-select
              multiple
              formControlName="subtypes"
            >
              <mat-option
                *ngFor="let data of listDataService.subtypeValues$ | async"
                [value]="data.value"
                (click)="updateField(detailsGroup,'subtypes', data.value)"
              >
                {{ data.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          </section>
        </div>
    </section>

    <!-- Case Details -->
    <section class="case-details" [formGroup]="detailsGroup">
      <h2>Case Details</h2>
      <hr>

      <div class="flex-column">
        <section>
          <h3 class="offscreen">Case Title</h3>
            <mat-form-field class="width-md-50">
              <mat-label>Case Title</mat-label>
              <input
                matInput
                formControlName="title">
              <mat-error>Required</mat-error>
            </mat-form-field>
        </section>

        <section>
          <h3 class="offscreen">Description</h3>
            <mat-form-field class="width-md-50">
              <mat-label>Description</mat-label>
              <textarea
                matInput
                formControlName="description">
                      </textarea>
              <mat-error>Required</mat-error>
            </mat-form-field>
        </section>

        <section>
          <h3 class="offscreen">Closed</h3>
            <mat-checkbox
              color="primary"
              formControlName="is_closed"
            >
              Closed
            </mat-checkbox>
        </section>

        <section *ngIf="(currentUserService.canAddAssignAllCase$ | async) || (currentUserService.canAssignOwnCase$ | async) || (currentUserService.canAddEditAllUI$ | async)">
          <h3 class="offscreen">Institution(s)</h3>
            <mat-form-field class="width-md-50">
              <mat-label>Institution(s)</mat-label>
              <mat-select multiple formControlName="institutions">
                <ng-container *ngFor="let data of listDataService.institutions$.value">
                  <mat-option [value]="data.value" *ngIf="currentUserService.canAssignInstitution(data.value)"
                              >
                      {{ data.name }}
                  </mat-option>
                </ng-container>
              </mat-select>
              <mat-error>Required</mat-error>
            </mat-form-field>
        </section>

        <section>
          <h3 class="offscreen">Date Filed / Submitted</h3>
            <mat-form-field class="margin-sm-bottom width-md-50">
              <mat-label>Date Filed / Submitted</mat-label>
              <input matInput [matDatepicker]="filedPicker" formControlName="filed_date" [max]="detailsGroup.get('resolution_date')?.value"
              >
              <mat-hint>MM/DD/YYYY</mat-hint>
              <mat-error>Required</mat-error>
              <mat-datepicker-toggle matIconSuffix [for]="filedPicker"></mat-datepicker-toggle>
              <mat-datepicker #filedPicker></mat-datepicker>
            </mat-form-field>
        </section>

        <section>
          <h3 class="offscreen">Date Resolution / Denial</h3>
            <mat-form-field class="width-md-50">
              <mat-label>Date Resolution / Denial</mat-label>
              <input matInput [matDatepicker]="resPicker" formControlName="resolution_date" [min]="detailsGroup.get('filed_date')?.value"
              >
              <mat-hint>MM/DD/YYYY</mat-hint>
              <mat-datepicker-toggle matIconSuffix [for]="resPicker"></mat-datepicker-toggle>
              <mat-datepicker #resPicker></mat-datepicker>
            </mat-form-field>
        </section>

      </div>
    </section>

    <!-- Litigation: Lawsuit & Tort Details-->
    <section class="case-type" [formGroup]="litigationsGroup" *ngIf="showLitigations">
        <h2>Lawsuit & Tort Details</h2>
        <hr>
        <mat-tab-group mat-align-tabs="start" mat-stretch-tabs="false">
            <mat-tab label="Identifying Info">
              <section class="margin-top">
                <h3 class="margin-sm-bottom">Opposing Counsel</h3>
                <div class="flex-md-row">
                  <section class="width-md-50 margin-sm-right">
                    <h4 class="offscreen">Law Firm</h4>
                      <mat-form-field class="width-100">
                        <mat-label>Law Firm</mat-label>
                        <input
                          formControlName="opposing_counsel_law_firm"
                          matInput
                        />
                      </mat-form-field>
                  </section>
                  <section class="flex-md-row width-md-50">
                    <section class="width-md-50 margin-sm-right">
                      <h4 class="offscreen">Attorney</h4>
                      <mat-form-field class="margin-sm-right width-md-100">
                        <mat-label>Attorney</mat-label>
                        <input
                          formControlName="opposing_counsel_attorney"
                          matInput
                        />
                      </mat-form-field>
                    </section>
                    <section class="width-md-50">
                      <h4 class="offscreen">Phone Number</h4>
                      <mat-form-field class="width-md-100">
                        <mat-label>Phone Number</mat-label>
                        <input
                          formControlName="opposing_counsel_phone_number"
                          matInput
                          appPhone
                        />
                        <mat-error>Invalid phone number</mat-error>
                      </mat-form-field>
                    </section>
                  </section>
                </div>
              </section>

              <section class="flex-md-row">
                <h3 class="offscreen">Opposing Counsel Address</h3>
                <section class="margin-sm-right width-md-50">
                  <h4 class="offscreen">Address</h4>
                  <mat-form-field class="width-md-100">
                    <mat-label>Address</mat-label>
                    <input
                      formControlName="opposing_counsel_address"
                      matInput
                    />
                  </mat-form-field>
                </section>
                <section class="flex-md-row width-md-50">
                  <section class="width-md-50 margin-sm-right">
                    <h4 class="offscreen">City</h4>
                  <mat-form-field class="margin-sm-right width-md-100">
                    <mat-label>City</mat-label>
                    <input
                      formControlName="opposing_counsel_city"
                      matInput
                    />
                  </mat-form-field>
                  </section>
                  <section class="width-md-50">
                    <h4 class="offscreen">State</h4>
                  <mat-form-field class="width-md-100">
                    <mat-label>State</mat-label>
                    <input
                      formControlName="opposing_counsel_state"
                      matInput
                    />
                  </mat-form-field>
                  </section>
                </section>
              </section>

              <section class="flex-md-row">
                <h3 class="offscreen">Plaintiffs</h3>
                <section class="margin-sm-right width-md-50">
                  <h4 class="offscreen">Plaintiff</h4>
                  <mat-form-field class="width-100">
                    <mat-label>Plaintiff</mat-label>
                    <input
                      matInput
                      formControlName="plaintiff"
                    />
                  </mat-form-field>
                </section>
                <section class="width-md-50">
                  <h4 class="offscreen">Other Plaintiff</h4>
                  <mat-form-field class="width-100">
                    <mat-label>Other Plaintiff</mat-label>
                    <input
                      matInput
                      formControlName="other_plaintiff"
                    />
                  </mat-form-field>
                </section>
              </section>

              <section class="flex-md-row">
                <h3 class="offscreen">Defendants</h3>
                <section class="width-md-50 margin-sm-right">
                  <h4 class="offscreen">Defendant</h4>
                  <mat-form-field class="width-100">
                    <mat-label>Defendant</mat-label>
                    <mat-select [value]="caseService.litigations.defendant"
                                formControlName="defendant"
                                >
                        <mat-option
                          [value]="">
                        </mat-option>
                        <mat-option *ngFor="let data of listDataService.institutions$.value" [value]="data.name"
                                    >
                        {{ data.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                </section>
                <section class="width-md-50">
                  <h4 class="offscreen">Other Defendant</h4>
                  <mat-form-field class="width-100">
                    <mat-label>Other Defendant</mat-label>
                    <input
                      matInput
                      formControlName="other_defendant"
                    />
                  </mat-form-field>
                </section>
              </section>
            </mat-tab>
            <mat-tab label="Claim Details">
              <section class="margin-top">
                <h3 class="offscreen">Court Assigned Number</h3>
                <mat-form-field class="width-md-50">
                  <mat-label>Court Assigned Number</mat-label>
                  <input
                    matInput
                    formControlName="court_assigned_number"
                  />
                </mat-form-field>
              </section>

              <section class="flex-md-row">
                <h3 class="offscreen">Claim Number</h3>
                <div class="flex-md-row width-md-50">
                  <div class="width-md-50 margin-sm-right">
                    <mat-form-field class="width-md-100">
                      <mat-label>Claim Number</mat-label>
                      <mat-select formControlName="fk_admin_claim_id" (keyup.enter)="setAdminClaimData(claim_number)"
                                  #claim_number>
                        <mat-option
                          (click)="setAdminClaimData(blankAdminClaimData)"
                          [value]="">
                        </mat-option>
                        <mat-option *ngFor="let data of listDataService.claimNumbers$?.value" [value]="data.pk_administrative_claim_id" (click)="setAdminClaimData(data)"
                        >
                          <mat-list>
                            <mat-list-item class="pointer">
                              <span matListItemTitle>{{ data.claim_number }} </span>
                              <span matListItemLine class="option-ellipsis">{{ data.case_title }}</span>
                            </mat-list-item>
                          </mat-list>
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="width-md-50">
                    <p class="width-100" *ngIf="adminClaimData.claim_number">
                      <button mat-button color="primary" (click)="previewAdminClaim()" (keyup)="previewAdminClaim()">
                        Preview Claim
                      </button>
                    </p>
                  </div>
                </div>
              </section>

              <div class="flex-md-row">
                <div class="flex-md-row width-md-50">
                  <section class="width-md-50 margin-sm-right">
                    <h3 class="offscreen">Mediation Date</h3>
                    <mat-form-field class="width-100">
                      <mat-label>Mediation Date</mat-label>
                      <input matInput [matDatepicker]="mediationDate"  formControlName="mediation_date">
                      <mat-hint>MM/DD/YYYY</mat-hint>
                      <mat-datepicker-toggle matIconSuffix [for]="mediationDate"></mat-datepicker-toggle>
                      <mat-datepicker #mediationDate></mat-datepicker>
                    </mat-form-field>
                  </section>

                  <section class="width-md-50">
                    <h3 class="offscreen">Trial Date</h3>
                    <mat-form-field class="width-100">
                      <mat-label>Trial Date</mat-label>
                      <input matInput [matDatepicker]="trialDate"  formControlName="trial_date">
                      <mat-hint>MM/DD/YYYY</mat-hint>
                      <mat-datepicker-toggle matIconSuffix [for]="trialDate"></mat-datepicker-toggle>
                      <mat-datepicker #trialDate></mat-datepicker>
                    </mat-form-field>
                  </section>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="Venue / Jurisdiction / Remedy">
              <section class="flex-md-row margin-top margin-sm-bottom">
                <h3 class="offscreen">Appeal to Court Filed</h3>
                <mat-checkbox
                  color="primary"
                  formControlName="is_court_appeal_filed"
                >
                  Appeal to Court Filed
                </mat-checkbox>
              </section>
              <div class="grid-2-50-row grid-col-gap-sm">
                <section class="width-100">
                  <h3 class="offscreen">Venue</h3>
                  <mat-form-field class="width-100">
                    <mat-label>Venue</mat-label>
                    <input
                      matInput
                      formControlName="venue"
                    />
                  </mat-form-field>
                </section>
                <section class="width-100">
                  <h3>Jurisdiction</h3>
                  <mat-radio-group formControlName="jurisdiction">
                    <mat-radio-button
                      color="primary"
                      *ngFor="let jurisdiction of jurisdictions"
                      [value]="jurisdiction"
                    >
                      {{ jurisdiction }}
                    </mat-radio-button>
                  </mat-radio-group>
                </section>
              </div>
              <div class="grid-2-50-row grid-col-gap-sm">
                <section class="width-100">
                  <h3 class="offscreen">Remedy Sought</h3>
                  <mat-form-field class="width-100">
                    <mat-label>Remedy Sought</mat-label>
                    <input
                      matInput
                      formControlName="remedy_sought"
                    />
                  </mat-form-field>
                </section>
                <section class="width-md-50">
                  <h3 class="offscreen">Remedy Amount</h3>
                  <mat-form-field class="width-100" floatLabel="always">
                    <mat-label>Remedy Amount</mat-label>
                    <input
                      matInput
                      formControlName="remedy_amount"
                      type="number"
                      placeholder="0"
                      class="currency-input"
                    />
                    <span matTextPrefix>$&nbsp;</span>
                  </mat-form-field>
                </section>
              </div>
              <div class="grid-2-50-row grid-col-gap-sm">
                <section class="width-100">
                  <h3 class="offscreen">Settlement Funding Source</h3>
                  <mat-form-field class="width-100">
                    <mat-label>Settlement Funding Source</mat-label>
                    <input
                      matInput
                      formControlName="settlement_funding_src"
                      type="text"
                    />
                  </mat-form-field>
                </section>
                <section class="width-md-50">
                  <h3 class="offscreen">Settlement Amount</h3>
                  <mat-form-field class="width-100" floatLabel="always">
                    <mat-label>Settlement Amount</mat-label>
                    <input
                      matInput
                      formControlName="settlement_amount_paid"
                      type="number"
                      placeholder="0"
                      class="currency-input"
                    />
                    <span matTextPrefix>$&nbsp;</span>
                  </mat-form-field>
                </section>
              </div>
              <div class="grid-2-50-row grid-col-gap-sm">
                <section class="width-100">
                  <h3 class="offscreen">Case Disposition</h3>
                  <mat-form-field class="width-100">
                    <mat-label>Case Disposition</mat-label>
                    <textarea
                      matInput
                      formControlName="case_disposition">
                                </textarea>
                  </mat-form-field>
                </section>
              </div>


            </mat-tab>
            <mat-tab label="Counsel">
            <section class="margin-top margin-sm-bottom">
              <h3 class="offscreen">Outside Counsel Required</h3>
              <mat-checkbox
                color="primary"
                formControlName="is_outside_counsel_required"
              >
                Outside Counsel Required
              </mat-checkbox>
            </section>
            <section class="margin-sm-bottom">
              <h3 class="offscreen">Recommended Outside Counsel</h3>
                <mat-form-field class="width-100">
                  <mat-label>Recommended Outside Counsel</mat-label>
                  <input
                    matInput
                    formControlName="recommended_outside_counsel"
                  />
                </mat-form-field>
            </section>
            <section class="margin-sm-bottom">
              <h3 class="offscreen">Justification of Outside Counsel</h3>
                <mat-form-field class="width-100">
                  <mat-label>Justification of Outside Counsel</mat-label>
                  <textarea
                    matInput
                    formControlName="outside_counsel_justification">
                  </textarea>
                </mat-form-field>
            </section>
            <section class="flex-sm-row">
              <h3 class="offscreen">Outside Counsel Fees</h3>
              <section class="margin-sm-right width-md-50">
                <h4 class="offscreen">Outside Counsel Fees Paid/Rate</h4>
                <mat-form-field class="width-100">
                  <mat-label>Outside Counsel Fees Paid/Rate</mat-label>
                  <input
                    matInput
                    formControlName="outside_counsel_fees"
                  />
                </mat-form-field>
              </section>
              <section class="width-md-50">
                <h4 class="offscreen">Amount Paid to Outside Counsel</h4>
                <mat-form-field class="width-50" floatLabel="always">
                  <mat-label>Amount Paid to Outside Counsel</mat-label>
                  <input
                    matInput
                    formControlName="outside_counsel_amount_paid"
                    type="number"
                    placeholder="0"
                    class="currency-input"
                  />
                  <span matTextPrefix>$&nbsp;</span>
                </mat-form-field>
              </section>
            </section>
            <section class="flex-sm-row">
              <h3 class="offscreen">Attorneys</h3>
              <section class="margin-sm-right width-md-50" [formGroup]="detailsGroup">
                <h4 class="offscreen">In House Attorney(s)</h4>
                <mat-form-field class="width-md-100">
                  <mat-label>In House Attorney(s)</mat-label>
                  <mat-select
                    multiple
                    formControlName="in_house_attorneys"
                  >
                    <mat-option
                      *ngFor="let data of listDataService.attorneys$?.value" [value]="data.value"
                    >
                      {{ data.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </section>
              <section class="width-md-50">
                <h4 class="offscreen">Attorney General Assigned</h4>
                <mat-form-field class="width-md-50">
                  <mat-label>Attorney General Assigned</mat-label>
                  <input
                    matInput
                    formControlName="attorney_general_names"
                  />
                  <!-- <mat-select
                    multiple
                    formControlName="attorney_generals"
                  >
                    <mat-option
                      *ngFor="let data of listDataService.attorneyGenerals$?.value"
                      [value]="data.value"
                    >
                      {{ data.name }}
                    </mat-option>
                  </mat-select> -->
                </mat-form-field>
              </section>
            </section>
            </mat-tab>
        </mat-tab-group>
    </section>

    <!-- Grievance Details -->
    <section class="case-type" [formGroup]="grievancesGroup" *ngIf="showGrievances">
        <h2>Grievance Details</h2>
        <hr>
        <mat-tab-group  mat-align-tabs="start" mat-stretch-tabs="false">
            <mat-tab label="Identifying Info">
              <div class="margin-top flex-md-row">
                  <section class="margin-sm-right width-md-50">
                    <h3 class="offscreen">Grievant Number</h3>
                      <mat-form-field class="width-md-100">
                          <mat-label>Grievant Number</mat-label>
                        <input
                          matInput
                          formControlName="grievant_number"
                        />
                      </mat-form-field>
                  </section>
                  <section class="width-md-50">
                    <h3 class="offscreen">Grievant</h3>
                    <mat-form-field class="width-100">
                      <mat-label>Grievant</mat-label>
                      <input
                        matInput
                        formControlName="grievant_name"
                      />
                    </mat-form-field>
                  </section>
                </div>
                <div class="flex-md-row">
                  <section class="margin-sm-right width-md-50">
                    <h3 class="offscreen">Grievant Associated with Institution</h3>
                    <mat-form-field class="width-100">
                      <mat-label>Grievant Associated with Institution</mat-label>
                      <mat-select formControlName="association">
                        <mat-option
                          [value]="">
                        </mat-option>
                        <mat-option *ngFor="let data of listDataService.grievantAssoc$?.value" [value]="data"
                        >
                          {{ data }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </section>
                  <section class="width-md-50">
                    <h3 class="offscreen">Bargaining Unit</h3>
                    <mat-form-field class="width-100">
                      <mat-label>Bargaining Unit</mat-label>
                      <mat-select formControlName="bargaining_unit">
                        <mat-option
                          [value]="">
                        </mat-option>
                        <mat-option *ngFor="let data of listDataService.bargainingUnit$?.value" [value]="data"
                        >
                          {{ data }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </section>
                </div>
                <div class="flex-md-row">
                    <section class="margin-sm-right width-md-50">
                      <h3 class="offscreen">Article & Section of Alleged Violation</h3>
                        <mat-form-field class="width-100">
                            <mat-label>Article & Section of Alleged Violation</mat-label>
                              <textarea
                                matInput
                                formControlName="violation_article_section">
                              </textarea>
                        </mat-form-field>
                    </section>
                    <section class="width-md-50">
                      <h3 class="offscreen">3rd Step Description</h3>
                        <mat-form-field class="width-100">
                            <mat-label>3rd Step Description</mat-label>
                              <textarea
                                matInput
                                formControlName="third_step_description">
                              </textarea>
                        </mat-form-field>
                    </section>
                </div>
            </mat-tab>
            <mat-tab label="Claim Details">
              <div class="margin-top margin-sm-bottom flex-md-row">
                <div class="margin-sm-right width-md-50 flex-md-row">
                  <section class="width-md-50">
                    <h3 class="offscreen">Appeal to Court Filed</h3>
                  <mat-checkbox
                    color="primary"
                    formControlName="is_court_appeal_filed"
                  >
                    Appeal to Court Filed
                  </mat-checkbox>
                  </section>
                  <section class="margin-sm-right width-md-50">
                    <h3 class="offscreen">Appeal to Arbitration</h3>
                  <mat-checkbox
                    color="primary"
                    formControlName="is_arbitration_appeal"
                  >
                    Appeal to Arbitration
                  </mat-checkbox>
                  </section>
                </div>
                <section class="width-md-50">
                  <h3 class="offscreen">Appeal to BOR</h3>
                  <mat-checkbox
                    color="primary"
                    formControlName="is_bor_appeal"
                  >
                    Appeal to BOR
                  </mat-checkbox>
                </section>
              </div>
              <div class="flex-md-row">
                <section class="margin-sm-right width-md-50">
                  <h3 class="offscreen">Assessment of Arbitrator</h3>
                  <mat-form-field class="width-md-100">
                    <mat-label>Assessment of Arbitrator</mat-label>
                    <textarea
                      matInput
                      formControlName="arbitration_assessment"
                    ></textarea>
                  </mat-form-field>
                </section>
                <section class="width-md-50">
                  <h3 class="offscreen">BOR Decision</h3>
                  <mat-form-field class="width-md-100">
                    <mat-label>BOR Decision</mat-label>
                    <textarea
                      matInput
                      formControlName="bor_decision"
                    ></textarea>
                  </mat-form-field>
                </section>
              </div>
              <div class="flex-md-row">
                <section class="margin-sm-right width-md-50">
                  <h3 class="offscreen">Settlement Amount</h3>
                  <mat-form-field class="form-field-flex width-md-100" floatLabel="always">
                    <mat-label>Settlement Amount</mat-label>
                    <input
                      matInput
                      formControlName="settlement_amount"
                      type="number"
                      placeholder="0"
                      class="currency-input"
                    />
                    <span matTextPrefix>$&nbsp;</span>
                  </mat-form-field>
                </section>
                <section class="width-md-50">
                  <h3 class="offscreen">Settlement Funding Source</h3>
                  <mat-form-field class="width-md-100">
                    <mat-label>Settlement Funding Source</mat-label>
                    <input
                      matInput
                      formControlName="settlement_funding_src"
                      type="text"
                      placeholder=""
                    />
                  </mat-form-field>
                </section>
              </div>
<!--              <div class="flex-md-row">-->
<!--                <section class="margin-sm-right width-md-50">-->
<!--                  <h3 class="offscreen">Arbitration Assignment</h3>-->
<!--                  <mat-form-field class="width-md-100">-->
<!--                    <mat-label>Arbitration Assignment</mat-label>-->
<!--                    <input-->
<!--                      matInput-->
<!--                      formControlName="arbitration_assignment"-->
<!--                    />-->
<!--                  </mat-form-field>-->
<!--                </section>-->
<!--                <div class="width-md-50">-->
<!--                </div>-->
<!--              </div>-->
            </mat-tab>
        </mat-tab-group>
    </section>

    <!-- Claim Details -->
    <section class="case-type" [formGroup]="administrativeClaimsGroup" *ngIf="showAdministrativeClaims">
        <h2>Claim Details</h2>
        <hr>
        <mat-tab-group mat-align-tabs="start" mat-stretch-tabs="false">
            <mat-tab label="Identifying Info">
              <div class="margin-top grid-3-25-sm-row grid-col-gap-sm">
                <section class="width-100">
                  <h3 class="offscreen">Date Claim Submitted</h3>
                  <mat-form-field class="width-md-100">
                    <mat-label>Date Claim Submitted</mat-label>
                    <input
                      matInput
                      [matDatepicker]="claimSubmittdPicker"
                      formControlName="claim_submitted_date"
                    >
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="claimSubmittdPicker"></mat-datepicker-toggle>
                    <mat-datepicker #claimSubmittdPicker></mat-datepicker>
                  </mat-form-field>
                </section>
                <section class="width-100">
                  <h3 class="offscreen">Agency</h3>
                  <mat-form-field class="form-field-flex width-md-100">
                    <mat-label>Agency</mat-label>
                    <mat-select formControlName="agency">
                      <mat-option
                        [value]="">
                      </mat-option>
                      <ng-container *ngFor="let data of listDataService.typeValues$ | async">
                        <mat-option *ngIf="data.name !== 'Tort'"
                                    [value]="data.value"
                        >
                          {{ data.name }}
                        </mat-option>
                      </ng-container>
                    </mat-select>
                  </mat-form-field>
                </section>
                <section class="width-100">
                  <h3 class="offscreen">Claim Number</h3>
                  <mat-form-field class="form-field-flex width-md-100">
                    <mat-label>Claim Number</mat-label>
                    <input
                      matInput
                      formControlName="claim_number"
                    />
                    <mat-error>Required</mat-error>
                  </mat-form-field>
                </section>
              </div>
              <div class="grid-2-25-50-sm-row grid-col-gap-sm">
                <section class="width-100">
                  <h3 class="offscreen">Settlement Amount</h3>
                  <mat-form-field class="form-field-flex width-md-100" floatLabel="always">
                    <mat-label>Settlement Amount</mat-label>
                    <input
                      matInput
                      formControlName="settlement_amount"
                      type="number"
                      placeholder="0"
                      class="currency-input"
                    />
                    <span matTextPrefix>$&nbsp;</span>
                  </mat-form-field>
                </section>
                <section class="width-100">
                  <h3 class="offscreen">Settlement Funding Source</h3>
                  <mat-form-field class="width-100">
                    <mat-label>Settlement Funding Source</mat-label>
                    <input
                      matInput
                      formControlName="settlement_funding_src"
                      type="text"
                      placeholder=""
                    />
                  </mat-form-field>
                </section>
              </div>
            </mat-tab>
        </mat-tab-group>
    </section>

    <!-- Appeals Details -->
    <section class="case-type" [formGroup]="appealsGroup" *ngIf="showAppeals">
        <h2>Appeal Details</h2>
        <hr>
        <mat-tab-group mat-align-tabs="start" mat-stretch-tabs="false">
            <mat-tab label="Identifying Info">
              <section class="margin-top">
                <h3 class="offscreen">Action Appealed</h3>
                <mat-form-field class="width-md-50">
                  <mat-label>Action Appealed</mat-label>
                  <textarea
                    matInput
                    formControlName="action_appealed"
                  ></textarea>
                </mat-form-field>
              </section>
            </mat-tab>
            <mat-tab label="Claim Details">
                <section class="margin-top margin-sm-bottom">
                    <h3>Appeal Presented to Board</h3>
                  <mat-radio-group formControlName="has_been_presented_to_board">
                    <mat-radio-button
                      color="primary"
                      *ngFor="let item of items"
                      [value]="item.value"
                    >
                      {{ item.label }}
                    </mat-radio-button>
                  </mat-radio-group>
                </section>
                <section>
                  <h3 class="offscreen">Date Presented</h3>
                    <mat-form-field class="margin-sm-bottom width-md-50">
                        <mat-label>Date Presented</mat-label>
                      <input matInput [matDatepicker]="presentedPicker" formControlName="presented_date"
                      >
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matIconSuffix [for]="presentedPicker"></mat-datepicker-toggle>
                        <mat-datepicker #presentedPicker></mat-datepicker>
                    </mat-form-field>
                </section>
                <section class="margin-bottom">
                    <h3>Appeal Decision</h3>
                  <mat-radio-group formControlName="appeal_decision">
                    <mat-radio-button
                      color="primary"
                      *ngFor="let option of optionStrings"
                      [value]="option.value"
                    >
                      {{ option.label }}
                    </mat-radio-button>
                  </mat-radio-group>
                </section>
            </mat-tab>
        </mat-tab-group>
    </section>

    <!-- Case Notes -->
    <section class="case-notes">
      <div class="flex-row flex-space-between">
        <h2>Notes & Attachments</h2>
        <button mat-stroked-button color="primary" (click)="addNote()"><mat-icon>add</mat-icon>Add Note</button>
      </div>
      <hr>
      <mat-table [dataSource]="caseService.addNewNotes" matSort>
        <!-- Date Column -->
        <ng-container matColumnDef="created_at">
          <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Date"> Date </mat-header-cell>
          <mat-cell tabindex="0" *matCellDef="let element"> {{ caseService.formatDate(element.created_at) }} </mat-cell>
        </ng-container>

        <!-- Details Column -->
        <ng-container matColumnDef="note">
          <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Details"> Details </mat-header-cell>
          <mat-cell tabindex="0" class="table-case-note" *matCellDef="let element">
            <span *ngIf="element.note">{{ element.note }}</span>
            <span *ngIf="!element.note"><i>(none)</i></span>
          </mat-cell>
        </ng-container>

        <!-- File Name Column -->
        <ng-container matColumnDef="filename">
          <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by File Name"> File Name </mat-header-cell>

          <mat-cell tabindex="0" *matCellDef="let element">
            <ng-container *ngFor="let file of element.attachments">
              <div class="multiple-line-cell padding-none blue-text pointer" (click)="download(file.filename, file.filepath)" (keyup)="(file)">
                {{ file.filename }}
              </div>
            </ng-container>
          </mat-cell>

        </ng-container>

        <!-- View Column -->
        <ng-container matColumnDef="view">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let element" class="flex-row flex-end">
            <button
              class="margin-sm-right"
              tabindex="0"
              mat-stroked-button
              (click)="viewNote(element.note)"
              color="primary"
              [disabled]="!element.note"
            >
              View
            </button> </mat-cell>
        </ng-container>

        <!-- Delete Column -->
        <ng-container matColumnDef="delete">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let element" class="flex-row flex-end">
            <button
              tabindex="0"
              mat-stroked-button
              (click)="deleteNote(element)"
              color="warn"
            >
              Delete
            </button> </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="notesColumns" class="matHeaderRow"></mat-header-row>
        <mat-row *matRowDef="let row; columns: notesColumns;"></mat-row>
      </mat-table>
    </section>

    <!-- Action Buttons -->
    <div class="margin-top">
      <button mat-flat-button
              color="primary"
              class="margin-right"
              (click)="addCase()"
              [disabled]="!validForm()"
      >
        <mat-icon>add</mat-icon>
        Add Case
      </button>
      <button
        mat-stroked-button
        [routerLink]="'/search-cases'"
        color="warn">Cancel
      </button>
    </div>
</div>
