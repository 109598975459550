import { Injectable } from '@angular/core';
import { DropdownSelectContent } from '../components/widgets/dropdown-select/dropdown-select-content.interface';

@Injectable({
  providedIn: 'root'
})
export class DropdownSelectService {

  public caseClassification: DropdownSelectContent = {};
  public caseType: DropdownSelectContent = {};
  public institution: DropdownSelectContent = {};
  public attorney: DropdownSelectContent = {};
  public grievantAssoc: DropdownSelectContent = {};
  public bargainingUnit: DropdownSelectContent = {};
  public claimNumbers: DropdownSelectContent = {};
  public tortClaimIds: DropdownSelectContent = {};
  public agency: DropdownSelectContent = {};
  // public caseSubtype: DropdownSelectContent = {};


  // #TODO review the parentInput with Bob
  // public parentInput(value: Array<any> | any): void {
  //   let msg = '';
  //   if (value instanceof Array) {
  //     msg = value.length === 1 ? value[0] : value.join(', ');
  //   } else if (value instanceof Object) {
  //     msg = JSON.stringify(value);
  //   } else {
  //     msg = value.toString();
  //   }
  //   window.location.href = msg;
  // }

  public setupDropdownSelects(): void {
    this.caseClassification = {
      title: 'Case Classification',
      name: 'classification'
    };

    this.caseType = {
      title: 'Case Type',
      name: 'type'
    };

    // this.caseSubtype = {
    //   title: 'Case Subtype',
    //   name: 'subtype'
    // };

    this.institution = {
      title: 'Institution',
      name: 'institutions'
    };

    this.attorney = {
      title: 'In House Attorney',
      name: 'in_house_attorneys'
    };

    this.agency = {
      title: 'Agency',
      name: 'agency'
    };

    this.grievantAssoc = {
      title: 'Grievant Associated',
      name: 'grievantAssoc'
    };

    this.bargainingUnit = {
      title: 'Bargaining Unit',
      name: 'bargainingUnit'
    };

    this.claimNumbers = {
      title: 'Claim Number',
      name: 'claimNumbers'
    };

    this.tortClaimIds = {
      title: 'Tort Claim ID',
      name: 'tortClaimIds'
    };
  }
}
