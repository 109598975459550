import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-button-toggle-grp',
  templateUrl: './button-toggle-grp.component.html',
  styleUrls: ['./button-toggle-grp.component.scss']
})

export class ButtonToggleGrpComponent {
  @Output() buttonGroupOutput = new EventEmitter<any>();
  public caseStatus: string = '';
  public status = 'all';

  constructor() { }

  emit(event: string){
    /* it would be nice if these acted as actual toggle buttons
       so that clicking them a second time would deactivate them
     */
    if (this.status === event && event !== 'all') {
      event = 'all';
      this.caseStatus = '';
    } else {
      this.status = event;
      this.caseStatus = event;
    }
    /* if we don't want that, then just remove the lines between
       these two comment blocks.
     */
    this.buttonGroupOutput.emit(event);
  }

  public resetCaseStatus(): void {
    this.caseStatus = '';
    this.emit('all');
  }

}
