<div class="add-user-container">
    <section class="margin-bottom case-details-heading">
        <h1 class="bold-headline--serif">Add a New User</h1>
    </section>

    <!-- User Details -->
    <section class="case-details margin-top padding-top" [formGroup]="addUserForm">
        <h2>User Details</h2>
        <hr>

        <!-- UserName & Institution -->
      <div class="flex-sm-row">
        <section class="margin-sm-right width-md-50">
          <h3 class="offscreen">Username</h3>
          <mat-form-field class="margin-right width-md-100">
            <mat-label>Username*</mat-label>
            <input
              matInput
              #userNameInput
              formControlName="username"
            />
            <mat-error>
              {{ userNameError }}
            </mat-error>
          </mat-form-field>
        </section>
        <section class="width-md-50">
          <h3 class="offscreen">Institution</h3>
          <mat-form-field class="width-md-100">
                <mat-label>Institution</mat-label>
                <mat-select formControlName="institution">
                    <mat-option multiple *ngFor="let data of listDataService.institutions$.value" [value]="data.value">
                      {{ data.name }}
                    </mat-option>
                  </mat-select>
            </mat-form-field>
          </section>
        </div>

      <!-- First & Last Name -->
      <div class="flex-md-row">
        <div class="flex-md-row width-md-50 margin-sm-right">
          <section class="width-md-50 margin-sm-right">
            <h3 class="offscreen">First Name</h3>
            <mat-form-field class="width-md-100">
              <mat-label>First Name</mat-label>
                    <input matInput formControlName="firstname" />
                    <mat-error>Required</mat-error>
                </mat-form-field>
              </section>
          <section class="width-md-50">
            <h3 class="offscreen">Last Name</h3>
            <mat-form-field class="width-md-100">
              <mat-label>Last Name</mat-label>
              <input matInput formControlName="lastname" />
              <mat-error>Required</mat-error>
            </mat-form-field>
          </section>
        </div>

        <div class="flex-md-row width-md-50">
          <section class="width-md-50 margin-sm-right">
            <h3 class="offscreen">Phone Number</h3>
            <mat-form-field class="width-sm-100">
              <mat-label>Phone Number</mat-label>
                <input matInput
                  formControlName="phone_primary"
                  appPhone
                />
                <mat-error>Invalid phone number</mat-error>
            </mat-form-field>
          </section>
          <section class="width-md-50">
            <h3 class="offscreen">Alternate Phone Number</h3>
            <mat-form-field class="width-sm-100">
              <mat-label>Alternate Phone Number</mat-label>
                    <input matInput
                      formControlName="phone_alternate"
                      appPhone
                    />
                    <mat-error>Invalid phone number</mat-error>
                </mat-form-field>
              </section>
            </div>
        </div>

        <!-- Email -->
      <div class="flex-sm-row">
        <section class="margin-sm-right width-md-50">
          <h3 class="offscreen">Email</h3>
          <mat-form-field class="width-md-100">
            <mat-label>Email</mat-label>
                <input matInput
                    formControlName="email_primary"
                    type="email" />
              <mat-error *ngIf="addUserForm.controls['email_primary'].hasError('required') && !addUserForm.controls['email_primary'].hasError('email')">Required</mat-error>
              <mat-error *ngIf="addUserForm.controls['email_primary'].hasError('email') && !addUserForm.controls['email_primary'].hasError('required')">Invalid email address</mat-error>
            </mat-form-field>
          </section>
        <section class="width-md-50">
          <h3 class="offscreen">Alternate Email</h3>
          <mat-form-field class="width-md-100">
            <mat-label>Alternate Email</mat-label>
                <input matInput
                    formControlName="email_alternate"
                    type="email" />
                <mat-error *ngIf="addUserForm.controls['email_alternate'].hasError('email')">Invalid email address</mat-error>
            </mat-form-field>
          </section>
        </div>

        <section>
            <h3>Select Role(s) for this User:</h3>
            <mat-form-field class="width-md-50">
                <mat-label>Roles</mat-label>
                <mat-select multiple formControlName="roles" (selectionChange)="updateRoles($event)">
                    <mat-option *ngFor="let data of listDataService.roles$ | async" [value]="data.value">
                        {{ data.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </section>
    </section>

    <!-- Action Buttons -->
    <div class="margin-top">
        <button mat-flat-button
            color="primary"
            class="margin-right"
            (click)="addUser()"
            [disabled]="addUserForm.valid === false"
        >
            <mat-icon>add</mat-icon>
            Add User
        </button>
        <button
            mat-stroked-button
            [routerLink]="'/manage-users'"
            color="warn">Cancel
        </button>
    </div>
</div>
