import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DropdownSelectContent } from './dropdown-select-content.interface';
import { ListDataService } from "../../../services/list-data/list-data.service";
import { DropdownSelectService } from "../../../services/dropdown-select.service";

@Component({
  selector: 'app-dropdown-select',
  templateUrl: './dropdown-select.component.html',
  styleUrls: ['./dropdown-select.component.scss']
})
export class DropdownSelectComponent {
  @Input() dropdownSelect: DropdownSelectContent = {
    options: [''],
    title: '',
    name: ''
  };

  @Output() dropdownOutput = new EventEmitter<any>();

  constructor(public listDataService: ListDataService, public dropDownSelectService: DropdownSelectService) {
  }

  public checkContent( content: string | undefined | Array<string> = [''] ): string {
    if (content instanceof Array) {
      return content.join(', ');
    }
    return typeof content === 'undefined' || content === null
      ? ''
      : content.trim();
  }

  public getLabel(title: string = '', options: any = []): string {
    options = options ?? [];
    if (title.trim() === 'Case Type' && options.length === 0) {
      return 'Please Select a Classification';
    }
    return this.checkContent(title);
  }
  public isDisabled(title: string = '', options: any = []): boolean {
    options = options ?? [];
    if (title === 'Case Types'  && options.count === 0) {
      return true;
    }
    return false;
  }

  public emit(value: unknown){
    this.dropdownOutput.emit(value);
  }

  public handleSelectionChange(event: any, source: string = ''): void {
    if (source === 'Case Classification') {
      this.listDataService.updateTypeSubtypeMulti('classification', event);
    } else if (source === 'Case Type') {
      this.listDataService.updateTypeSubtypeMulti('type', event);
    }
    //
  }

}
