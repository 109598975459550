import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  NonNullableFormBuilder,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { DropdownDetails, ListDataService } from "../shared/services/list-data/list-data.service";
import { DropdownSelectService } from "../shared/services/dropdown-select.service";
import { CurrentUserService } from '../shared/services/current-user/current-user.service';
import { UserManagementService } from '../shared/services/user-management/user-management.service';
import { User } from '../shared/models/user';
import {debounceTime, distinctUntilChanged, Subject} from "rxjs";

@Component({
  selector: 'app-add-new-currrent-user',
  templateUrl: './add-new-user.component.html',
  styleUrls: ['./add-new-user.component.scss']
})
export class AddNewUserComponent {
  @ViewChild('userNameInput', { static: false}) private userNameInput?: ElementRef;

  public institutions: any;
  public roles: any;
  public validateUsername$: Subject<any> = new Subject();
  public username: string = '';
  public isUniqueName: boolean = false;
  public isAllowedDomain: boolean = false;
  public userNameTouched = false;

  public addUserForm: FormGroup = new FormGroup<any>({
    attachments: this._fb.array([])
  });

  private allowedDomains = [
    'iastate.edu',
    'uiowa.edu',
    'uni.edu',
    'iowaregents.edu'
  ];

  private allUsers: User[] = [];

  constructor(
    public listDataService: ListDataService,
    public dropdownSelectService: DropdownSelectService,
    public userManagementService: UserManagementService,
    // private currentUserService: CurrentUserService,
    private _fb: NonNullableFormBuilder) {

    this.listDataService.getListData();
    this.fillNewUser();
    this.userManagementService.getUsers().subscribe( (users: User[]) => {
      this.allUsers = users;
    });
  }

  public get userNameError(): string {
    let msg = ''
    try {
      if (this.isUniqueName !== true) {
        msg = "This username already exists";
      } else if (this.isAllowedDomain !== true && (this.addUserForm?.get('username')?.value ?? '').trim() !== '') {
        msg = "The username must have a valid account";
      } else if ((this.addUserForm?.get('username')?.value ?? '').trim() === '') {
        msg = "Required"
      } else {
        msg = '';
      }
      return msg;
    } catch (e) {
      return '';
    }
  }

  public fillNewUser(): void {
    try {
      const addUser: FormGroup = this.addUserForm as FormGroup;
      addUser.setControl(
        'pk_user_id',
        this._fb.control(-1, {
          validators: Validators.required,
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      addUser.setControl(
        'username',
        this._fb.control('', {
          validators: this.UsernameValidator(),
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      addUser.setControl(
        'institution',
        this._fb.control('', {
          updateOn: 'change',
          validators: Validators.required,
        }),
        { emitEvent: true }
      );
      addUser.setControl(
        'firstname',
        this._fb.control('', {
          validators: Validators.required,
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      addUser.setControl(
        'lastname',
        this._fb.control('', {
          validators: Validators.required,
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      addUser.setControl(
        'email_primary',
        this._fb.control('', {
          validators: [Validators.required, Validators.email],
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      addUser.setControl(
        'email_alternate',
        this._fb.control('', {
          validators: [Validators.email],
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      addUser.setControl(
        'phone_primary',
        this._fb.control('', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      addUser.setControl(
        'phone_alternate',
        this._fb.control('', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );

      addUser.setControl(
        'roles',
        this._fb.control('', {
          validators: Validators.required,
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      this.validateUsername(this.addUserForm.get('username')?.value ?? '');
    } catch (e) {
      console.log('Error adding new user: ', e);
    }
  }

  public addUser(): void {
    try{
      if(!this.addUserForm?.valid) this.addUserForm.updateValueAndValidity();
      else{
        let newUser = new User();
        newUser.username = this.addUserForm?.get('username')?.value;
        newUser.firstname = this.addUserForm?.get('firstname')?.value;
        newUser.lastname = this.addUserForm?.get('lastname')?.value;
        newUser.institution = this.addUserForm?.get('institution')?.value;
        newUser.email_primary = this.addUserForm?.get('email_primary')?.value;
        newUser.email_alternate= this.addUserForm?.get('email_alternate')?.value;
        newUser.phone_primary= this.addUserForm?.get('phone_primary')?.value;
        newUser.phone_alternate= this.addUserForm?.get('phone_alternate')?.value;
        newUser.email_alternate= this.addUserForm?.get('email_alternate')?.value;
        newUser.pk_user_id = 0;
        newUser.roles_ids = this.addUserForm?.get('roles')?.value;
        this.userManagementService.postNewUser(newUser);
      }
    }

    catch(e: any){
      console.error('Error adding new user.');

    }
  }

  updateRoles(event: any){
    try{
      this.addUserForm.get('roles')?.setValue(event.value ?? []);
      this.addUserForm.controls['email_primary'].hasError('phone');
    }
      catch(e: any){
        console.log(e);
      }
  }

  private validateUsername(username: string = ''): void {
    if (typeof(this.addUserForm) === 'undefined' || typeof(this.allUsers) === 'undefined') { return; }
    const pk_user_id = this.addUserForm?.get('pk_user_id')?.value ?? 0;

    this.isUniqueName = !this.allUsers.some((user: User) => {
      return user.username === username && user.pk_user_id !== pk_user_id;
    });

    const domain = username.split('@');
    let userDomain = '';
    if ( (domain ?? []).length !== 2) {
      this.isAllowedDomain = false;
      return;
    } else {
      userDomain = domain[1] ?? '';
    }
    this.isAllowedDomain = this.allowedDomains.includes(userDomain);
  }

  private UsernameValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      this.validateUsername(control.value)
      return this.isUniqueName === false || this.isAllowedDomain === false ?
        {
          isBlank: control.value.trim() !== '',
          isUniqueName: this.isUniqueName,
          isAllowedDomain: this.isAllowedDomain
        } : null;
    };
  }
}
