import {Component, Input, OnInit} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ProgressSpinnerMode } from "@angular/material/progress-spinner";
import { Sort } from '@angular/material/sort';
import { LiveAnnouncer } from "@angular/cdk/a11y";
import { MatDialog } from "@angular/material/dialog";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { HttpClient } from "@angular/common/http";

import { Observable} from "rxjs";

import { environment } from "../../environments/environment";
import { CaseService } from "../shared/services/case.service";
import {DropdownDetails, ListDataService} from "../shared/services/list-data/list-data.service";
import { DropdownSelectService } from "../shared/services/dropdown-select.service";
import { AddNoteComponent } from "../shared/components/add-note/add-note.component";
import { ConfirmCaseDeleteComponent } from '../shared/components/confirm-case-delete/confirm-case-delete.component';
import { saveAs } from 'file-saver';
import { PreviewAdminClaimComponent } from '../shared/components/preview-admin-claim/preview-admin-claim.component';
import {ViewNoteComponent} from "../shared/components/view-note/view-note.component";
import {CurrentUserService} from "../shared/services/current-user/current-user.service";
import {Notes} from "../shared/models/notes";
import { DeleteCaseRequestComponent } from '../shared/components/delete-case-request/delete-case-request.component';
import {AdministrativeClaims} from "../shared/models/administrative-claims";
import {Router} from "@angular/router";
import {ThemePalette} from "@angular/material/core";

@Component({
  selector: 'app-case-details',
  templateUrl: './case-details.component.html',
  styleUrls: ['./case-details.component.scss']
})

export class CaseDetailsComponent implements OnInit {

  @Input() spinnerShow = true;

  public notesColumns: string[] = ['created_at', 'note', 'filename', 'view', 'delete'];

  public color: ThemePalette = 'primary';
  public mode = 'indeterminate' as ProgressSpinnerMode;

  public editingDetails = false;

  public editingLitigations = false;

  public editingGrievances = false

  public editingAdministrativeClaims = false

  public editingAppeals = false

  public attachmentsUrl = environment.apiUrl + environment.attachmentsEndpoint;

  public selected = new FormControl(0);

  public showLitigations = false;

  public showGrievances = false;

  public showAdministrativeClaims = false;

  public showAppeals = false;

  public adminClaimData: AdministrativeClaims = new AdministrativeClaims();

  public blankAdminClaimData: AdministrativeClaims = new AdministrativeClaims();

  public details: any;

  public agency: string = '';

  public subtypes: any;
  public type: any;
  public institutions: any;
  public inHouseAttorneys: any;
  public attorneyGenerals: any;

  public id = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);

  public jurisdictions = [
    'State Jurisdiction',
    'Federal Jurisdiction'
  ];

  public itemStrings = [
    {label: 'Yes', value: '1'},
    {label: 'No', value: '0'}
  ];

  public items = [
    {label: 'Yes', value: 1},
    {label: 'No', value: 0}
  ];

  public optionStrings = [
    {label: 'Approved', value: '1'},
    {label: 'Denied', value: '0'}
  ];

  public options = [
    {label: 'Approved', value: 1},
    {label: 'Denied', value: 0}
  ];

  public detailsGroup: FormGroup = this.caseService.fullCaseForm.get(
    'details'
  ) as FormGroup;

  public litigationsGroup: FormGroup = this.caseService.fullCaseForm.get(
    'litigations'
  ) as FormGroup;

  public grievancesGroup: FormGroup = this.caseService.fullCaseForm.get(
    'grievances'
  ) as FormGroup;

  public administrativeClaimsGroup: FormGroup = this.caseService.fullCaseForm.get(
    'administrativeClaims'
  ) as FormGroup;

  public appealsGroup: FormGroup = this.caseService.fullCaseForm.get(
    'appeals'
  ) as FormGroup;

  public notesGroup: FormArray = this.caseService.fullCaseForm.get(
    'notes'
  ) as FormArray;

  public previewAdminClaimGroup: FormArray = this.caseService.fullCaseForm.get(
    'previewAdminClaim'
  ) as FormArray;

  public previewTortClaimGroup: FormArray = this.caseService.fullCaseForm.get(
    'previewTortClaim'
  ) as FormArray;

  public subTypesAvailable: boolean = false;

  constructor(public caseService: CaseService, public listDataService: ListDataService, public dropdownSelectService: DropdownSelectService,
              private liveAnnouncer: LiveAnnouncer, private dialog: MatDialog, private http: HttpClient, public currentUserService: CurrentUserService, private router: Router) {
    this.caseService.state = 'details';
    this.listDataService.getListData();
    this.caseService.getNotes();
    this.getDetails();
    this.listDataService.subtypeValues$.subscribe(subtypes => {
      this.subTypesAvailable = (subtypes as DropdownDetails[] ?? []).length > 0;
    });
    this.getValues();
  }

  ngOnInit()  {
    this.listDataService.getListData();
    this.caseService.getNotes();
    this.getDetails();
    this.listDataService.subtypeValues$.subscribe(subtypes => {
      this.subTypesAvailable = (subtypes as DropdownDetails[] ?? []).length > 0;
    });
    this.getValues();
    this.setAgencyName();
  }

  public get disableTypes(): boolean {
    try {
      return (this.caseService.details.classification_value ?? '') === '';
    } catch (e) {
      return true;
    }
  }
  public get disableSubtypes(): boolean {
    try {
      return (this.detailsGroup.get('type')?.value ?? '') === '';
    } catch (e) {
      return true;
    }
  }

  public clearField(group: FormGroup, controlName = '', empty: string = ''): void  {
    group.get(controlName)?.setValue(empty);
    this.caseService.postAllData();
  }

  public getValues(): void  {
    let subtypes: any[] = [];
    this.listDataService.subtypeValues$.value.forEach( subtype => {
      if (this.caseService.details.subtypes.find(i => i === subtype.value)) {
        subtypes.push(subtype);
      }
    });
    this.subtypes = subtypes;

    let typeValue: DropdownDetails | any = [];
    this.listDataService.typeValues$.value.forEach( type => {
      if (this.caseService.details.type === type.value) {
        typeValue = type;
      }
    });
    this.type = typeValue;

    let institutions: any[] = [];
    this.listDataService.institutions$.value.forEach( institution => {
      if (this.caseService.details.institutions.find(i => i === institution.value)) {
        institutions.push(institution);
      }
    });
    this.institutions = institutions;

    let inHouseAttorneys: any[] = [];
    this.listDataService.attorneys$.value.forEach( attorney => {
      if (this.caseService.details.in_house_attorneys.find(i => i === attorney.value)) {
        inHouseAttorneys.push(attorney);
      }
    });
    this.inHouseAttorneys = inHouseAttorneys;

    let attorneyGenerals: any[] = [];
    this.listDataService.attorneyGenerals$.value.forEach( (attorney: { value: any; }) => {
      if (this.caseService.details.attorney_generals.find(i => i === attorney.value)) {
        attorneyGenerals.push(attorney);
      }
    });
    this.attorneyGenerals = attorneyGenerals;
  }

  public download(file: any): void {
    try {
    let url =  this.attachmentsUrl + '/' + file.pk_attachment_id;
    this.downloadFile(url).subscribe((response: any) => {
      let blob:any = new Blob([response], { type: 'text/json; charset=utf-8' });
      saveAs(blob, file.filename);
    }), (error: any) => console.log('Error downloading the file'),
      () => console.info('File downloaded successfully');
    } catch(e) {
      console.log(e);
    }
  }

  public downloadFile(filepath: any): Observable<any> {
    return this.http.get(filepath, {responseType: 'blob'});
  }

  public viewNote(note: string): void {
    try {
      this.dialog.open(ViewNoteComponent, {
        data: note,
        width: '95vw',
        maxWidth: '900px',
        height: 'auto',
        maxHeight: '600px',
      });
    } catch (e) {
      console.log('Error loading plan Advisor search: ', e);
    }
  }

  public deleteNote(note: Notes):  void  {
    this.caseService.deleteNote(note);
    this.caseService.getNotes();
  }

  public setAdminClaimData(data: any): void  {
    this.adminClaimData = data;
  }

  public previewAdminClaim(): void {
    try {
      this.dialog.open(PreviewAdminClaimComponent, {
        data: this.adminClaimData,
        width: '95vw',
        maxWidth: '900px',
        height: 'auto',
        maxHeight: '600px',
      });
    } catch (e) {
      console.log('Error loading Administrative Claim Preview: ', e);
    }
  }

  public addNote(): void {
    try {
      const dialogRef = this.dialog.open(AddNoteComponent, {
        data: this.notesGroup,
        width: '95vw',
        maxWidth: '900px',
        height: '95vw',
        maxHeight: '640px',
      });
      dialogRef.afterClosed().subscribe(  result => {
        }
      );
    } catch (e) {
      console.log('Error loading plan Advisor search: ', e);
    }
  }

  public updateInput(
    group: FormGroup,
    controlName = '',
    value: any, name: string = ''
  ): void {
    try {
      if ( controlName === 'classification'  && name !== '') {
        this.listDataService.updateTypeSubtype(controlName, value, name);
        this.caseService.fullCaseForm.get('details.type')?.setValue(-1, { onlySelf: false, emitValue: true});
        this.caseService.fullCaseForm.get('details.subtypes')?.setValue([], { onlySelf: false, emitValue: true});
      }
      if ( controlName === 'type' && name !== '') {
        this.listDataService.updateTypeSubtype(controlName, value, name);
        this.caseService.fullCaseForm.get('details.subtypes')?.setValue([], { onlySelf: false, emitValue: true});
      }
      group.get(controlName)?.setValue(value);
      if (group.get(controlName)?.valid) {
        this.caseService.postAllData();
      }
    } catch (e) {
      console.log(`Error updating control ${controlName}`, group);
    }
  }

  public getDetails(): void {
    this.caseService.getDetails().subscribe( details => {
      this.caseService.getNotes();
      this.listDataService.updateTypeSubtype('classification', '', this.caseService.details.classification_value);
      this.listDataService.updateTypeSubtype('type', '', this.caseService.details.type_value);
      this.showSection();
      this.details = this.caseService.details;
      let result: any[] = [];
      this.subtypes.forEach((value: { value: any; }) => {
        result.push(value.value)
      });
      this.detailsGroup.get('subtypes')?.setValue(result);
      this.setAdminClaim();
      // this.setTortClaim();
      this.getValues();
      this.setAgencyName();
      this.spinnerShow = false;
    });
    this.listDataService.getListData();
  }

  public setAdminClaim(): void  {
    this.listDataService.getListData();
    this.listDataService.claimNumbers$.value.forEach( (claim: any) => {
      if ((claim.pk_administrative_claim_id === this.caseService.litigations.fk_admin_claim_id) && (claim.fk_case_id !== this.caseService.details.pk_case_id)) {
        this.setAdminClaimData(claim);
      }
    });
  }

  public setAgencyName(): void {
    this.listDataService.getListData();
    let typeValue: string = '';
    this.listDataService.typeValues$.value.forEach( type => {
      if (this.caseService.administrativeClaims.agency.toString() === type.value.toString()) {
        typeValue = type.name;
      }
    });
    this.agency = typeValue;
  }

  public compareAgency(obj1: any, obj2: any): boolean {
    return obj1.toString() === obj2.toString();
  }

  public updateOther(
    group: FormGroup,
    controlName = '',
    value: string | number = '',
    name: string = ''
  ): void {
    try {
      if ( (controlName === 'classification' || controlName === 'type') && name !== '') {
        this.listDataService.updateTypeSubtype(controlName, value.toString(), name);
      }
      group.get(controlName)?.setValue(group.get(controlName)?.value);
      if (group.get(controlName)?.valid) {
        this.caseService.postAllData();
      }
    } catch (e) {
      console.log(`Error updating control ${controlName}`, group);
    }
  }

  public updateCb(
    group: FormGroup,
    controlName = '',
    event: MatCheckboxChange
  ): void {
    let newValue = '0';
    try {
      newValue = (event?.checked ?? false) ? '1' : '0';
      group.get(controlName)?.setValue(JSON.parse(newValue));
      if (group.get(controlName)?.valid) {
        this.caseService.postAllData();
      }
    } catch (e) {
      console.log(
        `Error updating control ${controlName} to ${newValue} in group: `,
        group
      );
    }
  }

  public setEditStatus(section: string, sendEmail: boolean = false): void  {
    if (section === 'Case Details') {
      this.editingDetails = !this.editingDetails;
    }
    if (section === 'Litigation Details') {
      this.editingLitigations = !this.editingLitigations;
    }
    if (section === 'Grievance Details') {
      this.editingGrievances = !this.editingGrievances;
    }
    if (section === 'Administrative Claims Details') {
      this.editingAdministrativeClaims = !this.editingAdministrativeClaims;
    }
    if (section === 'Appeals Details') {
      this.editingAppeals = !this.editingAppeals;
    }
    if (sendEmail === true) {
      this.caseService.sendEditEmail(this.caseService.fullCaseForm.get('details.pk_case_id')?.value ?? 0);;
    }
  }

  public get status(): string  {
    try {
      let status = ''
      if (this.caseService.details.is_closed === 1) {
        status = 'Closed'
      } else {
        status = 'Open'
      }
      return status;
    } catch (e) {
      console.log(e);
      return '';
    }
  }

  public showSection() {
    this.showLitigations = this.detailsGroup.get('classification')?.value === 1 &&
      (this.detailsGroup.get('type')?.value === 4 ||
        this.detailsGroup.get('type')?.value === 5 ||
        this.detailsGroup.get('type')?.value === 6 ||
        this.detailsGroup.get('type')?.value === 7 ||
        this.detailsGroup.get('type')?.value === 8 ||
        this.detailsGroup.get('type')?.value === 9 ||
        this.detailsGroup.get('type')?.value === 10 ||
        this.detailsGroup.get('type')?.value === 11 ||
        this.detailsGroup.get('type')?.value === 12 ||
        this.detailsGroup.get('type')?.value === 13 ||
        this.detailsGroup.get('type')?.value === 14 ||
        this.detailsGroup.get('type')?.value === 15 ||
        this.detailsGroup.get('type')?.value === 86 ||
        this.detailsGroup.get('type')?.value === 16);

    this.showGrievances = this.detailsGroup.get('classification')?.value === 2 &&
      (this.detailsGroup.get('type')?.value === 17 ||
        this.detailsGroup.get('type')?.value === 18 ||
        this.detailsGroup.get('type')?.value === 19 ||
        this.detailsGroup.get('type')?.value === 20 ||
        this.detailsGroup.get('type')?.value === 21 ||
        this.detailsGroup.get('type')?.value === 22);

    this.showAdministrativeClaims = (this.detailsGroup.get('classification')?.value === 2 ||  this.detailsGroup.get('classification')?.value === 3) &&
      (this.detailsGroup.get('type')?.value === 23 ||
      this.detailsGroup.get('type')?.value === 27 ||
      this.detailsGroup.get('type')?.value === 26 ||
      this.detailsGroup.get('type')?.value === 29 ||
      this.detailsGroup.get('type')?.value === 25 ||
      this.detailsGroup.get('type')?.value === 30 ||
      this.detailsGroup.get('type')?.value === 28 ||
      this.detailsGroup.get('type')?.value === 83 ||
      this.detailsGroup.get('type')?.value === 86 ||
      this.detailsGroup.get('type')?.value === 82 ||
      this.detailsGroup.get('type')?.value === 84);

    this.showAppeals = this.detailsGroup.get('classification')?.value === 2 &&
      this.detailsGroup.get('type')?.value === 24;

  }

  /** Announce the change in sort state for assistive technology. **/
  public announceSortChange(sortState: Sort): void{
    if (sortState.direction) {
      this.liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this.liveAnnouncer.announce('Sorting cleared');
    }
  }

  openDialog(enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.dialog.open(ConfirmCaseDeleteComponent, {
      width: '95vw',
      maxWidth: '900px',
      height: 'auto',
      maxHeight: '600px',
      data: {
        pk_case_id: this.id,
        title: this.caseService.details.title,
        description: this.caseService.details.description,
      }
    });
  }

  deleteCaseRequestDialog (enterAnimationDuration: string, exitAnimationDuration: string): void {
    let dialogRef = this.dialog.open(DeleteCaseRequestComponent, {
      width: '95vw',
      maxWidth: '900px',
      height: 'auto',
      maxHeight: '600px',
      data: {
        pk_case_id: this.id,
        title: this.caseService.details.title,
        description: this.caseService.details.description,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'delete') {
        this.caseService.details.is_delete_requested = 1;
        this.detailsGroup.get('is_delete_requested')?.setValue('1');
        this.caseService.postAllData();
      }
    });
  }

  public canDelete(){
    return this.currentUserService.canDeleteCase();
  }
}
