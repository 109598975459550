export class Attachments {
  public pk_attachment_id: number = -1;
  public fk_note_id: number = -1;
  public filename: string = '';
  public filepath: string = '';
  public mimetype: string = '';
  public filesize: number = -1;
  public created_by: string = '';
  public created_at: Date | string = '';
  public updated_by: string = '';
  public updated_at: Date | string = '';
}
