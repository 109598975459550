import { ValidatorFn, AbstractControl } from '@angular/forms';

export function phoneValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const regexPhone: RegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    const valid = regexPhone.test(control.value);
    // @ts-ignore
    return valid ? null : { invalidPhone: { value: control.value } };
  };
}
