import { AfterViewInit, Component, ViewChild, Renderer2 } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MaterialModule  } from '../shared/modules/material/material.module';

import { ScriptLoaderService } from '../shared/services/script-loader/script-loader.service';
import { CurrentUserService } from '../shared/services/current-user/current-user.service';

import { CurrentUser } from '../shared/models/current-user';
@Component({
  selector: 'app-discovery',
  standalone: true,
  imports: [CommonModule, MaterialModule],
  templateUrl: './discovery.component.html',
  styleUrls: ['./discovery.component.scss']
})

export class DiscoveryComponent implements AfterViewInit {
  @ViewChild('scriptContainer') public scriptContainer!: HTMLDivElement;
  public showScripts: boolean = false;
  public currentUser: CurrentUser = new CurrentUser;
  public isLoggedIn: boolean = false;
  public entities: { displayName: string, entityID: string, image: string}[] = [];
  public entityID: string = '';
  public imagePath = 'assets/discovery/images/';
  private loaded: boolean = false;
  private scripts: string[] = [
    "assets/discovery/idpselect_config.js",
    "assets/discovery/idpselect.js"
  ];

  private origin: string = environment.protocol + environment.server;
  private path: string = 'Shibboleth.sso/Login';
  private SAMLDS: string = '1';
  private target: string = 'ss:mem:lts';

  /* full link to discovery so it can build idpselect:
  https://lts-test.iowaregents.edu/discovery?entityID=https:%2F%2Flts-test.iowaregents.edu%2Fshibboleth&return=https:%2F%2Flts-test.iowaregents.edu%2FShibboleth.sso%2FLogin%3FSAMLDS%3D1%26target%3Dss%253Amem%253A00c835da82810dfc14664366fdfee812f7258b5aae05cbbc4b01a42f5b53e671
  */

  /* link as built by disovery service:
  https://lts-test.iowaregents.edu/Shibboleth.sso/Login?SAMLDS=1&target=ss%3Amem%3A00c835da82810dfc14664366fdfee812f7258b5aae05cbbc4b01a42f5b53e671&entityID=iowafed-test%3Aidp%3Auiowa.edu
  */
  constructor( private scriptLoaderService: ScriptLoaderService, private renderer2: Renderer2, private currentUserService: CurrentUserService, private http:  HttpClient) {
    // this.currentUserService.setCurrentUser();
    // this.currentUserService.isLoggedIn$.subscribe( isLoggedIn => {
    //     if (isLoggedIn === true) {
    //       this.currentUserService.currentUser$.subscribe( user => {
    //         this.currentUser = user;
    //       });
    //     } else {
    //     this.currentUser = new CurrentUser;
    //   }
    // });
    this.parseDiscoFeed();
  }

  ngAfterViewInit(): void {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    this.scriptLoaderService.loadJsScript(this.renderer2, "assets/discovery/idpselect_config.js");
    this.scriptLoaderService.loadJsScript(this.renderer2, "assets/discovery/idpselect.js");
  }

  public logIn(): void {
    const url = `${this.origin}${this.path}?SAMLDS=${this.SAMLDS}&target=${this.target}&entityID=${this.entityID}`;
    window.location.href = url;
  }

  public get siteName(): string {
    return 'Litigation Tracking System';
  }

  public get selectedEntityImage(): string {
    const blank = this.imagePath + 'blank.png';
    if (this.entityID === '') {
      return blank
    } else {
      const found = this.entities.find( (e) => {
        return e.entityID === this.entityID;
      })
      if (typeof(found) === 'undefined') {
        return blank
      } else {
        return found.image
      }
    }
    // on the off chance it gets this far
    return blank
  }
  public get selectedEntity(): string {
    const blank = '';
    if (this.entityID === '') {
      return blank
    } else {
      const found = this.entities.find( (e) => {
        return e.entityID === this.entityID;
      })
      if (typeof(found) === 'undefined') {
        return blank
      } else {
        return found.displayName
      }
    }
    // on the off chance it gets this far
    return blank
  }


  private parseDiscoFeed(): void {
    const url = `${environment.protocol}${environment.server}${environment.shibboletEndpoint}DiscoFeed`;
    this.http.get<any[]>(url).subscribe( discoFeed => {
      const entityIds: any[] = [];
      const borEntity: any = {};
      try {

        discoFeed.forEach(entity => {
          let entityID = entity?.entityID ?? '';
          let displayName = '';
          let image = '';
          if (entity.hasOwnProperty('DisplayNames') === true && entity.DisplayNames.length > 0) {
            displayName = entity?.DisplayNames[0]?.value ?? '';
          }
          displayName = displayName === '' ? entityID : displayName;
          if (entityID.toLowerCase().includes('uni') === true) {
            image = this.imagePath + 'uni.png';
          } else if (entityID.toLowerCase().includes('iastate') === true) {
            image = this.imagePath + 'isu.png';
          } else if (entityID.toLowerCase().includes('uiowa') === true) {
            image = this.imagePath + 'iowa-black.png';
          } else {
            image = this.imagePath + 'blank.png';
          }
          if (entityID.toLowerCase().includes(environment.borDiscoSearch) === true) {
            borEntity.displayName = displayName;
            borEntity.entityID = entityID;
            borEntity.image = this.imagePath + 'bor.png';
          }
          entityIds.push({
            entityID,
            displayName,
            image
          });
        });
        entityIds.unshift(borEntity);
        this.entities = [];
        this.entities = entityIds;
      } catch (e) {
        console.log(e);
      }
    });
  }
}
