import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup, NonNullableFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, tap, catchError, of } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { User } from '../../models/user';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {
  public usersUrl = environment.apiUrl + environment.usersEndpoint;
  public id = '';
  public users: User[] = [];
  public allUserNames: string[] = [];

  public fullCaseForm: FormGroup = new FormGroup<any>({
    details: this._formBuilder.group({}),

  });

  constructor( private _formBuilder: NonNullableFormBuilder, private http: HttpClient, private router: Router ) { }

  public getUsers(args: string = ''): Observable<User[]>{
    this.log(this.usersUrl);
    // args = args === '' ? '' : `?title=${args}&description=${args}`;
    return this.http.get<User[]>(this.usersUrl)
    .pipe(
      tap(data => this.log('fetched Users', data)),
      tap( (users: User[]) => { this.processUsers(users) }),
      catchError(this.handleError<User[]>('getUsers', []))
    );
  }

  public postNewUser(user: any): void {
    try {
      const url = this.usersUrl;
      this.http.post<any>(url, user).subscribe( (result)  => {
        if (result.hasOwnProperty('msg') && result.msg !== 'ok') {
          alert('There was a problem saving your response.');
        }
        this.router.navigate(['/manage-users']);

      });
    } catch (e) {
      // const id =
      //   this.addUserForm.get('pk_user_id')?.value ?? -1;
        console.log(
          `Error in postNewUser, username: ${user.username}, error: `,
          e );
      }
  }

  public setUser(id: number): User  {
    this.id = id.toString();
    return <User>this.users.find(i => i.pk_user_id === id);
  }

  public postAllData(user: User): void {
    try {
      const url = this.usersUrl + `/${user.pk_user_id}`;
        this.http.put<any>(url, user).subscribe( (result)  => {
          if (result.hasOwnProperty('msg') && result.msg !== 'ok') {
            alert('There was a problem saving your response.');
          }
          this.router.navigate(['/manage-users']);
        });
    } catch (e) {
      console.log(
        `Error in postAllData, user ID: ${user.id}, error: `,
        e
      );
    }
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for current-user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string, data: any = null) {
    if(!environment.production){
      console.log("data:", data);
      console.log("message:", message);
    }
  }

  private processUsers(users: User[]): void {
    try {
      this.allUserNames = [];
      users.forEach( user => {
        this.allUserNames.push(user?.username ?? '');
      });
    } catch(e){
      console.log('Error processing users: ', e);
    }
  }
}
