export const environment = {
  production: false,
  server: 'lts-test.iowaregents.edu/',
  protocol: 'https://',
  apiUrl: "https://lts-test.iowaregents.edu/lts/",
  casesEndpoint: "cases",
  listDatatEndpoint: "lists",
  institutionsEndpoint: "institutions",
  attorneysEndpoint: "users/inhouseattorneys",
  detailsEndpoint: "details",
  notesEndpoint: "notes",
  currentUserEndpoint: "users/currentuser",
  attachmentsEndpoint: "attachments",
  shibboletEndpoint: 'Shibboleth.sso/',
  logoutEndpoint: 'Logout',
  usersEndpoint: 'users',
  borDiscoSearch: 'uiowa'
};
