import {Injectable, ViewChild} from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { Case } from '../models/case';
import { HttpClient } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import {FormArray, FormGroup, NonNullableFormBuilder, Validators} from "@angular/forms";
import {Details} from "../models/details";
import {Litigations} from "../models/litigations";
import {AdministrativeClaims} from "../models/administrative-claims";
import {Appeals} from "../models/appeals";
import {Grievances} from "../models/grievances";
import {Notes} from "../models/notes";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import { phoneValidator } from "../validators/phone.validator";
import {ListDataService} from "./list-data/list-data.service";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CaseService  {

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  casesUrl = environment.apiUrl + environment.casesEndpoint;

  detailsUrl = environment.apiUrl + environment.detailsEndpoint;

  notesUrl = environment.apiUrl + environment.notesEndpoint;

  public detailsNotes!: MatTableDataSource<Notes>;

  public addNewNotes!: MatTableDataSource<Notes>;

  public cases: Case[] = [];

  public tempNotes: Notes[] = [];

  public state = '';

  public fullCaseForm: FormGroup = new FormGroup<any>({
    details: this._fb.group({}),
    litigations: this._fb.group({}),
    administrativeClaims: this._fb.group({}),
    appeals: this._fb.group({}),
    grievances: this._fb.group({}),
    notes: this._fb.array([])
  });

  public details: Details = new Details();
  public litigations: Litigations = new Litigations();
  public administrativeClaims: AdministrativeClaims = new AdministrativeClaims();
  public appeals: Appeals = new Appeals();
  public grievances: Grievances = new Grievances();
  public notes: Notes = new Notes();

  public id = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);

  public newCaseID$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  private _UpdateFieldOptions = {
    onlySelf: false,
    emitEvent: true,
    emitModelToViewChange: true,
    emitViewToModelChange: true,
  };

  constructor( private _fb: NonNullableFormBuilder, private http: HttpClient, private listDataService: ListDataService ) { }

  public getCases(args: string = ''): Observable<Case[]>{
    this.log(this.casesUrl);
    args = args === '' ? '' : `?title=${args}&description=${args}&notes=${args}`;
    return this.http.get<Case[]>(this.casesUrl + args)
    .pipe(
      tap(data => this.log('fetched cases', data)),
      tap( data => { if (this.state === 'search-cases') { this.listDataService.resetFilters(); }}),
      catchError(this.handleError<Case[]>('getCases', []))
    );
  }

  public formatDate(date: string | Date, action: string = ''): Date | string  {
    if (date !== '') {
      let d: any = new Date(date);
      d.setMinutes(d.getMinutes());
      if (action !== 'edit') {
        d = d.toLocaleDateString('en-US');
      }
      return d;
    } else {
      return '';
    }
  }

  public validForm(): boolean {
    return !!((!this.fullCaseForm.get('details.resolution_date') && this.fullCaseForm.get('details.title')?.valid &&
      this.fullCaseForm.get('details.type')?.valid && this.fullCaseForm.get('details.description')?.valid &&
      this.fullCaseForm.get('details.institutions')?.valid && this.fullCaseForm.get('details.filed_date')?.valid) ||
      (this.fullCaseForm.get('details.resolution_date') && this.fullCaseForm.get('details.title')?.valid &&
        this.fullCaseForm.get('details.resolution_date')?.valid &&
      this.fullCaseForm.get('details.type')?.valid && this.fullCaseForm.get('details.description')?.valid &&
      this.fullCaseForm.get('details.institutions')?.valid && this.fullCaseForm.get('details.filed_date')?.valid));
  }

  public setCase(id: number): Case  {
    this.id = id.toString();
    return <Case>this.cases.find(i => i.pk_case_id === id);
  }

  public sendEditEmail(pk_case_id: number = 0): void {
    if (pk_case_id > 0) {
      const url = environment.apiUrl + 'sendeditemail/' + pk_case_id;
      this.http.post<any>(url, '').subscribe( result => {
        //do nothing
      });
    }
  }

  public getDetails(): Observable<any>  {
    let url = this.detailsUrl + `/${this.id}`;
    return this.http.get<any>(url).pipe(
      tap(
     details => {
      if (details.cases.length > 0) {
        this.details = details.cases[0];
      }
      if (details.litigations.length > 0) {
        this.litigations = details.litigations[0];
      }
      if (details.administrativeClaims.length > 0) {
        this.administrativeClaims = details.administrativeClaims[0];
      }
      if (details.appeals.length > 0) {
        this.appeals = details.appeals[0];
      }
      if (details.grievances.length > 0) {
        this.grievances = details.grievances[0];
      }
      if (details.notes.length > 0) {
        this.notes = details.notes;
      }
      this.fillForm();
    }));
  }

  public getNewCase(): void {
    this.fullCaseForm.reset();
    this.details = new Details();
    this.litigations = new Litigations();
    this.administrativeClaims = new AdministrativeClaims();
    this.appeals = new Appeals();
    this.grievances = new Grievances();
    this.notes = new Notes();
    this.details.pk_case_id = -1;
    this.litigations.fk_case_id = -1;
    this.administrativeClaims.fk_case_id = -1;
    this.appeals.fk_case_id = -1;
    this.grievances.fk_case_id = -1;
    this.fillForm();
  }

  public getNewEmptyCase(): void {
    this.fullCaseForm = new FormGroup<any>({
      details: this._fb.group({}),
      litigations: this._fb.group({}),
      administrativeClaims: this._fb.group({}),
      appeals: this._fb.group({}),
      grievances: this._fb.group({}),
      notes: this._fb.array([])
    });

    this.details = new Details();
    this.litigations = new Litigations();
    this.administrativeClaims = new AdministrativeClaims();
    this.appeals = new Appeals();
    this.grievances = new Grievances();
    this.notes = new Notes();
    this.details.pk_case_id = -1;
    this.litigations.fk_case_id = -1;
    this.administrativeClaims.fk_case_id = -1;
    this.appeals.fk_case_id = -1;
    this.grievances.fk_case_id = -1;
    this.fillForm();
  }


  public fillForm():  void  {
    this.fillNotes(this.notes);
    this.fillDetails(this.details);
    this.fillLitigations(this.litigations);
    this.fillAdministrativeClaims(this.administrativeClaims);
    this.fillGrievances(this.grievances);
    this.fillAppeals(this.appeals);
  }

  public postAllData(): void {
    try {
      const url = this.detailsUrl + `/${this.id}`;
      this.details = this.fullCaseForm.get('details')?.value;
      this.litigations = this.fullCaseForm.get('litigations')?.value;
      this.administrativeClaims = this.fullCaseForm.get('administrativeClaims')?.value;
      this.appeals = this.fullCaseForm.get('appeals')?.value;
      this.grievances = this.fullCaseForm.get('grievances')?.value;
      this.notes = this.fullCaseForm.get('notes')?.value;
      let fullDetails = {
        "cases":  [
            this.details
        ],
        "litigations":  [
          this.litigations
        ],
        "administrativeClaims":  [
          this.administrativeClaims
        ],
        "appeals":  [
          this.appeals
        ],
        "grievances":  [
          this.grievances
        ],
        "notes":  [
          this.notes
        ],
      }
        this.http.post<any>(url, fullDetails).subscribe( (result)  => {
          if (result.hasOwnProperty('msg') && result.msg !== 'ok') {
            alert('There was a problem saving your response.');
          } else {
            if (result.hasOwnProperty('pk_ids')) {
              const pk_administrative_claim_id = (parseInt(result.pk_ids?.pk_administrative_claim_id ?? '-1'));
              const pk_appeal_id = (parseInt(result.pk_ids?.pk_appeal_id ?? '-1'));
              const pk_litigation_id = (parseInt(result.pk_ids?.pk_litigation_id ?? '-1'));
              const pk_grievance_id = (parseInt(result.pk_ids?.pk_grievance_id ?? '-1'));

              if (isNaN(pk_administrative_claim_id) === false && pk_administrative_claim_id > 0) {
                this.fullCaseForm.get('administrativeClaims.pk_administrative_claim_id')?.setValue(
                  pk_administrative_claim_id,
                  this._UpdateFieldOptions
                );
              }
              if (isNaN(pk_appeal_id) === false && pk_appeal_id > 0) {
                this.fullCaseForm.get('appeals.pk_appeal_id')?.setValue(
                  pk_appeal_id,
                  this._UpdateFieldOptions
                );
              }
              if (isNaN(pk_litigation_id) === false && pk_litigation_id > 0) {
                this.fullCaseForm.get('litigation.pk_litigation_id')?.setValue(
                  pk_litigation_id,
                  this._UpdateFieldOptions
                );
              }
              if (isNaN(pk_grievance_id) === false && pk_administrative_claim_id > 0) {
                this.fullCaseForm.get('grievances.pk_grievance_id')?.setValue(
                  pk_grievance_id,
                  this._UpdateFieldOptions
                );
              }

            }
          }
        });
      this.getCases().subscribe( cases => {
        this.cases = cases;
      });
    } catch (e) {
      const id =
        this.fullCaseForm.get('details.pk_case_id')?.value ?? -1;
      console.log(
        `Error in postAllData, case ID: ${id}, error: `,
        e
      );
    }
  }

  public postNewData(): void {
    try {
      let url = this.detailsUrl;
      this.details = this.fullCaseForm.get('details')?.value;
      this.litigations = this.fullCaseForm.get('litigations')?.value;
      this.administrativeClaims = this.fullCaseForm.get('administrativeClaims')?.value;
      this.appeals = this.fullCaseForm.get('appeals')?.value;
      this.grievances = this.fullCaseForm.get('grievances')?.value;
      this.notes = this.fullCaseForm.get('notes')?.value;
      let fullDetails = {
        "cases":  [
          this.details
        ],
        "litigations":  [
          this.litigations
        ],
        "administrativeClaims":  [
          this.administrativeClaims
        ],
        "appeals":  [
          this.appeals
        ],
        "grievances":  [
          this.grievances
        ],
        "notes":  [
          this.notes
        ],
      }

      this.newCaseID$.next(0);

      this.http.post<any>(url, fullDetails).subscribe( (result)  => {
        if (result.hasOwnProperty('msg') && result.msg !== 'ok') {
          alert('There was a problem saving your response.');
        } else {
          if (result.hasOwnProperty('pk_ids')) {
            const pk_administrative_claim_id = (parseInt(result.pk_ids?.pk_administrative_claim_id ?? '-1'));
            const pk_appeal_id = (parseInt(result.pk_ids?.pk_appeal_id ?? '-1'));
            const pk_litigation_id = (parseInt(result.pk_ids?.pk_litigation_id ?? '-1'));
            const pk_grievance_id = (parseInt(result.pk_ids?.pk_grievance_id ?? '-1'));

            const pk_case_id = (parseInt(result.pk_ids?.pk_case_id ?? 0));
            this.newCaseID$.next(pk_case_id);

            if (isNaN(pk_administrative_claim_id) === false && pk_administrative_claim_id > 0) {
              this.fullCaseForm.get('administrativeClaims.pk_administrative_claim_id')?.setValue(
                pk_administrative_claim_id,
                this._UpdateFieldOptions
              );
            }
            if (isNaN(pk_appeal_id) === false && pk_appeal_id > 0) {
              this.fullCaseForm.get('appeals.pk_appeal_id')?.setValue(
                pk_appeal_id,
                this._UpdateFieldOptions
              );
            }
            if (isNaN(pk_litigation_id) === false && pk_litigation_id > 0) {
              this.fullCaseForm.get('litigation.pk_litigation_id')?.setValue(
                pk_litigation_id,
                this._UpdateFieldOptions
              );
            }
            if (isNaN(pk_grievance_id) === false && pk_administrative_claim_id > 0) {
              this.fullCaseForm.get('grievances.pk_grievance_id')?.setValue(
                pk_grievance_id,
                this._UpdateFieldOptions
              );
            }

          }
          this.getCases().subscribe( cases => {
            this.cases = cases;
          });
        }
      });
    } catch (e) {
      const id =
        this.fullCaseForm.get('details.pk_case_id')?.value ?? -1;
      console.log(
        `Error in postAllData, case ID: ${id}, error: `,
        e
      );
    }
  }

  public postNewNote(note: any): void {
    try {
      const url = this.notesUrl;
      this.http.post<any>(url, note).subscribe( (result)  => {
        if (result.hasOwnProperty('msg') && result.msg !== 'ok') {
          alert('There was a problem saving your response.');
        } else {
          this.getNotes();
        }
      });
    } catch (e) {
      const id =
        this.fullCaseForm.get('details.pk_case_id')?.value ?? -1;
      console.log(
        `Error in postNewNote, case ID: ${id}, error: `,
        e
      );
    }
  }

  public getNotes(): void {
    try {
      const url = this.casesUrl + `/${this.id}`;
      this.http.get<any>(url).subscribe( (result)  => {
        if (result.hasOwnProperty('msg') && result.msg !== 'ok') {
          alert('There was a problem saving your response.');
        } else {
          this.detailsNotes = new MatTableDataSource(result[0].notes);
          this.detailsNotes.sort = this.sort;
          this.detailsNotes.paginator = this.paginator;
        }
      });
    } catch (e) {
      const id =
        this.fullCaseForm.get('details.pk_case_id')?.value ?? -1;
      console.log(
        `Error in postNewNote, case ID: ${id}, error: `,
        e
      );
    }
  }

  public deleteNote(note: any): void {
    try {
      let url = this.notesUrl + `/${note.pk_note_id}`;
      this.http.delete<any>(url).subscribe( result => {
        this.getNotes();
      });
    } catch (e) {
      const id =
        this.fullCaseForm.get('details.pk_case_id')?.value ?? -1;
      console.log(
        `Error in deleteNote, case ID: ${id}, error: `,
        e
      );
    }
  }

  public fillNotes(data: any = []): void {
    if(typeof(data?.length) === 'undefined'){
      return;
    }
    try {
      (this.fullCaseForm.get('notes') as FormArray).clear();
      data?.forEach((note: any) => {
        (this.fullCaseForm.get('notes') as FormArray).push(
          this._fb.group({
            pk_note_id: [note.pk_note_id ?? ''],
            fk_case_id: [note.fk_case_id ?? ''],
            pk_attachment_id: [note.pk_attachment_id ?? ''],
            fk_note_id: [note.fk_note_id ?? ''],
            note: [note.note ?? ''],
            created_by: [note.created_by ?? ''],
            created_at: [note.created_at ?? ''],
            updated_by: [note.updated_by ?? ''],
            updated_at: [note.updated_at ?? ''],
            attachments: [note.attachments ?? []]
          })
        );
      });
    } catch (e) {
      console.log('Error filling full case: ', e);
    }
  }

  public fillDetails(data: any): void {
    console.log('details data is', data);
    console.log('fullcaseform details are', this.fullCaseForm.get('details'));
    try {
      const newCase: FormGroup = this.fullCaseForm.get('details') as FormGroup;
      newCase.setControl(
        'pk_case_id',
        this._fb.control(parseInt(data.pk_case_id ?? '') ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'title',
        this._fb.control(data.title ?? '', {
          validators: [Validators.required],
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'created_at',
        this._fb.control(data.created_at ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'created_by_firstname',
        this._fb.control(data.created_by_firstname ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'created_by_lastname',
        this._fb.control(data.created_by_lastname ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'created_by_email',
        this._fb.control(data.created_by_email ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'updated_by_email',
        this._fb.control(data.updated_by_email ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'delete_request_firstname',
        this._fb.control(data.delete_request_firstname ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'delete_request_lastname',
        this._fb.control(data.delete_request_lastname ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'delete_request_email',
        this._fb.control(data.delete_request_email ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'is_delete_requested',
        this._fb.control(data.is_delete_requested ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'delete_requested_by',
        this._fb.control(data.delete_requested_by ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'delete_email_sent',
        this._fb.control(data.delete_email_sent ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'type',
        this._fb.control(data.type ?? '', {
          validators: [Validators.required, Validators.min(1)],
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'subtypes',
        this._fb.control([], {
          // validators: Validators.required,
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'subtypes_values',
        this._fb.control([], {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'subtypes_full',
        this._fb.control([], {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'classification',
        this._fb.control(data.classification ?? '', {
          validators: Validators.required,
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'classification_value',
        this._fb.control(data.classification_value ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'description',
        this._fb.control(data.description ?? '', {
          validators: Validators.required,
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'institutions',
        this._fb.control(data.institutions ?? [], {
          validators: Validators.required,
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'institutions_values',
        this._fb.control(data.institutions_values ?? [], {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'institutions_full',
        this._fb.control(data.institutions_full ?? [], {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'in_house_attorneys',
        this._fb.control(data.in_house_attorneys ?? [], {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'in_house_attorneys_values',
        this._fb.control(data.in_house_attorneys_values ?? [], {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'in_house_attorneys_full',
        this._fb.control(data.in_house_attorneys_full ?? [], {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'attorney_generals',
        this._fb.control(data.attorney_generals ?? [], {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'attorney_generals_values',
        this._fb.control(data.attorney_generals_values ?? [], {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'attorney_generals_full',
        this._fb.control(data.attorney_generals_full ?? [], {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'attorney_general_assigned',
        this._fb.control(data.attorney_general_assigned ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'filed_date',
        this._fb.control(data.filed_date ?? '', {
          validators: Validators.required,
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'resolution_date',
        this._fb.control(data.resolution_date ?? '', {
          //validators: Validators.required,
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'remedy_sought',
        this._fb.control(data.remedy_sought ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'notes',
        this._fb.control(data.notes ?? [], {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'notes_combined',
        this._fb.control(data.notes_combined ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'in_house_attorney_id',
        this._fb.control(data.in_house_attorney_id ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'in_house_attorney_first',
        this._fb.control(data.in_house_attorney_first?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'in_house_attorney_last',
        this._fb.control(data.in_house_attorney_last ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'in_house_attorney_email',
        this._fb.control(data.in_house_attorney_email ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'in_house_attorney_phone',
        this._fb.control(data.in_house_attorney_phone ?? '', {
          // validators: phoneValidator(),
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'in_house_attorney_institution',
        this._fb.control(data.in_house_attorney_institution ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'is_closed',
        this._fb.control(data.is_closed ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      console.log('fullcaseform details are now', this.fullCaseForm.get('details'));
    } catch (e) {
      console.log('Error filling full case: ', e);
    }
  }

  public fillLitigations(data: any): void {
    console.log('data for litigations is', data);
    try {
      const newCase: FormGroup = this.fullCaseForm.get('litigations') as FormGroup;
      newCase.setControl(
        'pk_litigation_id',
        this._fb.control(parseInt(data.pk_litigation_id ?? '') ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'fk_case_id',
        this._fb.control(parseInt(data.fk_case_id ?? '') ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'fk_admin_claim_id',
        this._fb.control(parseInt(data.fk_admin_claim_id ?? '') ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'tort_claim_id',
        this._fb.control(data.tort_claim_id ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'court_assigned_number',
        this._fb.control(data.court_assigned_number ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'venue',
        this._fb.control(data.venue?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'remedy_amount',
        this._fb.control(data.remedy_amount ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'case_disposition',
        this._fb.control(data.case_disposition ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'settlement_amount_paid',
        this._fb.control(data.settlement_amount_paid ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'settlement_funding_src',
        this._fb.control(data.settlement_funding_src ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'is_outside_counsel_required',
        this._fb.control(data.is_outside_counsel_required ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'outside_counsel_justification',
        this._fb.control(data.outside_counsel_justification ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'recommended_outside_counsel',
        this._fb.control(data.recommended_outside_counsel ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'outside_counsel_fees',
        this._fb.control(data.outside_counsel_fees ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'outside_counsel_amount_paid',
        this._fb.control(data.outside_counsel_amount_paid ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'attorney_general_assigned',
        this._fb.control(data.attorney_general_assigned ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'attorney_general_names',
        this._fb.control(data.attorney_general_names ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'outside_counsel_amount_paid',
        this._fb.control(data.outside_counsel_amount_paid ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'jurisdiction',
        this._fb.control(data.jurisdiction ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'litigation_hold',
        this._fb.control(data.litigation_hold ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'remedy_sought',
        this._fb.control(data.remedy_sought ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'opposing_counsel',
        this._fb.control(data.opposing_counsel ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'plaintiff',
        this._fb.control(data.plaintiff ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'other_plaintiff',
        this._fb.control(data.other_plaintiff ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'defendant',
        this._fb.control(data.defendant ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'jurisdiction',
        this._fb.control(data.jurisdiction ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'is_court_appeal_filed',
        this._fb.control(data.is_court_appeal_filed ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'other_defendant',
        this._fb.control(data.other_defendant ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'created_by',
        this._fb.control(data.created_by ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'created_at',
        this._fb.control(data.created_at ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'updated_by',
        this._fb.control(data.updated_by ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'updated_at',
        this._fb.control(data.updated_at ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'outside_counsel_email_sent',
        this._fb.control(parseInt(data.outside_counsel_email_sent ?? '') ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'opposing_counsel_attorney',
        this._fb.control(data.opposing_counsel_attorney ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'opposing_counsel_phone_number',
        this._fb.control(data.opposing_counsel_phone_number ?? '', {
          validators: phoneValidator(),
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'opposing_counsel_law_firm',
        this._fb.control(data.opposing_counsel_law_firm ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'opposing_counsel_city',
        this._fb.control(data.opposing_counsel_city ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'opposing_counsel_state',
        this._fb.control(data.opposing_counsel_state ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'opposing_counsel_address',
        this._fb.control(data.opposing_counsel_address ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'tort_claim_id',
        this._fb.control(parseInt(data.tort_claim_id ?? '') ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'tort_claim_text',
        this._fb.control(data.tort_claim_text ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'mediation_date',
        this._fb.control(data.mediation_date ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'trial_date',
        this._fb.control(data.trial_date ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
    } catch (e) {
      console.log('Error filling full case: ', e);
    }
  }

  public fillAdministrativeClaims(data: any): void {
    console.log('data for admin claim is', data);
    try {
      const newCase: FormGroup = this.fullCaseForm.get('administrativeClaims') as FormGroup;
      newCase.setControl(
        'pk_administrative_claim_id',
        this._fb.control(parseInt(data.pk_administrative_claim_id ?? -1) ?? -1, {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'fk_case_id',
        this._fb.control(this.fullCaseForm.get('details.pk_case_id')?.value ?? -1, {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'claim_number',
        this._fb.control(data.claim_number ?? '', {
          updateOn: 'change',
          validators: Validators.required
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'claim_submitted_date',
        this._fb.control(data.claim_submitted_date ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'created_by',
        this._fb.control(data.created_by ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'created_at',
        this._fb.control(data.created_at ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'updated_by',
        this._fb.control(data.updated_by ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'updated_at',
        this._fb.control(data.updated_at ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'agency',
        this._fb.control(data.agency ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'settlement_amount',
        this._fb.control(data.settlement_amount ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'settlement_funding_src',
        this._fb.control(data.settlement_funding_src ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
    } catch (e) {
      console.log('Error filling full case: ', e);
    }
  }

  public fillAppeals(data: any): void {
    try {
      const newCase: FormGroup = this.fullCaseForm.get('appeals') as FormGroup;
      newCase.setControl(
        'pk_appeal_id',
        this._fb.control(parseInt(data.pk_appeal_id ?? '') ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'appeal_type',
        this._fb.control(data.appeal_type ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'action_appealed',
        this._fb.control(data.action_appealed ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'has_been_presented_to_board',
        this._fb.control(data.has_been_presented_to_board ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'presented_date',
        this._fb.control(data.presented_date ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'appeal_decision',
        this._fb.control(data.appeal_decision ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'created_by',
        this._fb.control(data.created_by ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'created_at',
        this._fb.control(data.created_at ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'updated_by',
        this._fb.control(data.updated_by ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'updated_at',
        this._fb.control(data.updated_at ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      // newCase.setControl(
      //   'bor_appeals_case_subtype',
      //   this._fb.control(data.bor_appeals_case_subtype ?? '', {
      //     updateOn: 'change',
      //   }),
      //   { emitEvent: true }
      // );
    } catch (e) {
      console.log('Error filling full case: ', e);
    }
  }

  public fillGrievances(data: any): void {
    console.log('data for grievance is', data);
    try {
      const newCase: FormGroup = this.fullCaseForm.get('grievances') as FormGroup;
      newCase.setControl(
        'pk_grievance_id',
        this._fb.control(parseInt(data.pk_grievance_id ?? '') ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'fk_case_id',
        this._fb.control(parseInt(data.fk_case_id ?? '') ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'grievant_number',
        this._fb.control(data.grievant_number ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'grievant_name',
        this._fb.control(data.grievant_name ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'association',
        this._fb.control(data.association ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'bargaining_unit',
        this._fb.control(data.bargaining_unit ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'violation_article_section',
        this._fb.control(data.violation_article_section ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'third_step_description',
        this._fb.control(data.third_step_description ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'is_arbitration_appeal',
        this._fb.control(data.is_arbitration_appeal ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'arbitration_assignment',
        this._fb.control(data.arbitration_assignment ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'arbitration_assessment',
        this._fb.control(data.arbitration_assessment ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'is_bor_appeal',
        this._fb.control(data.is_bor_appeal ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'bor_decision',
        this._fb.control(data.bor_decision ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'is_court_appeal_filed',
        this._fb.control(data.is_court_appeal_filed ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'created_by',
        this._fb.control(data.created_by ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'created_at',
        this._fb.control(data.created_at ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'updated_by',
        this._fb.control(data.updated_by ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'updated_at',
        this._fb.control(data.updated_at ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'settlement_amount',
        this._fb.control(data.settlement_amount ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newCase.setControl(
        'settlement_funding_src',
        this._fb.control(data.settlement_funding_src ?? '', {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
    } catch (e) {
      this.log('Error filling full case: ', e);
    }
  }

  public deleteCase(id: unknown): Observable<any>{
    try {
      const url = this.casesUrl + `/${id}`;
      this.log('attempting to delete case', id);
      this.log(url);
      return this.http.delete<any>(url);
      // this.http.delete<any>(url).subscribe(x=> this.log(x));
    } catch (e) {
      this.handleError(
        `Error in deleting, case ID: ${id}, error: `,
        e
      );
      return of({msg: 'error', error: e});
    }
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      // console.error(error); // log to console instead

      // TODO: better job of transforming error for current-user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string, data: any = null) {
    if(!environment.production){
      // console.log("data:", data);
      // console.log("message:", message);
    }
  }

}
