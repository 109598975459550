<div class="add-user-container">
  <p class="flex-row margin-sm-bottom">
    <button mat-stroked-button routerLink="/manage-users" color="primary">Back to Manage Users</button>
  </p>
  <section class="margin-bottom case-details-heading">
    <h1 class="bold-headline--serif">Edit User {{user.firstname}} {{ user.lastname }}</h1>
  </section>

  <!-- User Details -->
  <section class="case-details margin-top padding-top" [formGroup]="userForm">
    <h2>User Details</h2>
    <hr>
    <!-- Is Inactive -->
    <section class="flex-md-row margin-top margin-sm-bottom">
      <h3 class="offscreen">Inactive</h3>
      <mat-checkbox
        color="primary"
        (change)="updateInactive($event)"
        [checked]="isInactive()"
      >
        Inactive
      </mat-checkbox>
    </section>

        <!-- UserName & Institution -->
        <div class="flex-md-row">
          <section class="margin-sm-right width-md-50">
            <h3 class="offscreen">Username</h3>
            <mat-form-field class="margin-right width-md-100">
              <mat-label>Username*</mat-label>
              <input
                matInput
                #userNameInput
                formControlName="username"
              />
              <mat-error>
                {{ userNameError }}
              </mat-error>
            </mat-form-field>
          </section>
          <section class="width-md-50">
            <h3 class="offscreen">Institution</h3>
            <mat-form-field class="width-md-100">
              <mat-label>Institution</mat-label>
              <mat-select  formControlName="institution" [value]="user.institution">
                  <mat-option *ngFor="let data of listDataService.institutions$.value" [value]="data.value" >
                    {{ data.name }}
                  </mat-option>
                </mat-select>
            </mat-form-field>
          </section>
        </div>

    <!-- First & Last Name -->
    <div class="flex-md-row">
      <div class="flex-md-row width-md-50 margin-sm-right">
        <section class="width-md-50 margin-sm-right">
          <h3 class="offscreen">First Name</h3>
          <mat-form-field class="width-md-100">
            <mat-label>First Name</mat-label>
            <input matInput formControlName="firstname" [value]="user.firstname"
                   (change)="updateInput(userForm,'firstname', firstname.value)"

                   #firstname
            />
            <mat-error>Required</mat-error>
          </mat-form-field>
        </section>
        <section class="width-md-50">
          <h3 class="offscreen">Last Name</h3>
          <mat-form-field class="width-md-100">
            <mat-label>Last Name</mat-label>
            <input matInput formControlName="lastname" [value]="user.lastname"/>
            <mat-error>Required</mat-error>
          </mat-form-field>
        </section>
      </div>
      <div class="flex-md-row width-md-50">
        <section class="width-md-50 margin-sm-right">
          <h3 class="offscreen">Phone Number</h3>
          <mat-form-field class="width-md-100">
            <mat-label>Phone Number</mat-label>
            <input  matInput  formControlName="phone_primary" appPhone [value]="user.phone_primary">
            <mat-error>Invalid phone number</mat-error>
          </mat-form-field>
        </section>
        <section class="width-md-50">
          <h3 class="offscreen">Alternate Phone Number</h3>
          <mat-form-field class="width-md-100">
            <mat-label>Alternate Phone Number</mat-label>
            <input appPhone matInput formControlName="phone_alternate" [value]="user.phone_alternate" />
            <mat-error>Invalid phone number</mat-error>
          </mat-form-field>
        </section>
      </div>
    </div>

    <!-- Email -->
    <section class="flex-md-row">
      <section class="margin-sm-right width-md-50">
        <h3 class="offscreen">Email</h3>
        <mat-form-field class="width-md-100">
          <mat-label>Email</mat-label>
          <input matInput
                 type="email"
                 formControlName="email_primary" [value]="user.email_primary" />
          <mat-error *ngIf="userForm.controls['email_primary'].hasError('required') && !userForm.controls['email_primary'].hasError('email')">Required</mat-error>
          <mat-error *ngIf="userForm.controls['email_primary'].hasError('email') && !userForm.controls['email_primary'].hasError('required')">Invalid email address</mat-error>
        </mat-form-field>
      </section>
      <section class="width-md-50">
        <h3 class="offscreen">Alternate Email</h3>
        <mat-form-field class="width-md-100">
          <mat-label>Alternate Email</mat-label>
          <input matInput
                 type="email"
                 formControlName="email_alternate" [value]="user.email_alternate" />
          <mat-error *ngIf="userForm.controls['email_alternate'].hasError('email')">Invalid email address</mat-error>
        </mat-form-field>
      </section>
    </section>

    <section>
      <h3>Select Role(s) for this User:</h3>
      <mat-form-field class="width-md-50">
        <mat-label>Roles</mat-label>
        <mat-select multiple
                    formControlName="roles"
                    (selectionChange)="updateRoles($event)"
        >
          <mat-option *ngFor="let data of listDataService.roles$ | async" [value]="data.value"

          >
            {{ data.name }}
          </mat-option>
        </mat-select>
        <mat-error>Required</mat-error>
      </mat-form-field>
    </section>
  </section>

    <!-- Action Buttons -->
    <section class="margin-top">
        <button
          mat-flat-button
          color="primary"
          class="margin-right"
          (click)="updateUser()"
          [disabled]="userForm.valid === false"
        >
          Save
        </button>
        <button
            mat-stroked-button
            [routerLink]="'/manage-users'"
            color="warn">Cancel
        </button>
    </section>
</div>
