<div id="confirm-container">
  <header class="heading-bar flex">
    <h2 mat-dialog-title>Are you sure you want to delete this case?</h2>
  </header>
  <mat-dialog-content class="margin-sm-top">
    <section class="margin-sm-bottom">
      <h3 class="margin-none">Case Title</h3>
      <p>
        {{data.title}}
      </p>
    </section>
    <section>
      <h3 class="margin-none">Case Details</h3>
      <p class="text-overflow-hidden">
        {{data.description}}
      </p>
    </section>
  </mat-dialog-content>

  <mat-dialog-actions
    class="flex-row space-between margin-top"
  >
    <button
      mat-stroked-button
      mat-dialog-close="cancel"
      color="primary"
    >
      Cancel
    </button>


    <button
      mat-stroked-button
      (click)="deleteCase()"
      color="warn"
    >
      Delete Case
    </button>

  </mat-dialog-actions>
</div>

