<mat-expansion-panel class="margin-bottom">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Filter Settings
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="flex-wrap-row margin-top">
      <app-dropdown-select
      *ngFor="let dropdownSelect of dropdownSelects"
      [dropdownSelect]="dropdownSelect"
      (dropdownOutput)="emitDropdown($event)"
      class="width-wrap-25"
      ></app-dropdown-select>
    </div>

    <div class="flex-wrap-row margin-sm-top date-filters">
      <app-date-range-picker
      *ngFor="let dateRangePicker of dateRangePickers"
      [dateRangePicker]="dateRangePicker"
      (dateOutput)="emitDate($event)"
      class="width-wrap-25 margin-sm-bottom"
      ></app-date-range-picker>
<!--      (startOutput)="emitStartDate($event)"-->
<!--      (endOutput)="emitEndDate($event)"-->

    </div>
    <div class="flex-row margin-sm-top flex-end">
      <button
        mat-stroked-button
        color="primary"
        (click)="clearAllFilters()"
      >
        Clear All Filters
      </button>
    </div>
</mat-expansion-panel>
