import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from "../../modules/material/material.module";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CaseService } from "../../services/case.service";
import { Router } from "@angular/router";
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBar } from "@angular/material/snack-bar";


@Component({
  selector: 'app-delete-case-request',
  standalone: true,
  imports: [CommonModule, MaterialModule],
  providers: [
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {
      duration: 2500}}
  ],
  templateUrl: './delete-case-request.component.html',
  styleUrls: ['./delete-case-request.component.scss']
})
export class DeleteCaseRequestComponent {
  public snackBarDuration: number = 5000; // seconds
  public title: string = (this?.data?.title ?? 'No title');
  public description: string = (this?.data?.description ?? 'No title');
  //private pk_case_id: number = (this?.data?.pk_case_id ?? -1);
  
  constructor (public dialogRef: MatDialogRef<DeleteCaseRequestComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {pk_case_id: number, title: string, description: string},
    private caseService: CaseService, private router: Router, private snackBar: MatSnackBar) { }

  public confirmDeleteCaseRequest(): void {
    try {
      const snackbarRef = this.snackBar.open('Delete Case Request Sent', '', {
        duration: 3000
      });
      // this.caseService.confirmDeleteCaseRequest(this.data.pk_case_id).subscribe( (deleteResult: any) => {
      //   if (deleteResult.hasOwnProperty('msg') && deleteResult.msg === 'error') {
      //     snackbarRef.dismiss();
      //     this.snackBar.open('Error Requesting to Delete Case', '', {
      //       duration: 5000
      //     })
      //   }
      // });
      this.dialogRef.close('delete');
    } catch (e) {
      this.snackBar.open('Error Requesting to Delete Case', '', {
        duration: 5000
      });
    }
  }
}
