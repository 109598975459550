import { Injectable } from '@angular/core';
import { DateRangePickerContent } from '../components/widgets/date-range-picker/date-range-picker.content.interface';

@Injectable({
  providedIn: 'root'
})
export class DateRangePickerService {
    public dateCreated: DateRangePickerContent = {};
    public dateModified: DateRangePickerContent = {};
    public dateFiledSubmitted: DateRangePickerContent = {};
    public trialDate: DateRangePickerContent = {};
    public mediationDate: DateRangePickerContent = {};

    public parentInput(value: Array<any> | any): void {
      let msg = '';
      let showAlert = false;
      if (value instanceof Array) {
        msg = value.length === 1 ? value[0] : value.join(', ');
      } else if (value instanceof Object) {
        msg = JSON.stringify(value);
      } else {
        msg = value.toString();
      }
      window.location.href = msg;
    }

    public setupDateRangePickers(): void {
      this.dateCreated = {
        title: 'Date Created',
        dateRangeLabel: 'Date Created',
        content: ['(add date picker 1)']
      };
      this.dateModified = {
        title: 'Date Modified',
        dateRangeLabel: 'Date Modified',
        content: ['(add date picker 5)']
      }

      this.dateFiledSubmitted = {
        title: 'Date Filed/Submitted',
        dateRangeLabel: 'Date Filed/Submitted',
        content: ['(add date picker 2)']
      };

      this.trialDate = {
        title: 'Trial Date',
        dateRangeLabel: 'Trial Date',
        content: ['(add date picker 3)']
      };

      this.mediationDate = {
        title: 'Mediation Date',
        dateRangeLabel: 'Mediation Date',
        content: ['(add date picker 4)']
      };
    }
}
