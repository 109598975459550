import { Component, OnInit, ViewChild, ChangeDetectorRef} from '@angular/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { ButtonToggleGrpComponent } from '../shared/components/widgets/button-toggle-grp/button-toggle-grp.component';
import { CheckboxComponent } from '../shared/components/widgets/checkbox/checkbox.component';
import { DateRangeOutput } from '../shared/components/widgets/date-range-picker/date-range-picker.component';
import { TableComponent } from '../shared/components/widgets/table/table.component';
import { Case } from '../shared/models/case';
import { CaseService } from '../shared/services/case.service';
import { CsvExportService } from '../shared/services/csv-export/csv-export.service';
import { DropdownSelectService } from "../shared/services/dropdown-select.service";
import { ListDataService } from "../shared/services/list-data/list-data.service";
import {CurrentUserService} from "../shared/services/current-user/current-user.service";

@Component({
  selector: 'app-search-cases',
  templateUrl: './search-cases.component.html',
  styleUrls: ['./search-cases.component.scss']
})
export class SearchCasesComponent implements OnInit {
  @ViewChild(ButtonToggleGrpComponent) statusGroup!: ButtonToggleGrpComponent;
  @ViewChild(TableComponent) table!: TableComponent;
  @ViewChild(CheckboxComponent) checkbox!: CheckboxComponent;

  public parentCases: Case[] = [];
  public searchedCases: Case[] = [];
  public color = 'primary';
  public mode = 'indeterminate' as ProgressSpinnerMode;
  public spinnerShow = true;
  public searchTerms: any;
  public renderTable = true;
  public displayedColumns: string[] = ['title', 'description', 'type_value', /*'created_at', */ 'max_date', 'institutions_values', 'in_house_attorneys'];
  public caseFilters: Record<string, unknown[]>  = this.getEmptyCaseFilters();

  constructor(
    public dropDownService: DropdownSelectService,
    private csvExportService: CsvExportService,
    public listDataService: ListDataService,
    public currentUserService: CurrentUserService,
    private caseService: CaseService,
    private changeDetector: ChangeDetectorRef) {
    this.caseService.state = 'search-cases';
    this.listDataService.getListData();
    this.getCases();
  }

  ngOnInit(): void {
    this.caseService.state = 'search-cases';
    this.listDataService.getListData();
    this.getCases();
  }

  public toggleDateColumns(){

    this.renderTable = false;

    if(this.checkbox.checked) this.displayedColumns =
     ['title', 'description', 'created_at', 'type_value', 'institutions_values', 'in_house_attorneys', 'trial_date', 'mediation_date'];
    else this.displayedColumns =
     ['title', 'description', 'created_at', 'type_value', 'institutions_values', 'in_house_attorneys'];
     this.changeDetector.detectChanges();

    this.renderTable = true;

  }

  public search($event: any) {
    this.table.spinnerShow = this.spinnerShow;
    this.getCases($event);
  }

  public getCases(args: string = ''): void{
    try{
      this.searchTerms = args;
      this.spinnerShow = true;
      this.caseService.getCases(args).subscribe( cases => {
        this.searchedCases = cases;
        this.caseService.cases = cases;
        this.filter(cases);
        this.spinnerShow = false;
      });
    } catch(e) {
    }
  }

  public addFilter(name: string, value: string){
    let current = this.caseFilters[name];
    value = value.toString().toLowerCase().trim();
    if (!current.includes(value)){
      this.caseFilters[name].push(value);
    } else{
      this.caseFilters[name] = this.caseFilters[name].filter(x => x !== value);
    }
    this.filter(this.searchedCases);
  }

  filter(cases: Case[] = this.searchedCases){
    this.parentCases = cases.filter(x =>{

      return this.applyDropdownFilter(x,this.caseFilters) &&

        x.is_closed.toString().match(new RegExp(this.caseFilters['is_closed'].join('|'), 'gi')) !== null &&

        new Date(this.caseFilters['created_at'][0] as string).getTime() <=
          new Date(this.isNullOrEmpty(x.created_at) ? this.caseFilters['created_at'][0] as string : x.created_at).getTime() &&
          new Date(this.caseFilters['created_at'][1] as string).getTime() >=
          new Date(this.isNullOrEmpty(x.created_at) ? this.caseFilters['created_at'][0] as string : x.created_at).getTime() &&

        new Date(this.caseFilters['max_date'][0] as string).getTime() <=
          new Date(this.isNullOrEmpty(x.max_date) ? this.caseFilters['max_date'][0] as string : x.max_date).getTime() &&
          new Date(this.caseFilters['max_date'][1] as string).getTime() >=
          new Date(this.isNullOrEmpty(x.max_date) ? this.caseFilters['max_date'][0] as string : x.max_date).getTime() &&

        new Date(this.caseFilters['filed_date'][0] as string).getTime() <=
          new Date(this.isNullOrEmpty(x.filed_date) ? this.caseFilters['filed_date'][0] as string : x.filed_date).getTime() &&
          new Date(this.caseFilters['filed_date'][1] as string).getTime() >=
          new Date(this.isNullOrEmpty(x.filed_date) ? this.caseFilters['filed_date'][0] as string : x.filed_date).getTime() &&

        new Date(this.caseFilters['trial_date'][0] as string).getTime() <=
          new Date(this.isNullOrEmpty(x.trial_date) ? this.caseFilters['trial_date'][0] as string : x.trial_date).getTime() &&
          new Date(this.caseFilters['trial_date'][1] as string).getTime() >=
          new Date(this.isNullOrEmpty(x.trial_date) ? this.caseFilters['trial_date'][0] as string : x.trial_date).getTime()&&

        new Date(this.caseFilters['mediation_date'][0] as string).getTime() <=
          new Date(this.isNullOrEmpty(x.mediation_date) ? this.caseFilters['mediation_date'][0] as string : x.mediation_date).getTime() &&
        new Date(this.caseFilters['mediation_date'][1] as string).getTime() >=
          new Date(this.isNullOrEmpty(x.mediation_date) ? this.caseFilters['mediation_date'][0] as string : x.mediation_date).getTime()
    });
  }

  private applyDropdownFilter(c: Case = new Case(), caseFilters: Record<string, unknown[]>): boolean {
    let classMatch = false;
    let typeMatch = false;
    let instMatch = false;
    let attorneyMatch = false;
    try{
      const classifications: any[] = caseFilters['classification'] ?? [];
      const types: any[] = caseFilters['type'] ?? [];
      const institutions: any[] = caseFilters['institutions'] ?? [];
      const attorneys: any[] = caseFilters['attorneys'] ?? [];
      if (classifications.length === 0) {
        classMatch = true;
      } else {
        classMatch = classifications.some(classification => {
          return classMatch || (classification === '' ? true : c.classification.toString() === classification.toString());
        });
      }
      if (types.length === 0)  {
        typeMatch = true;
      } else {
        typeMatch = types.some(type => {

          return typeMatch || (type === '' ? true : c.type.toString() === type.toString());
        });
      }
      if (institutions.length === 0)  {
        instMatch = true;
      } else {
        instMatch = institutions.some(inst => {
          if (isNaN(parseInt(inst))) {
          return false;
          }
          return instMatch || (inst === '' ? true : c.institutions.includes(parseInt(inst)));
        });
      }
      if (attorneys.length === 0)  {
        attorneyMatch = true;
      } else {
        attorneyMatch = attorneys.some(attorney => {
          if (isNaN(parseInt(attorney))) {
            return false;
          }
          return attorneyMatch || (attorney === '' ? true : c.in_house_attorneys.includes(parseInt(attorney)));
        });
      }
      return classMatch && typeMatch && instMatch && attorneyMatch;
    } catch (e) {
          return false;
    }
  }

  filterStatus(status: string = ''){
    switch(status.toLowerCase()){
      case 'open':
        this.caseFilters['is_closed'] = ['0'];
        break;
      case 'closed':
        this.caseFilters['is_closed'] = ['1'];
        break;
      default:
        this.caseFilters['is_closed'] = ['0', '1'];
        break;
    }
    this.filter(this.searchedCases);
  }

  addDateFilter(value: DateRangeOutput){
    switch(value.Label){
      case 'Date Created':
        this.caseFilters['created_at'][0] = [value.StartDate];
        this.caseFilters['created_at'][1] = [value.EndDate];
        break;
      case 'Date Modified':
        this.caseFilters['max_date'][0] = [value.StartDate];
        this.caseFilters['max_date'][1] = [value.EndDate];
        break;
      case 'Date Filed/Submitted':
        this.caseFilters['filed_date'][0] = [value.StartDate];
        this.caseFilters['filed_date'][1] = [value.EndDate];

        break;

      case 'Trial Date':
        this.caseFilters['trial_date'][0] = [value.StartDate];
        this.caseFilters['trial_date'][1] = [value.EndDate];
        break;

      case 'Mediation Date':
        this.caseFilters['mediation_date'][0] = [value.StartDate];
        this.caseFilters['mediation_date'][1] = [value.EndDate];
        break;
      default:
        break;
    }
    this.filter(this.searchedCases);
  }

  isNullOrEmpty(value: unknown){
    return value === null || value === '';
  }

  public exportFoundset(): void {
    try {
      this.spinnerShow = true;
      this.caseService.getCases('').subscribe( cases => {
        this.csvExportService.saveData(cases, "cases-export.csv");
        this.spinnerShow = false;
      });
    } catch(e) { }
  }

  public resetFilters(): void {
    this.caseService.state = 'search-cases';
    this.listDataService.getListData();
    this.getCases();
    // this.caseFilters = this.getEmptyCaseFilters();
    // this.parentCases = this.caseService.cases;
    // this.searchedCases = this.caseService.cases;
    // this.filterStatus(); // sending nothing will call the default action which is all
    // this.statusGroup?.resetCaseStatus();
  }

  private getEmptyCaseFilters(): Record<string, unknown[]> {
    return {
      type_value: [],
      created_at: ["1000-01-01", "3000-01-01"],
      max_date: ["1000-01-01", "3000-01-01"],
      trial_date: ["1000-01-01", "3000-01-01"],
      mediation_date: ["1000-01-01", "3000-01-01"],
      filed_date: ["1000-01-01", "3000-01-01"],
      institution_name: [],
      type: [],
      institutions_full: [],
      institutions_values: [],
      institutions: [],
      attorneys_full: [],
      attorneys_values: [],
      attorneys: [],
      title: [],
      description: [],
      is_closed: ['0','1'],
      classification_value: [],
      classification: []
    }
  }

}
