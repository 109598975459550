import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, ViewChild, Input, OnChanges } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';

import { DropdownSelectService } from "../../../services/dropdown-select.service";
import { CaseService } from "../../../services/case.service";
import { Case } from 'src/app/shared/models/case';
import {Router} from "@angular/router";
import {ThemePalette} from "@angular/material/core";

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnChanges {
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  @Input() cases!: Case[];
  @Input() spinnerShow = true;
  @Input() displayedColumns!: string[];
  @Input() renderTable!: boolean;
  public color: ThemePalette = 'primary';
  public mode = 'indeterminate' as ProgressSpinnerMode;
  public dataSource!: MatTableDataSource<Case>;
  public defaultPageSize = 20;
  public defaultPageSizeOptions: number[] = [ 5, 10, 20, 50, 75, 100]
  constructor(public dropDownSelectService: DropdownSelectService, public caseService: CaseService,
              public router: Router, private liveAnnouncer: LiveAnnouncer) {
  }

  ngOnChanges() {
    try {
      if (typeof (this.cases) !== 'undefined') {
        this.dataSource = new MatTableDataSource(this.cases);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
      const sortState: Sort = {active: 'max_date', direction: 'desc'};
      if (typeof(this.sort) !== 'undefined') {
        this.sort.active = sortState.active;
        this.sort.direction = sortState.direction;
        this.sort.sortChange.emit(sortState);
      }
    } catch (e) {
      console.log('TableComponent::ngOnChanges: ', e);
    }
  }

  /** Announce the change in sort state for assistive technology. **/
  public announceSortChange(sortState: Sort): void{
    if (sortState.direction) {
      this.liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this.liveAnnouncer.announce('Sorting cleared');
    }
  }
}
