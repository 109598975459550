export class AdministrativeClaims {
    public pk_administrative_claim_id: number = -1; /* int primary key identity */
    public fk_case_id: number = -1; /* int not null */
    public agency: string = ''; /* nvarchar(255) */
    public claim_number: string = ''; /* nvarchar(255) */
    public claim_submitted_date: string = ''; /* date */
    public created_by: number = -1; /* int */
    public created_at: string = ''; /* datetime2(0) default CURRENT_TIMESTAMP */
    public updated_by: number = -1; /* int */
    public updated_at: string = ''; /* datetime2(0) */
    public settlement_amount: string = ''; /* money */
    public settlement_funding_src: string = ''; /* nvarchar(max) */
}
