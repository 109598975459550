import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ValidUserGuard } from './shared/guards/valid-user/valid-user.guard';
import { SearchCasesComponent } from './search-cases/search-cases.component';
import { CaseDetailsComponent } from './case-details/case-details.component';
import { DiscoveryComponent } from './discovery/discovery.component';
import { AddNewCaseComponent } from './add-new-case/add-new-case.component';
import { ManageUsersComponent } from './manage-users/manage-users.component';
import { AddNewUserComponent } from './add-new-user/add-new-user.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import {ManageUsersGuard} from "./shared/guards/manage-users/manage-users.guard";
import {AddCaseGuard} from "./shared/guards/add-case/add-case.guard";

const routes: Routes = [
  {
    path: 'discovery',
    component: DiscoveryComponent ,
    title: 'User Login'
  },
  {
    path: 'search-cases',
    component: SearchCasesComponent ,
    title: 'My Cases',
    canActivate: [ValidUserGuard],
    // canLoad: [ValidUserGuard]
  },
  {
    path: 'case-details/:id',
    component: CaseDetailsComponent,
    title: 'View Case Details',
    canLoad: [ValidUserGuard],
    canActivate: [ValidUserGuard]
  },
  {
    path: 'add-new-case',
    component: AddNewCaseComponent,
    title: 'Add New Case',
    canLoad: [ValidUserGuard],
    canActivate: [AddCaseGuard]
  },
  {
    path: 'manage-users',
    component: ManageUsersComponent,
    title: 'Manage Users',
    canLoad: [ValidUserGuard],
    canActivate: [ManageUsersGuard]
  },
  {
    path: 'add-new-current-user',
    component: AddNewUserComponent,
    title: 'Add New User',
    canLoad: [ValidUserGuard],
    canActivate: [ManageUsersGuard]
  },
  {
    path: 'edit-current-user/:id',
    component: EditUserComponent,
    title: 'Edit User',
    canLoad: [ValidUserGuard],
    canActivate: [ManageUsersGuard]
  },
  { path: '', redirectTo: 'search-cases', pathMatch: 'full' },
  { path: '**', redirectTo: 'search-cases' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
