import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, ViewChild, Input, OnChanges, ChangeDetectorRef, OnInit, Output, EventEmitter } from '@angular/core';
import { TableComponent } from '../shared/components/widgets/table/table.component';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { DropdownSelectComponent } from '../shared/components/widgets/dropdown-select/dropdown-select.component';
import { User } from '../shared/models/user';
import { UserManagementService } from '../shared/services/user-management/user-management.service';
import { ListDataService } from '../shared/services/list-data/list-data.service';
import { DropdownSelectService } from '../shared/services/dropdown-select.service';

@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.scss']
})
export class ManageUsersComponent implements OnInit, OnChanges {
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  public users!: User[];
  public searchedUsers!: User[];
  public displayedColumns: string[] = ['is_inactive', 'username', 'lastname', 'institution', 'roles', 'email_primary', 'edit'];
  public dataSource!: MatTableDataSource<User>;

  public spinnerShow = false;
  public spinnerColor = 'primary';
  public spinnerMode = 'indeterminate' as ProgressSpinnerMode;

  public dropdownSelects: any;
  public initSort = true;
  @Output() dropdownOutput = new EventEmitter<any>();


  constructor(public userManagementService: UserManagementService, private changeDetector: ChangeDetectorRef,
    public listDataService: ListDataService, public dropdownSelectService: DropdownSelectService, private liveAnnouncer: LiveAnnouncer){
  }

  ngOnInit(){
    this.getUsers();
    // this.sort.active = 'username';
    // this.sort.direction = 'asc';
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    this.listDataService.institutions$.subscribe(data => {
      this.dropdownSelects = [
        this.dropdownSelectService.institution,
      ];
    })

    this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
      if (typeof(data[sortHeaderId]) === 'string') {
        return data[sortHeaderId].toLocaleLowerCase();
      }
      return data[sortHeaderId];
    };

  }

  ngOnChanges() {
    this.getUsers();
    this.dataSource = new MatTableDataSource(this.users);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    const sortState: Sort = {active: 'username', direction: 'asc'};
    this.sort.active = sortState.active;
    this.sort.direction = sortState.direction;
    this.sort.sortChange.emit(sortState);
  }

  /** Announce the change in sort state for assistive technology. **/
  public announceSortChange(sortState: Sort): void{
    if (sortState.direction) {
      this.liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this.liveAnnouncer.announce('Sorting cleared');
    }
  }

  public getUsers(args: string = ''): void{
    try{
      // this.searchTerms = args;
      this.spinnerShow = true;
      this.userManagementService.getUsers(args).subscribe( users => {
        this.users = users;
        this.searchedUsers = users;
        this.userManagementService.users = users;
        if(this.initSort){
          this.dataSource = new MatTableDataSource(this.users.sort((n1,n2) => {
          if (n1.username > n2.username) {
              return 1;
          }

          if (n1.username < n2.username) {
              return -1;
          }

          return 0;}).sort((a: User, b: User) => a.is_inactive - b.is_inactive));
        } else{
          this.dataSource = new MatTableDataSource(this.users);
          this.sort.active = 'username';
          this.sort.direction = 'asc';
        }
        this.dataSource.sort = this.sort;

        this.initSort = false;
        this.dataSource.paginator = this.paginator;
        this.spinnerShow = false;
      });
    } catch(e) {
    }
  }
}
