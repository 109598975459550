import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SearchCasesComponent } from './search-cases/search-cases.component';
import { MaterialModule } from './shared/modules/material/material.module';
import { FooterComponent } from './shared/components/footer/footer.component';
import { LtsBrandingBarComponent } from './shared/components/lts-branding-bar/lts-branding-bar.component';
import { TableComponent } from './shared/components/widgets/table/table.component';
import { SideNavComponent } from './shared/components/side-nav/side-nav.component';
import { CheckboxComponent } from './shared/components/widgets/checkbox/checkbox.component';
import { ButtonToggleGrpComponent } from './shared/components/widgets/button-toggle-grp/button-toggle-grp.component';
import { SearchInputComponent } from './shared/components/widgets/search-input/search-input.component';
import { DropdownSelectComponent } from './shared/components/widgets/dropdown-select/dropdown-select.component';
import { DatePickerComponent } from './shared/components/widgets/date-picker/date-picker.component';
import { ExpansionPanelComponent } from './shared/components/widgets/expansion-panel/expansion-panel.component';
import { DateRangePickerComponent } from './shared/components/widgets/date-range-picker/date-range-picker.component';
import { HorizontalCardComponent } from './shared/components/widgets/horizontal-card/horizontal-card.component';
import {MAT_DATE_LOCALE, MatNativeDateModule, MatOptionModule} from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CaseDetailsComponent } from './case-details/case-details.component';
import { AddNoteComponent } from './shared/components/add-note/add-note.component';
// import { DialogComponent } from './shared/components/widgets/dialog/dialog.component';
import { ManageUsersComponent } from './manage-users/manage-users.component';
import { AddNewCaseComponent } from './add-new-case/add-new-case.component';
import { AddNewUserComponent } from './add-new-user/add-new-user.component';
import { DiscoveryComponent} from "./discovery/discovery.component";
import { UserInterceptorInterceptor } from './shared/services/user-interceptor/user-interceptor.interceptor';
import { PreviewAdminClaimComponent } from './shared/components/preview-admin-claim/preview-admin-claim.component';
import { PreviewTortClaimComponent } from './shared/components/preview-tort-claim/preview-tort-claim.component';
import {PhoneDirective} from "./shared/directives/phone.directive";
import { ViewNoteComponent } from './shared/components/view-note/view-note.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { DeleteCaseRequestComponent } from './shared/components/delete-case-request/delete-case-request.component';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    LtsBrandingBarComponent,
    SearchCasesComponent,
    TableComponent,
    SideNavComponent,
    CheckboxComponent,
    ButtonToggleGrpComponent,
    SearchInputComponent,
    DropdownSelectComponent,
    DatePickerComponent,
    ExpansionPanelComponent,
    DateRangePickerComponent,
    HorizontalCardComponent,
    CaseDetailsComponent,
    AddNoteComponent,
    ManageUsersComponent,
    AddNewCaseComponent,
    AddNewUserComponent,
    PreviewAdminClaimComponent,
    PreviewTortClaimComponent,
    PhoneDirective,
    ViewNoteComponent,
    EditUserComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    MaterialModule,
    HttpClientModule,
    MatOptionModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatCardModule,
    OverlayModule,
    DiscoveryComponent,
    DeleteCaseRequestComponent
  ],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
    { provide: HTTP_INTERCEPTORS, useClass: UserInterceptorInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
