export class Grievances {
    public pk_grievance_id: number = -1; /* int primary key identity */
    public fk_case_id: number = -1; /* int not null */
    public grievant_number: string = ''; /* nvarchar(50) */
    public grievant_name: string = ''; /* nvarchar(max) */
    public association: string = ''; /* nvarchar(max) */
    public bargaining_unit: string = ''; /* nvarchar(max) */
    public violation_article_section: string = ''; /* nvarchar(max) */
    public third_step_description: string = ''; /* nvarchar(max) */
    public is_arbitration_appeal: number = 0; /* bit default 0 not null */
    public arbitration_assignment: string = ''; /* nvarchar(max) */
    public arbitration_assessment: string = ''; /* nvarchar(max) */
    public is_bor_appeal: number = 0; /* bit default 0 not null */
    public bor_decision: string = ''; /* nvarchar(max) */
    public is_court_appeal_filed: number = 0; /* bit default 0 not null */
    public created_by: number = -1; /* int */
    public created_at: string = ''; /* datetime2(0) default CURRENT_TIMESTAMP */
    public updated_by: number = -1; /* int */
    public updated_at: string = ''; /* datetime2(0) */
    public settlement_amount: number = 0 /* money */
    public settlement_funding_src: string = ''; /* nvarchar(max) */
}
