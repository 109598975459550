<header class="branding-bar">
    <span></span>
    <section class="flex space-between">
        <div class="branding-bar-container">
            <div class="logo logo-tab">
                <a routerLink="/search-cases" class="site-name">
                    <img src="../../../../assets/images/BOR-Logo.png" alt="Board of Regents - State of Iowa" class="branding-bar-logo">
                </a>
            </div>
            <h1 (click)="intro()" (keyup)="keyboardIntro($event)">
                {{ siteName }}
            </h1>
        </div>
        <div class="branding-bar--right flex-row" *ngIf="isLoggedIn()">
            <div class="user margin-right center flex-row flex-center">
                <mat-icon class="margin-xsm-right">person</mat-icon><p>{{currentUserService.currentUser.username}}</p>
            </div>
            <div class="logout" tabindex="0">
                <a (click)="logOut()" (keyup.enter)="logOut()" (keyup.space)="logOut()" mat-flat-button color="accent">Log Out</a>
            </div>
        </div>
    </section>
</header>
