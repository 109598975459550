<div class="manage-users-container">
    <ng-template cdkConnectedOverlay [cdkConnectedOverlayOpen]="spinnerShow">
      <mat-progress-spinner *ngIf="spinnerShow"
                            class="spinner-overlay"
                            [color]="spinnerColor"
                            [mode]="spinnerMode">
      </mat-progress-spinner>
    </ng-template>

    <p class="flex-row flex-end margin-sm-bottom flex-space-between">
      <button mat-stroked-button routerLink="/search-cases" color="primary">Back to My Cases</button>
      <button routerLink="/add-new-current-user" mat-stroked-button color="primary"><mat-icon>add</mat-icon>Add a New User</button>
    </p>
    <section class="margin-bottom">
        <h1 class="bold-headline--serif">Select a user below to view details</h1>
    </section>

    <!-- <mat-expansion-panel class="margin-bottom">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Filter Settings
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="flex-md-row margin-top">
        <app-dropdown-select
        *ngFor="let dropdownSelect of dropdownSelects"
        [dropdownSelect]="dropdownSelect"
        class="width-md-25"
        ></app-dropdown-select>
      </div>
      <div class="flex-md-row margin-top">
        <app-dropdown-select
        *ngFor="let dropdownSelect of dropdownSelects"
        [dropdownSelect]="dropdownSelect"
        (dropdownOutput)="emitDropdown($event)"
        class="width-md-25"
        ></app-dropdown-select>
      </div>

      <div class="flex-md-row margin-sm-top">
        <app-dropdown-select
        *ngFor="let dropdownSelect of dropdownSelects"
        [dropdownSelect]="dropdownSelect"
        (dropdownOutput)="emitDropdown($event)"
        class="width-md-25"
        ></app-dropdown-select>
      </div>
  </mat-expansion-panel> -->


    <mat-table [dataSource]="dataSource" matSort>
      <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOpen]="spinnerShow"
        >
          <mat-progress-spinner *ngIf="spinnerShow"
              class="spinner-overlay"
              [color]="spinnerColor"
              [mode]="spinnerMode">
          </mat-progress-spinner>
      </ng-template>

      <!-- Inactive Status Column -->
      <ng-container matColumnDef="is_inactive">
        <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by is_inactive"> Status </mat-header-cell>
        <mat-cell tabindex="0" *matCellDef="let element"> {{ element.is_inactive ? 'Inactive' : 'Active'}} </mat-cell>
      </ng-container>

      <!-- Username Column -->
      <ng-container matColumnDef="username">
        <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by username"> Username </mat-header-cell>
        <!-- <mat-cell *matCellDef="let element" [routerLink]="'/users/'+ element.pk_case_id" class="table-link"> {{ element.username }} </mat-cell> -->
        <mat-cell tabindex="0" *matCellDef="let element" class="table-cell-ellipsis"> {{ element.username }} </mat-cell>
      </ng-container>

      <!-- First Name Column -->
      <ng-container matColumnDef="lastname">
        <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by lastname"> Name </mat-header-cell>
        <mat-cell tabindex="0" *matCellDef="let element"> {{ element.lastname }}, {{ element.firstname }}</mat-cell>
      </ng-container>

      <!-- Last Name Column -->
      <!-- <ng-container matColumnDef="lastname">
        <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by lastname"> Last Name </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.lastname }} </mat-cell>
      </ng-container>       -->

      <!-- Institution Column -->
      <ng-container matColumnDef="institution">
        <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by institution"> Institution </mat-header-cell>
        <mat-cell tabindex="0" *matCellDef="let element">
          {{ element.institution_value }}
        </mat-cell>
      </ng-container>

      <!-- Role Column -->
      <ng-container matColumnDef="roles">
        <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by role"> Role </mat-header-cell>
        <mat-cell tabindex="0" *matCellDef="let element">
          <ng-container *ngFor="let role of element.roles">
            <div class="multiple-line-cell padding-none">
              {{ role }}
            </div>
          </ng-container>
        </mat-cell>
      </ng-container>

      <!-- Email & Alt Email Column -->
      <ng-container matColumnDef="email_primary">
        <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by email_primary"> Email </mat-header-cell>
        <mat-cell tabindex="0" *matCellDef="let element" class="table-cell-ellipsis"> {{ element.email_primary }}</mat-cell>
      </ng-container>

      <!-- Edit Column -->
      <ng-container matColumnDef="edit">
        <mat-header-cell *matHeaderCellDef> </mat-header-cell>
        <mat-cell *matCellDef="let element" class="flex-row flex-end table-cell-button" [routerLink]="'/edit-current-user/' + element.pk_user_id" (click)="userManagementService.setUser(element.pk_user_id)">
          <button tabindex="0" mat-stroked-button color="primary">
              Edit
            </button>
          </mat-cell>
      </ng-container>

      <!-- Is Inactive Column -->
      <!-- <ng-container matColumnDef="is_inactive">
        <mat-header-cell *matHeaderCellDef> </mat-header-cell>
        <mat-cell *matCellDef="let element" class="flex-row flex-end table-cell-button">
          Active
        </mat-cell>
      </ng-container> -->

      <mat-header-row *matHeaderRowDef="displayedColumns" class="matHeaderRow"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

      <div class="mat-row no-table-data" role="row" *matNoDataRow>
        <div role="cell" class="mat-cell">No Records Found</div>
      </div>
    </mat-table>

    <mat-paginator
        [pageSize]="20"
        [pageSizeOptions]="[5, 10, 20]"
        showFirstLastButtons
        aria-label="Select page of users">
    </mat-paginator>
</div>
