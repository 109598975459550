import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Router } from '@angular/router';
import {map, Observable} from 'rxjs';

import { CurrentUserService } from '../../services/current-user/current-user.service';

@Injectable({
  providedIn: 'root'
})
export class AddCaseGuard implements CanActivate {
  constructor (private currentUserService: CurrentUserService, private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    this.currentUserService.setCurrentUser();
    return this.currentUserService.setCurrentUser().pipe(
      map(result => {
        console.log(this.currentUserService.canEditCase$.value);
        console.log(this.currentUserService.canAddUpdateViewOwnCase$.value);
        console.log(this.currentUserService.canAddEditAllUI$.value);
        if (!this.currentUserService.canEditCase$.value && !this.currentUserService.canAddUpdateViewOwnCase$.value && !this.currentUserService.canAddEditAllUI$.value) {
          this.router.navigate(['/search-cases']);
        }
        console.log('can activate');
        return this.currentUserService.canEditCase$.value || this.currentUserService.canAddUpdateViewOwnCase$.value || this.currentUserService.canAddEditAllUI$.value;
      })
    );
  }
}
