<div id="add-note-attachment-container">
  <header class="heading-bar flex">
    <h2 mat-dialog-title>Add a Note &amp; Attachment</h2>
  </header>
  <mat-dialog-content [formGroup]="noteForm">
      <mat-form-field class="form-field-flex width-100 margin-sm-top margin-sm-bottom">
        <mat-label>Enter a note/comment about this case</mat-label>
        <textarea
          matInput
          #noteInput
          formControlName="note"
          matInput
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="10"
          maxlength="1000"
        ></textarea>
        <mat-hint>Max characters: 1000</mat-hint>
      </mat-form-field>

    <h3 class="margin-sm-top">Upload Attachment</h3>
    <p class="margin-sm-bottom" *ngIf="attachment.filename"><b>{{attachment.filename}}</b></p>

    <div *ngIf="!attachment.filename" class="flex-row align-center margin-sm-top margin-sm-bottom">
      <button
        class="margin-sm-right"
        mat-flat-button
        (click)="uploadFiles()"
        color="primary"
      >
        Chose File
      </button>
      <p><i>&nbsp;(No file chosen)</i></p>
    </div>

    <p *ngIf="!fileSizeValid" class="red-text"><b>Maximum file size: 75MB</b></p>
    <p *ngIf="fileSizeValid">Maximum file size: 75MB</p>

    <p *ngIf="invalidFileType" class="red-text"><b>Accepted file types: .doc, .docx, .pdf</b></p>
    <p *ngIf="!invalidFileType">Accepted file types: .doc, .docx, .pdf</p>


  </mat-dialog-content>
  <mat-dialog-actions
    class="flex-row space-between margin-top"
  >
  <span>
  <button
    mat-flat-button
    mat-dialog-close
    (click)="add()"
    color="primary"
    [disabled]="!this.noteForm.get('note')?.value && !attachment.filename"
  >
    Save Note
  </button>

    <button
        mat-flat-button
        (click)="add()"
        color="primary"
        [disabled]="!this.noteForm.get('note')?.value && !attachment.filename"
      >
    Save Note &amp; Add Another Note
  </button>
  </span>

    <button
      mat-stroked-button
      mat-dialog-close
      color="warn"
    >
      Cancel
    </button>
  </mat-dialog-actions>

  <input
    id="file-input-element"
    name="file-input-element"
    type="file"
    accept=".xls,.xlsx, .doc, .docx, .jpg, .jpeg, .png, .gif, .pdf, .csv"
    #file
    (change)="handleFileInputDialog(file.files)"
    style="display: none"
  />
</div>
