<section>
<h3 class="offscreen">{{ getLabel(dropdownSelect.title, selection?.options)}}</h3>
<mat-form-field class="form-field-flex">
  <mat-label>{{ getLabel(dropdownSelect.title, selection?.options)}}</mat-label>
  <mat-select
    multiple
    #selection
    [disabled]="isDisabled(dropdownSelect.title, selection?.options)"
    (selectionChange)="handleSelectionChange($event, dropdownSelect.title)"
  >
    <ng-container *ngIf="checkContent(dropdownSelect.title) === 'Case Classification'">
      <mat-option *ngFor="let data of listDataService.classifications$.value" [value]="data.value"
        (onSelectionChange)="listDataService.getDropDownName('Classification');
          emit(data.value);">
          {{ data.name }}
      </mat-option>
    </ng-container>
    <ng-container *ngIf="checkContent(dropdownSelect.title) === 'Case Type'">

      <mat-option *ngFor="let data of listDataService.typeValues$ | async" [value]="data.value"
                  (onSelectionChange)="listDataService.getDropDownName('type'); emit(data.value)">
        {{ data.name }}
      </mat-option>
    </ng-container>
    <ng-container *ngIf="checkContent(dropdownSelect.title) === 'Institution'">
      <mat-option *ngFor="let data of listDataService.institutions$.value" [value]="data.value"
                  (onSelectionChange)="listDataService.getDropDownName('institutions'); emit(data.value)">
        {{ data.name }}
      </mat-option>
    </ng-container>
    <ng-container *ngIf="checkContent(dropdownSelect.title) === 'In House Attorney'">
      <mat-option *ngFor="let data of listDataService.attorneys$.value" [value]="data.value"
                  (onSelectionChange)="listDataService.getDropDownName('attorneys'); emit(data.value)">
        {{ data.name }}
      </mat-option>
    </ng-container>
  </mat-select>
</mat-form-field>
</section>
