import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { HorizontalCardContent} from './horizontal-card-content.interface';
//import {NavService} from '../../../../services/nav.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-horizontal-card',
  templateUrl: './horizontal-card.component.html',
  styleUrls: ['./horizontal-card.component.scss'],
})
export class HorizontalCardComponent {
  @Input() card: HorizontalCardContent = {
    title: '',
    subtitle: '',
    image: '',
    content: [''],
    buttons: [{ label: '', emitValue: [''] }],
  };

  @Output() childOutput = new EventEmitter<any>();

  // constructor(public nav: NavService, public router: Router) {
  //   if (typeof this.card === 'undefined') {
  //     // @ts-ignore
  //     this.card.title = '';
  //   }
  // }

    constructor(public router: Router) {
    if (typeof this.card === 'undefined') {
      // @ts-ignore
      this.card.title = '';
    }
  }

  public checkContent(
    content: string | undefined | Array<string> = ['']
  ): string {
    if (content instanceof Array) {
      return content.join(', ');
    }
    return typeof content === 'undefined' || content === null
      ? ''
      : content.trim();
  }
}
