import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import {BehaviorSubject, map, Observable, of} from 'rxjs';

import { CurrentUser } from '../../models/current-user';
import { Router } from '@angular/router';
import {tap} from "rxjs/operators";
import {CaseService} from "../case.service";

@Injectable({
  providedIn: 'root'
})
export class CurrentUserService {
  public currentUser$: BehaviorSubject<CurrentUser> = new BehaviorSubject(new CurrentUser());
  public isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public currentUser: CurrentUser = new CurrentUser;
  public isLoggedIn: boolean = false;

  public rolesAddEditUsers: string[] = [
    'Application Administrator'
  ];
  public rolesEditAllCases: string[] = [
    'Application Administrator',
    'Attorney General',
    'Board Office Counsel'
  ];
  public rolesEditOwnCases: string[] = [
    'University Administrator',
    'University General Counsel',
    'University Attorney'
  ];
  public rolesAddAssignAllCases: string[] = [
    'Application Administrator',
    'Board Office Counsel'
  ];
  public rolesAddUpdateViewOwnCases: string[] = [
    'University Administrator',
    'University General Counsel',
    'University Attorney'
  ];
  public rolesDeleteAllCases: string[] = [
    'Board Office Counsel'
  ];
  public rolesAssignOwnCases: string[] = [
    'University Administrator',
    'University General Counsel'
  ];
  public rolesViewOwnCases: string[] = [
    'University Guest'
  ]

  /* variables to use in the html to determine the feature */
  public canAddEditUsers$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public canEditCase$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public canAddEditAllUI$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public canAddAssignAllCase$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public canAddUpdateViewOwnCase$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public canDeleteCase$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public canAssignOwnCase$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public canViewOwnCase$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  /* end variables to use in the html to determine the feature */


  constructor(private http: HttpClient, private router: Router, private caseService: CaseService) {
    this.setCurrentUser();
    this.currentUser$.subscribe(user => {
      this.currentUser = user;
    });
    this.isLoggedIn$.subscribe( ili => {
      this.isLoggedIn = ili;
    });

  }

   public setCurrentUser(): Observable<CurrentUser> {
    const url = environment.apiUrl + environment.currentUserEndpoint;
    try {
      return this.http.get<CurrentUser>(url).pipe(
        tap( user => {
          console.log(user);
          if (user.hasOwnProperty('pk_user_id') === true) {
            this.currentUser$.next(user);
            this.isLoggedIn$.next(true);
            this.currentUser = user;
            this.isLoggedIn = true;
            this.setCanAddEditUsers();
            this.setCanEditCase();
            this.setCanAddEditAllUI();
            this.setAddAssignAllCase();
            this.setAddUpdateViewOwnCase();
            this.setDeleteCase();
            this.setAssignOwnCase();
            this.setViewOwnCase();
            console.log(this.canAddEditAllUI$.value);
          } else {
            this.resetUser();
          }
        })
      );
    } catch(e) {
      this.resetUser();
      return of(new CurrentUser());
    }
   }

   public resetUser(): void {
    this.currentUser$.next(new CurrentUser());
    this.currentUser = new CurrentUser();
    this.isLoggedIn$.next(false);
    this.isLoggedIn = false;
    this.canAddEditUsers$.next(false);
    this.canEditCase$.next(false);
    this.canAddEditAllUI$.next(false);
    this.canAddAssignAllCase$.next(false);
    this.canAddUpdateViewOwnCase$.next(false);
    this.canDeleteCase$.next(false);
    this.canAssignOwnCase$.next(false);
    this.canViewOwnCase$.next(false);
   }

   public logOut(clearHistory: boolean = false): void {
     const url = `${environment.protocol}${environment.server}${environment.shibboletEndpoint}${environment.logoutEndpoint}`;
     console.log(url);
     window.location.href = url;
     if (clearHistory === true) {
       // todo: remove current page from history to stop back button
     }
   }

   public canAssignInstitution(value: number): boolean  {
     return this.canAssignOwnCase$.value || this.canAddAssignAllCase$.value ||
       (this.canAddEditAllUI$.value && (value === 1 || value === 8 || value === 7))
   }

   public canDeleteCase(caseInstitution: number = -1): boolean {
     const sameInstitution = this.currentUser.institution === caseInstitution;
     return true;
   }
  public setCanAddEditUsers(): void{
    this.canAddEditUsers$.next(this.currentUser.roles.some( role => {
      return this.rolesAddEditUsers.includes(role.role);
    }));
  }

  public setCanEditCase(): void {
    this.canEditCase$.next(this.currentUser.roles.some( role => {
        return this.rolesEditAllCases.includes(role.role) ||
          ( this.rolesEditOwnCases.includes(role.role) &&
            this.caseService.details.institutions.includes(this.currentUser.institution)
          );
    }));
  }

  public setCanAddEditAllUI(): void {
    this.canAddEditAllUI$.next(this.currentUser.roles.some( role => {
      return this.currentUser.institution === 1 || this.currentUser.institution === 8 || this.currentUser.institution === 7
    }));
  }

  private setAddAssignAllCase(): void {
    this.canAddAssignAllCase$.next(this.currentUser.roles.some( role => {
      return this.rolesAddAssignAllCases.includes(role.role);
    }));
  }
  public setAddUpdateViewOwnCase(): void {
    this.canAddUpdateViewOwnCase$.next( this.ownCasesCheck(this.rolesAddUpdateViewOwnCases, this.currentUser.roles));
  }
  private setDeleteCase(): void {
    this.canDeleteCase$.next(this.currentUser.roles.some( role => {
      return this.rolesDeleteAllCases.includes(role.role);
    }));
  }
  private setAssignOwnCase(): void {
    this.canAssignOwnCase$.next(this.ownCasesCheck(this.rolesAssignOwnCases, this.currentUser.roles));
  }
  private setViewOwnCase(): void {
    this.canViewOwnCase$.next(this.ownCasesCheck(this.rolesViewOwnCases, this.currentUser.roles));
  }

  public ownCasesCheck(sourceRoles: any[] = [], userRoles: any[] = []): boolean {
    return userRoles.some( role => {
      return sourceRoles.includes(role.role) && (
        this.caseService.details.institutions.length > 0 ?
          this.caseService.details.institutions.includes(this.currentUser.institution) :
          true
      );
    });
  }

}
