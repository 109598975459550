<mat-table [dataSource]="dataSource" matSort>
    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOpen]="spinnerShow"
      >
        <mat-progress-spinner *ngIf="spinnerShow"
            class="spinner-overlay"
            [color]="color"
            [mode]="mode">
        </mat-progress-spinner>
    </ng-template>

    <!-- Case Title Column -->
    <ng-container matColumnDef="title">
      <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by caseTitle"> Case Title </mat-header-cell>
      <mat-cell tabindex="0" *matCellDef="let element" [routerLink]="'/case-details/'+ element.pk_case_id" class="table-link"
                (click)="caseService.setCase(element.pk_case_id)"
                (keyup.enter)="caseService.setCase(element.pk_case_id); router.navigate(['/case-details/'+ element.pk_case_id])">
        {{ element.title }}
      </mat-cell>
    </ng-container>

    <!-- Case Description Column -->
    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by caseDescription"> Case Description </mat-header-cell>
      <mat-cell tabindex="0" *matCellDef="let element" class="table-cell-ellipsis"> {{ element.description }} </mat-cell>
    </ng-container>

  <!-- Case Type Column -->
    <ng-container matColumnDef="type_value">
      <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by caseType"> Case Type </mat-header-cell>
      <mat-cell tabindex="0" *matCellDef="let element"> {{ element.type_value }} <br />
        <span class="classification-value"
              [ngClass]="{
                'classification-value__litigation' : element.classification_value === 'Litigation',
                'classification-value__internal' : element.classification_value === 'Internal Complaints',
                'classification-value__external' : element.classification_value === 'External Complaints'}">
            {{ element.classification_value | uppercase }}</span>
      </mat-cell>
    </ng-container>

    <!-- Date Created Column -->
<!--    <ng-container matColumnDef="created_at">-->
<!--      <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by dateCreated"> Created </mat-header-cell>-->
<!--      <mat-cell tabindex="0" *matCellDef="let element"> {{ caseService.formatDate(element.created_at) }} </mat-cell>-->
<!--    </ng-container>-->

  <!-- Date Modified Column -->
    <ng-container matColumnDef="max_date">
      <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by date Modified"> Modified </mat-header-cell>
      <mat-cell tabindex="0" *matCellDef="let element"> {{ caseService.formatDate(element.max_date) }} </mat-cell>
    </ng-container>


  <!-- Institution Column -->
    <ng-container matColumnDef="institutions_values">
      <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by institution"> Institution(s) </mat-header-cell>
      <mat-cell tabindex="0" *matCellDef="let element">
        <ng-container *ngFor="let institution of element.institutions_values">
          <div class="multiple-line-cell padding-none">
            {{ institution }}
          </div>
          </ng-container>
      </mat-cell>
    </ng-container>

    <!-- In House Attorney Column -->
    <ng-container matColumnDef="in_house_attorneys">
      <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by attorney"> In House Attorney(s) </mat-header-cell>
      <mat-cell tabindex="0" *matCellDef="let element">
        <ng-container *ngFor="let attorney of element.in_house_attorneys_values">
          <div class="multiple-line-cell padding-none">
            {{ attorney }}
          </div>
        </ng-container>
      </mat-cell>
    </ng-container>

    <!-- Trial Date Column -->
    <ng-container matColumnDef="trial_date" >
      <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by trialDate"> Trial Date </mat-header-cell>
      <mat-cell tabindex="0" *matCellDef="let element"> {{ caseService.formatDate(element.trial_date) }} </mat-cell>
    </ng-container>

    <!-- Mediation Date Column -->
    <ng-container matColumnDef="mediation_date">
      <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by mediationDate"> Mediation Date </mat-header-cell>
      <mat-cell tabindex="0" *matCellDef="let element"> {{ caseService.formatDate(element.mediation_date) }} </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns" class="matHeaderRow"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>

  <mat-paginator
    [pageSize]="defaultPageSize"
    [pageSizeOptions]="defaultPageSizeOptions"
    showFirstLastButtons
    aria-label="Select page of cases">
  </mat-paginator>
