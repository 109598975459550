import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Router } from '@angular/router';
import {map, Observable} from 'rxjs';

import { CurrentUserService } from '../../services/current-user/current-user.service';

@Injectable({
  providedIn: 'root'
})
export class ManageUsersGuard implements CanActivate {
  constructor (private currentUserService: CurrentUserService, private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.currentUserService.setCurrentUser();
    return this.currentUserService.setCurrentUser().pipe(
      map(result => {
        if (!this.currentUserService.canAddEditUsers$.value) {
          this.router.navigate(['/search-cases']);
        }
        console.log('can activate');
        return this.currentUserService.canAddEditUsers$.value;
      })
    );
  }
}
