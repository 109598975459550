import {Component, Directive, ElementRef, Input, ViewChild, AfterContentChecked} from '@angular/core';
import { DropdownDetails, ListDataService } from "../shared/services/list-data/list-data.service";
import { DropdownSelectService } from "../shared/services/dropdown-select.service";
import { UserManagementService } from '../shared/services/user-management/user-management.service';
import { User } from '../shared/models/user';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NonNullableFormBuilder, ValidationErrors,
  Validator, ValidatorFn,
  Validators
} from '@angular/forms';
import {MatCheckboxChange} from "@angular/material/checkbox";
import {debounceTime, distinctUntilChanged, Subject} from "rxjs";
import {tap} from "rxjs/operators";


@Component({
  selector: 'app-edit-current-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements AfterContentChecked{
  @ViewChild('userNameInput', { static: false}) private userNameInput?: ElementRef;

  public institutions: any;
  public user: User = new User();
  public id = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
  public userForm = this.blankUserForm();
  public username: string = '';

  public isUniqueName: boolean = false;
  public isAllowedDomain: boolean = false;

  private allowedDomains = [
    'iastate.edu',
    'uiowa.edu',
    'uni.edu',
    'iowaregents.edu'
  ];

  private allUsers: User[] = [];


  constructor(public listDataService: ListDataService, public dropdownSelectService: DropdownSelectService, public userManagementService: UserManagementService,
     public _formBuilder: NonNullableFormBuilder) {

    this.listDataService.getListData();
    this.getUser();
  }

  ngAfterContentChecked() {
    this.userForm.updateValueAndValidity( { onlySelf: false, emitEvent: true});
  }

  public get userNameError(): string {
    let msg = ''
    try {
      if (this.isUniqueName !== true) {
        msg = "This username already exists";
      } else if (this.isAllowedDomain !== true && (this.userForm?.get('username')?.value ?? '').trim() !== '') {
        msg = "The username must have a valid account";
      } else if ((this.userForm?.get('username')?.value ?? '').trim() === '') {
        msg = "Required"
      } else {
        msg = '';
      }
      return msg;
    } catch (e) {
      return '';
    }
  }

  public getUser(){
    this.userManagementService.getUsers().subscribe((users: any) => {
      this.allUsers = users;
      this.user = users.find( (user: any) => this.id == user.pk_user_id.toString()) ?? new User();
      this.userForm.get('firstname')?.setValue((this.user?.firstname ?? ''), {
         onlySelf: false,
      });
      this.userForm.get('lastname')?.setValue(this.user?.lastname ?? '', {
        onlySelf: false
      });
      this.userForm.get('username')?.setValue(this.user?.username ?? '', {
        onlySelf: false,
      });
      this.userForm.get('institution')?.setValue(this.user?.institution ?? '', {
        onlySelf: false,
      });
      this.userForm.get('phone_primary')?.setValue(this.user?.phone_primary ?? '', {
        onlySelf: false,
      });
      this.userForm.get('phone_alternate')?.setValue(this.user?.phone_alternate ?? '', {
        onlySelf: false,
      });
      this.userForm.get('email_primary')?.setValue(this.user?.email_primary ?? '', {
        onlySelf: false,
      });
      this.userForm.get('email_alternate')?.setValue(this.user?.email_alternate ?? '', {
        onlySelf: false,
      });
      this.userForm.get('roles')?.setValue(this.user?.roles_ids ?? '', {
        onlySelf: false,
      });
      this.userForm.get('roles_ids')?.setValue(this.user?.roles_ids ?? '', {
        onlySelf: false,
      });
      this.userForm.get('is_inactive')?.setValue(this.user?.is_inactive ?? 1, {
        onlySelf: false,
      });

      this.userForm.get('pk_user_id')?.setValue(this.user?.pk_user_id ?? '', {
        onlySelf: false,
      });
      this.username = this.user?.username ?? '';
      this.validateUsername(this.userForm.get('username')?.value ?? '');
    });

  }

  public updateUser(): void {
    try {
      this.user.is_inactive = this.userForm.get('isInactive')?.value;
      this.userManagementService.postAllData(this.userForm.value);
    } catch (e) {
      console.log('current-user update error', e);
    }
  }

  public updateRoles(event: any){
    try{
      this.userForm.get('roles_ids')?.setValue(event.value ?? []);
    }
      catch(e: any){
        console.log(e);
      }
  }

  public updateInactive(event: MatCheckboxChange): void {
    try {
      this.userForm.get('is_inactive')?.setValue( event.checked ? 1: 0, {
        onlySelf: false
      });
    } catch (e) {

    }
  }
  public updateInput(
    group: FormGroup,
    controlName = '',
    value: any, name: string = ''
  ): void {
    try {
      group.get(controlName)?.setValue(value);
      if (group.get(controlName)?.valid) {
      }
    } catch (e) {
      console.log(`Error updating control ${controlName}`, group);
    }
  }

  public isInactive(){
    return this.user.is_inactive === 1;
  }

  private blankUserForm(): FormGroup {
    return this._formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      username: ['', this.UsernameValidator()],
      institution: ['', Validators.required],
      phone_primary: ['', [Validators.maxLength(15), Validators.minLength(1)]],
      phone_alternate: [''],
      email_primary: ['', [Validators.required, Validators.email]],
      email_alternate: ['', Validators.email],
      roles: ['', Validators.required],
      roles_ids: [''],
      is_inactive: [1],
      pk_user_id: ['']
    });
  }

  private validateUsername(username: string = ''): void {
    this.setUniqueName(username);
    this.setAllowedDomain(username);
  }

  private setUniqueName(username: string = ''): boolean {
    if (typeof(this.userForm) === 'undefined' || typeof(this.allUsers) === 'undefined') {
      return true;
    }
    if (
      (this.userForm.get('pk_user_id')?.value.toString() ?? '') === '' ||
      (this.userForm.get('username')?.value ?? '') === '' ) {
      return true;
    }
    try {
      const pk_user_id = this.user.pk_user_id;
      this.isUniqueName = !this.allUsers.some((user: User) => {
        return user.username === username && user.pk_user_id !== pk_user_id;
      });
      return this.isUniqueName;
    } catch (e) {
      return false;
    }
  }

  private setAllowedDomain(username: string = ''): boolean {
    if (typeof(this.userForm) === 'undefined' || typeof(this.allUsers) === 'undefined') { return true; }
    try {
      const domain = username.split('@');
      let userDomain = '';
      if ( (domain ?? []).length !== 2) {
        this.isAllowedDomain = false;
        return false;
      } else {
        userDomain = domain[1] ?? '';
      }
      this.isAllowedDomain = this.allowedDomains.includes(userDomain);
      return this.isAllowedDomain;
    } catch (e) {
      console.log('setAllowedDomain:', e);
      return false;
    }
  }

  private UsernameValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const unique = this.setUniqueName(control.value);
      const domain = this.setAllowedDomain(control.value);
      this.isUniqueName = unique;
      const isFilledOut = control.value.trim() !== '';
      return this.isUniqueName === false || this.isAllowedDomain === false || isFilledOut === false ?
        {
          isFilledOut,
          isUniqueName: this.isUniqueName,
          isAllowedDomain: this.isAllowedDomain
        } : null;
    };
  }


}
