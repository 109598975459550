<div id="confirm-container">
    <header class="heading-bar flex">
      <h2 mat-dialog-title>Are you sure you want to request for this case to be deleted?</h2>
    </header>
    <mat-dialog-content class="margin-sm-top">
      <p>A request will be sent to the Board Office Counsel for the following case to be deleted.</p>
      <section class="margin-sm-top margin-sm-bottom">
        <h3 class="margin-none">Case Title</h3>
        <p>
          {{data.title}}
        </p>
      </section>
      <section>
        <h3 class="margin-none">Case Details</h3>
        <p class="text-overflow-hidden">
          {{data.description}}
        </p>
      </section>
    </mat-dialog-content>
  
    <mat-dialog-actions
      class="flex-row space-between margin-top"
    >
      <button
        mat-stroked-button
        mat-dialog-close="cancel"
        color="primary"
      >
        Cancel
      </button>
  
      <button
        mat-stroked-button
        (click)="confirmDeleteCaseRequest()"
        color="warn"
      >
        Confirm
      </button>
  
    </mat-dialog-actions>
  </div>
  
  