import { Component, Input, EventEmitter, Output } from '@angular/core';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import * as moment from 'moment';
import { DateRangePickerContent } from './date-range-picker.content.interface';
import {MomentInput} from "moment";
import {DateValidator} from "../../../validators/date.validator";
const today = new Date();
const month = today.getMonth();
const year = today.getFullYear();
@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent {
  @Input() dateRangePicker: DateRangePickerContent = {
    title: '',
    content: [''],
    dateRangeLabel: ''
  }
  @Output() startOutput = new EventEmitter<any>();
  @Output() endOutput = new EventEmitter<any>();
  @Output() dateOutput = new EventEmitter<any>();
  public date = new FormGroup({
    start: new FormControl('', DateValidator.dateValidator),
    end: new FormControl('', DateValidator.dateValidator),
  });
  dateInput! : DateRangeOutput;

  public checkContent(
    content: string | undefined | Array<string> = ['']
  ): string {
    if (content instanceof Array) {
      return content.join(', ');
    }
    return typeof content === 'undefined' || content === null
      ? ''
      : content.trim();
  }

  public emit(label: string, value: unknown) {
    if (this.date.valid && this.date.get('start')?.value && this.date.get('end')?.value) {
      this.dateInput = {
        Label: this.dateRangePicker.dateRangeLabel,
        StartDate: this.date.value.start,
        EndDate: this.date.value.end
      };
    } else {
      this.dateInput = {
        Label: this.dateRangePicker.dateRangeLabel,
        StartDate: 'Wed Jan 01 1800 00:00:00 GMT-0550 (Central Standard Time)',
        EndDate: 'Wed Jan 01 2200 00:00:00 GMT-0600 (Central Standard Time)'
      };
    }
    this.dateOutput.emit(this.dateInput);
  }
}

export class DateRangeOutput {
  Label?: string;
  StartDate?: any;
  EndDate?: any;
}

