<section>
  <h3 class="offscreen">{{ checkContent(dateRangePicker.dateRangeLabel) }}</h3>
<mat-form-field class="form-field-flex">
  <mat-label>{{ checkContent(dateRangePicker.dateRangeLabel) }}</mat-label>
  <mat-date-range-input [rangePicker]="picker" [formGroup]="date" >
    <input matStartDate matInput placeholder="Start date" formControlName="start" (dateChange)="emit(checkContent(dateRangePicker.dateRangeLabel), $event)">
    <input matEndDate matInput placeholder="End date" formControlName="end" (dateChange)="emit(checkContent(dateRangePicker.dateRangeLabel), $event)">
  </mat-date-range-input>
  <mat-error>Invalid Date MM/DD/YYYY</mat-error>
  <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker></mat-date-range-picker>
</mat-form-field>
</section>
