<link rel="stylesheet" type="text/css" href="/assets/discovery/idpselect.css">

<div id="login-container">
  <section class="login-content">
      <h1>Litigation Tracking System</h1>
      <hr color="primary">
      <div class="flex-sm-row margin-top">
        <div class="margin-sm-right width-sm-50">
          <p>The Board of Regents, State of Iowa, is a group of nine citizens who govern five public educational institutions in the state through policymaking, coordination, and oversight, as provided by law.</p>

          <p>The institutions include Iowa's three public universities &#x2014; the University of Iowa, Iowa State University, and the University of Northern Iowa; and two special preschool/K-12th grade schools &#x2014; the Iowa School for the Deaf and the Iowa Braille and Sight Saving School.</p>
        </div>
        <div class="flex-column margin-sm-left width-sm-50">
          <h2>Please Log In </h2>
          <mat-form-field appearance="fill">
              <mat-label for="entityOptions">Select Institution</mat-label>
              <mat-select [(value)]="entityID" id="entityOptions">
<!--                <mat-select-trigger>{{ selectedEntity }}</mat-select-trigger>-->
                <mat-select-trigger><img [src]="selectedEntityImage" alt="no logo" height="50" /></mat-select-trigger>
                <mat-option *ngFor="let entity of entities" [value]="entity.entityID">
                    <span class="options-container">
                      <span class="option-image">
                        <img [src]="entity.image" alt="no logo" height="50" />
                      </span>
                      <!-- <span class="option-text">
                        {{entity.displayName}}
                      </span> -->
                    </span>
                  </mat-option>
              </mat-select>
          </mat-form-field>
          <button mat-flat-button id="loginBtn" color="primary" (click)="logIn()">Log In</button>
        </div>
      </div>
    </section>
</div>


  <div id="idpSelect"></div>
