<mat-card class="card click-container bg--white card--media-left card--enclosed">
    <div *ngFor="let img of card.image" class="card__media card__media--medium">
      <img class="card__img" [src]="img" alt="Alt Text" />
    </div>
    <div class="card__body">
      <mat-card-header>
        <h2 class="headline card__title">
            <mat-card-title
              [innerHTML]="checkContent(card.title)"
              class="headline__text"
            ></mat-card-title>
        </h2>
      </mat-card-header>
      <mat-card-content class="card__details">
        <mat-card-subtitle
          class="card__subtitle"
          [innerHTML]="checkContent(card.subtitle)"
        ></mat-card-subtitle>
        <div *ngFor="let content of card.content">
          <p [innerHTML]="checkContent(content)" class="card__meta"></p>
        </div>
      </mat-card-content>
  
      <mat-card-actions>
        <ng-container *ngFor="let button of card.buttons">
          <button
            mat-stroked-button
            class="bttn bttn--outline bttn--sans-serif"
            id="desc-a-card"
            [innerHTML]="checkContent(button.label)"
          ></button>
        </ng-container>
      </mat-card-actions>
    </div>
  </mat-card>