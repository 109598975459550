import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, tap } from 'rxjs';

@Injectable()
export class UserInterceptorInterceptor implements HttpInterceptor {

  constructor(private router: Router) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // if status code is 401 unauthorized, route to /discovery
    return next.handle(request).pipe(
      tap({
        next: (event) => {
          if ( ((event as HttpResponse<any>)?.status ?? 200) === 401) {
            this.router.navigate(['/discovery']);
            // window.location.href='/discovery';
          }
        },
        error: (error) => {
          // window.location.href='/discovery';
          this.router.navigate(['/discovery']);
        }
      })
    );
  }
}
