import { BaseModel } from "./base-model";

export class CurrentUser {
  public pk_user_id: number = -1;
  public username: string = '';
  public institution: number = -1;
  public institution_value: string = '';
  public firstname: string = '';
  public lastname: string = '';
  public email_primary: string = '';
  public phone_primary: string = '';
  public email_alternate: string = '';
  public phone_alternate: string = '';
  public sn: string = '';
  public mail: string = '';
  public givenName: string = '';
  public eduPersonPrincipalName: string = '';
  public displayName: string = '';
  public permissions: { pk_permission_id: number, permission: string}[] = [];
  public roles: { pk_role_id: number, role: string}[] = [];
  public is_inactive: number = 1;
}

