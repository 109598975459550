import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {tap} from "rxjs/operators";
import { Observable } from 'rxjs/internal/Observable';
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Details} from "../../models/details";
import {CaseService} from "../../services/case.service";

@Component({
  selector: 'app-preview-admin-claim',
  templateUrl: './preview-admin-claim.component.html',
  styleUrls: ['./preview-admin-claim.component.scss']
})
export class PreviewAdminClaimComponent {

  public detailsUrl = environment.apiUrl + environment.detailsEndpoint;

  public details: Details = new Details();

  constructor(
    public caseService: CaseService,
      private http: HttpClient,
      @Inject(MAT_DIALOG_DATA) public data: any = {
        pk_administrative_claim_id: data.pk_administrative_claim_id,
        fk_case_id: data.fk_case_id,
        agency: data.agency,
        claim_number: data.claim_number,
        claim_submitted_date: data.claim_submitted_date,
        created_by: data.created_by,
        created_at: data.created_at,
        updated_by: data.updated_by,
        updated_at: data.updated_at,
        created_firstname: data.created_firstname,
        created_lastname: data.created_lastname,
        created_email: data.created_email,
        updated_firstname: data.updated_firstname,
        updated_lastname: data.updated_lastname,
        updated_email: data.updated_email
       }
    ) {
    this.getDetails().subscribe();
  }

  public get status(): string  {
    try {
      let status = ''
      if (this.details.is_closed === 1) {
        status = 'Closed'
      } else {
        status = 'Open'
      }
      return status;
    } catch (e) {
      console.log(e);
      return '';
    }
  }

  public getDetails(): Observable<any>  {
    let url = this.detailsUrl + `/${this.data.fk_case_id}`;
    return this.http.get<any>(url).pipe(
      tap(
        details => {
          this.details = details.cases[0];
        }));
  }

}

