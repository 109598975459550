export class Litigations {
   public pk_litigation_id: number = -1; /* int */
   public fk_case_id: number = -1; /* int (not null) */
   public opposing_counsel: string = ''; /* TO-DO - Remove nvarchar */
   public plaintiff: string = ''; /* nvarchar */
   public other_plaintiff: string = ''; /* nvarchar */
   public defendant: string = ''; /* nvarchar */
   public other_defendant: string = ''; /* nvarchar */
   public lawsuit_category: number = -1; /* int */
   public court_assigned_number: string = ''; /* varchar(255) */
   public fk_admin_claim_id: number = -1; /* int */
   public venue: string = ''; /* nvarchar */
   public jurisdiction: string = ''; /* nvarchar */
   public remedy_sought: string = ''; /* nvarchar */
   public remedy_amount: string = ''; /* money */
   public is_court_appeal_filed: string = ''; /* bit (default 0 not null) */
   public attorney_assigned: number = -1; /* int */
   public attorney_general_assigned: number = -1; /* int */
   public attorney_general_names: string = ''; /* bit default 0 not null */
   public is_outside_counsel_required: string = ''; /* bit default 0 not null */
   public outside_counsel_justification: string = ''; /* nvarchar */
   public recommended_outside_counsel: string = ''; /* nvarchar */
   public outside_counsel_fees: string = ''; /* nvarchar */
   public outside_counsel_amount_paid: string = ''; /* money */
   public case_disposition: string = ''; /* nvarchar */
   public settlement_amount_paid: string = ''; /* money */
   public settlement_funding_src: string = ''; /* nvarchar(max) */
   public mediation_date: string = ''; /* string */
   public trial_date: string = ''; /* string */
   public litigation_hold: number = -1; /* bit default 0 not null */
   public created_by: number = -1; /* int */
   public created_at: string = ''; /* stringtime2(0) default CURRENT_TIMESTAMP */
   public updated_by: number = -1; /* int */
   public updated_at: string = ''; /* stringtime2(0) */
   public outside_counsel_email_sent: number = -1;
   public opposing_counsel_attorney: string = '';
   public opposing_counsel_phone_number: string = '';
   public opposing_counsel_law_firm: string = '';
   public opposing_counsel_city: string = '';
   public opposing_counsel_state: string = '';
   public opposing_counsel_address: string = '';
   public tort_claim_id: number = -1;
   public tort_claim_text: string = '';
}
