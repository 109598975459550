import { Injectable } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Subject, takeUntil } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class BreakpointService {

  public _Destroyed = new Subject<void>();
  public _IsMobile = false;

  public _MobileWidth = '(max-width: 800px)';

  constructor(
    public _BreakpointObserver: BreakpointObserver
  ) {
    this.observe()
      .pipe(takeUntil(this._Destroyed))
      .subscribe((result: any) => {
        this._HandleResize(result);
      });
   }

   public get mobile(): boolean {
    return this._IsMobile;
   }

  public _HandleResize(observed: any): void {
    console.log('resized');
    this._IsMobile = observed.matches === true;
  }

  public observe(): any  {
    return this._BreakpointObserver.observe([this._MobileWidth]);
  }
}
