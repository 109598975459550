import { R3SelectorScopeMode } from '@angular/compiler';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CsvExportService {

  private separator = ",";
  private enclosure = '"';
  private escape = '"';
  private eol = "\r\n";
  private exportedFields: string[] = [
    "classification_value",
    "type_value",
    "title",
    "description",
    "is_closed",
    "filed_date",
    "resolution_date",
    "settlement_amount_paid",
    "settlement_funding_src",
    // "settlement_amount", // replaced by external_claim_settlement and internal_claim_settlement
    "external_claim_settlement",
    "internal_claim_settlement",
    // "is_delete_requested",
    // "delete_requested_by",
    "created_by_firstname",
    "created_by_lastname",
    "created_by_email",
    "updated_by_firstname",
    "updated_by_lastname",
    "updated_by_email",
    "created_at",
    // "updated_by",
    // "updated_at",
    "trial_date",
    "mediation_date",
    "subtypes_values",
    "institutions_values",
    "reviewers_values",
    "in_house_attorneys_values",
    "attorney_general_names",
    "notes_combined",
  ];
  private arrayFields: string[] = [
    "subtypes_values",
    "institutions_values",
    "in_house_attorneys_values",
    // "attorney_generals_values",
  ];
  private dateFields: string[] = [
    "filed_date",
    "created_at",
    "updated_at",
    "trial_date",
    "mediation_date",
    "resolution_date"
  ];
  private boolFields: string[] = [
    "is_closed",
    "is_delete_requested",
  ];

  constructor() { }

  /**
   *
   * @param data - an array of objects where each object keys.  If the objects do not have the same keys, then the export
   * will not look right.  Even if there is only one entry, it must still be an array
   *
   * Prompts the current-user to save the objects as a csv file
   *
   */
  public saveData(data: any[] = [], filename = "exported-data.cav"): void {
    // both arrays [1,2,3] and objects { "a": 1, "b": 2} are objects
    if (typeof(data) !== 'object') {
      return;
    }
    //if it is an object or an empty array
    if (typeof(data.length) === 'undefined' || data.length === 0) {
      return;
    }
    try {
      if (Object.keys(data[0]).length === 0) {
        return;
      }
      let csv = `sep=${this.separator}${this.eol}`;
      const header = this.exportedFields.map( header => {
        if (header === 'settlement_amount_paid') {
          header = 'litigation_settlement'
        }
        // if (header === 'settlement_amount') {
        //   header = 'external_claim_settlement'
        // }
        return this.enclosure + header.toString().replaceAll(this.enclosure, `${this.escape}${this.enclosure}`) + this.enclosure;
      }).join(this.separator);
      let dataRows = "";
      for (let row of data) {
        let dataRow: string[]= [];
        this.exportedFields.forEach( field => {
          let val = row[field] ?? "";
          if (this.boolFields.includes(field)) {
            val = val.toString() === '1' ? 'true' : (val.toString() === '0' ? 'false' : '');
          } else if (this.dateFields.includes(field)) {
            val = val.includes("1900-01-01") ? "" : val;
            val = val === "null" ? "" : val;
          } else if (this.arrayFields.includes(field)) {
            val = val.join(this.separator + " ");
          }
          if (field === 'external_claim_settlement' && row['classification_value'] === 'External Complaints') {
            val = row['settlement_amount'];
          } else if (field === 'internal_claim_settlement' && row['classification_value'] === 'Internal Complaints') {
            val = row['settlement_amount'];
          }
          // now handle internal and external settlements
          dataRow.push(this.enclosure + val.toString().replaceAll(this.enclosure, `${this.escape}${this.enclosure}`) + this.enclosure);
        });
        dataRows += this.eol + dataRow.join(this.separator);
      };
      csv += header + dataRows + this.eol;
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch(e) {
      console.log(e);
    }
  }
}
