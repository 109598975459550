<ng-container *ngIf="isDiscovery === true; else app">
  <app-discovery></app-discovery>
</ng-container>
<ng-template #app>
<mat-toolbar *ngIf="breakPointService.mobile" class="sidenav-header">
<!--  <button mat-icon-button (click)="sidenav.toggle()">-->
<!--      <mat-icon *ngIf="!sidenav.opened">menu</mat-icon>-->
<!--      <mat-icon *ngIf="sidenav.opened">close</mat-icon>-->
<!--  </button>-->
  <mat-nav-list class="mobile-nav-list">
    <a
      routerLink="/add-new-case"
      mat-flat-button
      color="primary"
      class="flex margin-sm"
      *ngIf="(currentUserService.canAddUpdateViewOwnCase$ | async) || (currentUserService.canEditCase$ | async) || (currentUserService.canAddEditAllUI$ | async)"
    >
      <mat-icon matListItemIcon>add</mat-icon>
      Add a New Case
    </a>
    <a mat-list-item routerLink="/search-cases" class="menu-button">
      <mat-icon matListItemIcon>cases</mat-icon>My Cases
    </a>
    <ng-container *ngIf="currentUserService.canAddEditUsers$ | async">
      <a mat-list-item routerLink="/manage-users" class="menu-button">
        <mat-icon matListItemIcon>group</mat-icon>Manage Users
      </a>
    </ng-container>
  </mat-nav-list>

</mat-toolbar>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #sidenav
    [mode]="mode"
    [opened]="isOpen"
    class="sidenav"
    [fixedInViewport]="myFixed
  ">
    <mat-nav-list>
      <a routerLink="/add-new-case"
        mat-flat-button
        color="primary"
        class="flex margin-sm"
        *ngIf="(currentUserService.canAddUpdateViewOwnCase$ | async) || (currentUserService.canEditCase$ | async) || (currentUserService.canAddEditAllUI$ | async)"
      >
        <mat-icon matListItemIcon>add</mat-icon>
        Add a New Case
      </a>
      <a mat-list-item routerLink="/search-cases" class="menu-button">
        <mat-icon matListItemIcon>cases</mat-icon>My Cases
      </a>
      <ng-container *ngIf="currentUserService.canAddEditUsers$ | async">
        <a mat-list-item routerLink="/manage-users" class="menu-button">
          <mat-icon matListItemIcon>group</mat-icon>Manage Users
        </a>
      </ng-container>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
</ng-template>
