import { BaseModel } from "./base-model";

export class User extends BaseModel {
    public pk_user_id: number = -1; /* int primary key identity */
    public username: string = ''; /* nvarchar(255) not null */
    public institution: number = -1; /* int not null */
    public institution_value: string = ''
    public firstname: string = ''; /* nvarchar(255) */
    public lastname: string = ''; /* nvarchar(255) */
    public email_primary: string = ''; /* nvarchar(255) */
    public phone_primary: string = ''; /* nvarchar(50) */
    public email_alternate: string = ''; /* nvarchar(255) */
    public phone_alternate: string = ''; /* nvarchar(50) */
    public created_by: number = -1; /* int */
    public created_at: string = ''; /* datetime2(0) default CURRENT_TIMESTAMP */
    public updated_by: number = -1; /* int */
    public updated_at: string = ''; /*  datetime2(0) */
    public roles: Array<any> = [];
    public roles_ids: Array<any> = [];
    public roles_full: Array<any> = []
    public is_inactive: number = 0;
}
