<div class="search-cases-container" [class.white-background]="spinnerShow">
<p class="flex-row flex-end margin-sm-bottom"  *ngIf="(currentUserService.canAddUpdateViewOwnCase$ | async) || (currentUserService.canEditCase$ | async) || (currentUserService.canAddEditAllUI$ | async)">
  <button mat-stroked-button color="primary" (click)="exportFoundset()">Export to Excel</button>
</p>

<section class="flex-column flex-space-between margin-bottom">
  <h1 class="bold-headline--serif">Select a case below to view details</h1>
  <div class="flex-md-row flex-space-between margin-sm-bottom margin-top align-center">
      <app-search-input (callParent)="search($event)" class="width-md-50"></app-search-input>
      <app-checkbox (click)="toggleDateColumns()" class="checkbox-container width-md-50"></app-checkbox>
      <app-button-toggle-grp (buttonGroupOutput)="filterStatus($event)" class="btn-toggle-grp-container width-md-50"></app-button-toggle-grp>
  </div>
</section>

<section>
  <h2 class="offscreen">Filters</h2>
  <app-expansion-panel (dropdownOutput)="addFilter(listDataService.dropDownSelected, $event)"
    (dateOutput)="addDateFilter($event)"
    (resetFiltersOutput)="resetFilters()"
  >
  </app-expansion-panel>
</section>

<app-table [renderTable]="renderTable" [displayedColumns]="displayedColumns" [cases]="parentCases"  [spinnerShow]="spinnerShow"></app-table>
</div>
