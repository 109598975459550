<header class="heading-bar flex">
    <h2 mat-dialog-title>Tort Claim Details - {{ data.tort_claim_id }}</h2>
</header>
<mat-dialog-content>
    <p class="flex-row flex-end margin-sm-bottom">
        <a mat-dialog-close mat-stroked-button color="primary" target="_blank" [href]="'case-details/' + details.pk_case_id"><mat-icon>arrow_circle_right</mat-icon> Go to Case Details</a>
    </p>
    <section>
      <h1 class="bold-headline--serif">{{ details.title }}</h1>
      <p>Created {{ caseService.formatDate(details.created_at) }} by {{ details.created_by_firstname }} {{ details.created_by_lastname }}</p>
      <mat-chip-set aria-label="Case Details">
        <mat-chip disabled
                  [ngClass]="{
                'classification-value__litigation__chip' : details.classification_value === 'Litigation',
                'classification-value__internal__chip' : details.classification_value === 'Internal Complaints',
                'classification-value__external__chip' : details.classification_value === 'External Complaints'}"
        >{{ details.classification_value }}</mat-chip>
        <mat-chip disabled
                  [ngClass]="{
                'status-value__open__chip' : status === 'Open',
                'status-value__closed__chip' : status === 'Closed'}"
        >Status: {{ status }}</mat-chip>
      </mat-chip-set>
    </section>

    <!-- Case Details -->
    <section class="case-details">
        <div class="flex-row flex-space-between">
            <h2>Case Details</h2>
        </div>
        <hr>
      <section>
        <h3>Case Type</h3>
        <p>{{ details.type_value }}</p>
      </section>
      <section>
        <h3>Subtype(s)</h3>
        <ul *ngFor="let subtype of details.subtypes_values">
          <li> {{ subtype }}</li>
        </ul>
        <p *ngIf="details.subtypes_values.length === 0"><i>(none)</i></p>
      </section>
        <section>
            <h3>Description</h3>
            <p class="case-description" *ngIf="details.description">{{ details.description }}</p>
            <p class="case-description" *ngIf="!details.description"><i>(none)</i></p>
        </section>
      <section>
        <h3>Institution(s)</h3>
        <ul *ngFor="let institution of details.institutions_values">
          <li> {{ institution }}</li>
        </ul>
      </section>
        <section>
            <h3>Date Filed / Submitted</h3>
            <p *ngIf="details.filed_date">{{ caseService.formatDate(details.filed_date) }}</p>
            <p *ngIf="!details.filed_date"><i>(none)</i></p>
        </section>
        <section>
            <h3>Date Resolution / Denial</h3>
            <p *ngIf="details.resolution_date">{{ caseService.formatDate(details.resolution_date) }}</p>
            <p *ngIf="!details.resolution_date"><i>(none)</i></p>
        </section>
    </section>
</mat-dialog-content>

<mat-dialog-actions class="flex-row flex-end">
  <button
    mat-stroked-button
    mat-dialog-close
    color="warn"
  >
    Close
  </button>
</mat-dialog-actions>

