import { Component, OnInit, ViewChild } from '@angular/core';
import {MatDrawerMode, MatSidenav} from '@angular/material/sidenav';
import { BreakpointService } from "../../services/breakpoint.service";
import {CaseService} from "../../services/case.service";
import { CurrentUserService } from "../../services/current-user/current-user.service";
import {Breakpoints} from "@angular/cdk/layout";

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {

  @ViewChild(MatSidenav, { static: true })
  sidenav!: MatSidenav;

  public mode: MatDrawerMode = 'side';
  public myFixed: string = 'fixed';
  public isOpen: boolean = true;
  public isSmall: boolean = false;

  constructor(
    public breakPointService: BreakpointService,
    public caseService: CaseService,
    public currentUserService: CurrentUserService
    ) {
    window.addEventListener('beforeunload', function() {
      sessionStorage.clear();
    });
    window.addEventListener('beforeunload', function() {
      localStorage.clear();
    });
  }

  ngOnInit(): void {
    this.breakPointService._BreakpointObserver.observe(['(max-width: 800px)']).subscribe( observer => {
      console.log('observer is', observer);
      this.isSmall = observer.matches;
      if (this.isSmall) {
        this.mode = 'over'
        this.myFixed = ''
        this.isOpen = false;
      } else {
        this.mode = 'side'
        this.myFixed = 'fixed'
        this.isOpen = true;
      }
    })
    // this.breakPointService.observe().subscribe(() => {
    //   if (this.breakPointService.mobile) {
    //     this.mode = 'over';
    //     // this.sidenav.close();
    //   } else {
    //     this.sidenav.mode = 'side';
    //     // this.sidenav.open();
    //   }
    // });
  }

  public get isDiscovery(): boolean {
    const path = window.location.pathname;
    try {
      return path.indexOf('/discovery') === 0; // doesn't matter if pathname includes leading /
    } catch (e) {
      return false;
    }
  }

}
