export class Details {
  classification_value: string = '';
  type_value: string = '';
  created_by_firstname: string = '';
  created_by_lastname: string = '';
  created_by_email: string = '';
  updated_by_firstname: string = '';
  updated_by_lastname: string = '';
  updated_by_email: string = '';
  delete_request_firstname: string = '';
  delete_request_lastname: string = '';
  delete_request_email: string = '';
  pk_case_id: number = -1;
  classification: number = -1;
  type: number = -1;
  title: string = '';
  description: string = '';
  is_closed: number = 0;
  filed_date: string = '';
  resolution_date: string = '';
  is_delete_requested: number = 0;
  delete_requested_by: string = '';
  created_by: number = -1;
  created_at: string = '';
  updated_by: string = '';
  updated_at: string = '';
  trial_date: string = '';
  mediation_date: string = '';
  subtypes_values: Array<any> = [];
  subtypes: Array<number> = [];
  subtypes_full: Array<any> = [];
  institutions: Array<any> = [];
  institutions_values: Array<any> = [];
  institutions_full: Array<any> = [];
  in_house_attorneys: Array<any> = [];
  in_house_attorneys_values: Array<any> = [];
  in_house_attorneys_full: Array<any> = [];
  attorney_generals: Array<any> = [];
  attorney_generals_values: Array<any> = [];
  attorney_generals_full: Array<any> = [];
  in_house_attorney_id: number = -1;
  in_house_attorney_first: string = '';
  in_house_attorney_last: string = '';
  in_house_attorney_email: string = '';
  in_house_attorney_phone: string = '';
  in_house_attorney_institution: string = '';
  attorney_general_assigned: number = -1;
}
