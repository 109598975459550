<div id="view-note-container">
  <header class="heading-bar flex">
    <h2 mat-dialog-title>View Note</h2>
  </header>
  <mat-dialog-content>
    <p>{{ data }}</p>
  </mat-dialog-content>

  <mat-dialog-actions
      class="flex-row flex-end margin-top"
    >
    <button
      mat-stroked-button
      mat-dialog-close
      color="warn"
    >
      Cancel
    </button>
  </mat-dialog-actions>
</div>