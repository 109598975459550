import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MatDialog } from "@angular/material/dialog";
import { ProgressSpinnerMode } from "@angular/material/progress-spinner";
import { Router } from "@angular/router";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";

import { DropdownSelectService } from "../shared/services/dropdown-select.service";
import { AddNoteComponent } from "../shared/components/add-note/add-note.component";
import { DropdownDetails, ListDataService } from "../shared/services/list-data/list-data.service";
import { ViewNoteComponent } from "../shared/components/view-note/view-note.component";
import { PreviewAdminClaimComponent } from '../shared/components/preview-admin-claim/preview-admin-claim.component';;
import { CurrentUserService } from '../shared/services/current-user/current-user.service';
import { CaseService } from "../shared/services/case.service";
import {AdministrativeClaims} from "../shared/models/administrative-claims";
import {first, Subscription} from "rxjs";
import {ThemePalette} from "@angular/material/core";

@Component({
  selector: 'app-add-new-case',
  templateUrl: './add-new-case.component.html',
  styleUrls: ['./add-new-case.component.scss']
})
export class AddNewCaseComponent implements OnInit, OnDestroy {

  @Input() spinnerShow = true;

  public color: ThemePalette = 'primary';
  public mode = 'indeterminate' as ProgressSpinnerMode;

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  public notesColumns: string[] = ['created_at', 'note', 'filename', 'view', 'delete'];

  public jurisdictions = [
    'State Jurisdiction',
    'Federal Jurisdiction'
  ];

  public itemStrings = [
    {label: 'Yes', value: '1'},
    {label: 'No', value: '0'}
  ];

  public items = [
    {label: 'Yes', value: 1},
    {label: 'No', value: 0}
  ];

  public optionStrings = [
    {label: 'Approved', value: '1'},
    {label: 'Denied', value: '0'}
  ];

  public options = [
    {label: 'Approved', value: 1},
    {label: 'Denied', value: 0}
  ];

  public showLitigations = false;

  public showGrievances = false;

  public showAdministrativeClaims = false;

  public showAppeals = false;

  public adminClaimData: AdministrativeClaims = new AdministrativeClaims();

  public blankAdminClaimData: AdministrativeClaims = new AdministrativeClaims();

  public detailsGroup: FormGroup = this.caseService.fullCaseForm.get(
    'details'
  ) as FormGroup;

  public litigationsGroup: FormGroup = this.caseService.fullCaseForm.get(
    'litigations'
  ) as FormGroup;

  public grievancesGroup: FormGroup = this.caseService.fullCaseForm.get(
    'grievances'
  ) as FormGroup;

  public administrativeClaimsGroup: FormGroup = this.caseService.fullCaseForm.get(
    'administrativeClaims'
  ) as FormGroup;

  public appealsGroup: FormGroup = this.caseService.fullCaseForm.get(
    'appeals'
  ) as FormGroup;

  public notesGroup: FormArray = this.caseService.fullCaseForm.get(
    'notes'
  ) as FormArray;

  public previewAdminClaimGroup: FormArray = this.caseService.fullCaseForm.get(
    'previewAdminClaim'
  ) as FormArray;

  public previewTortClaimGroup: FormArray = this.caseService.fullCaseForm.get(
    'previewTortClaim'
  ) as FormArray;

  public typesAvailable: boolean = false;
  public subTypesAvailable: boolean = false;

  public hasAddedNotes: boolean = false;
  constructor(public caseService: CaseService, public listDataService: ListDataService, public dropdownSelectService: DropdownSelectService,
              public router: Router, private dialog: MatDialog, public currentUserService: CurrentUserService) {
    this.caseService.state = 'add-new-case';
    this.listDataService.getListData();
    this.getNewCase();
    this.listDataService.subtypeValues$.subscribe(subtypes => {
      this.subTypesAvailable = (subtypes as DropdownDetails[] ?? []).length > 0;
      if ( (subtypes as DropdownDetails[] ?? []).length > 0 ) {
        this.detailsGroup.get('subtypes')?.enable();
      } else {
        this.detailsGroup.get('subtypes')?.disable();
      }
    });
    this.listDataService.typeValues$.subscribe( types => {
      this.typesAvailable = (types as DropdownDetails[] ?? []).length > 0;
      if( (types as DropdownDetails[] ?? []).length > 0 ) {
        this.detailsGroup.get('type')?.enable();
      } else {
        this.detailsGroup.get('type')?.disable();
      }
    });
  }

  ngOnInit() {
    this.caseService.tempNotes = [];
  }

  ngOnDestroy() {
    console.log('zzzzzz destroy');
    // if (this.caseIDSub !== null) {
    //   this.caseIDSub?.unsubscribe();
    // }
  }


  public get disableTypes(): boolean {
    try {
      return (this.detailsGroup.get('classification')?.value ?? '') === '';
    } catch (e) {
      return true;
    }
  }
  public get disableSubtypes(): boolean {
    try {
      return (this.detailsGroup.get('type')?.value ?? '') === '';
    } catch (e) {
      return true;
    }
  }

  public objectEmpty(obj: Object): boolean {
    return Object.keys(obj).length === 0;
  }

  public download(filename: any, text: any): void {
    try {
      let element = document.createElement('a');
      element.setAttribute('href', encodeURI(text));
      element.setAttribute('download', filename);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } catch (e) {
      console.log(`Error downloading ${filename}`, e);
    }
  }

  public getNewCase(): void {
    this.caseService.getNewEmptyCase();
    // now fill the groups with the new blank data, because the html doesn't use this.caseService.fullCaseForm as the source
    this.detailsGroup = this.caseService.fullCaseForm.get('details') as FormGroup;
    this.litigationsGroup = this.caseService.fullCaseForm.get('litigations') as FormGroup;
    this.grievancesGroup = this.caseService.fullCaseForm.get('grievances') as FormGroup;
    this.administrativeClaimsGroup = this.caseService.fullCaseForm.get('administrativeClaims') as FormGroup;
    this.appealsGroup = this.caseService.fullCaseForm.get('appeals') as FormGroup;
    this.notesGroup = this.caseService.fullCaseForm.get('notes') as FormArray;
    this.previewAdminClaimGroup = this.caseService.fullCaseForm.get('previewAdminClaim') as FormArray;
    this.previewTortClaimGroup = this.caseService.fullCaseForm.get('previewTortClaim') as FormArray;
    // end of filling groups with the blank data
    console.log('caseservice.fullcaseform is now', this.caseService.fullCaseForm)
    if (!this.currentUserService.canAddAssignAllCase$.value)  {
      this.caseService.fullCaseForm.get('details.institutions')?.setValue(this.currentUserService.currentUser.institution);
    }
    this.caseService.tempNotes = [];
    this.caseService.addNewNotes = new MatTableDataSource(this.caseService.tempNotes);
    this.caseService.addNewNotes.sort = this.sort;
    this.caseService.addNewNotes.paginator = this.paginator;
    this.spinnerShow = false;
  }

  public viewNote(note: string): void {
    try {
      this.dialog.open(ViewNoteComponent, {
        data: note,
        width: '95vw',
        maxWidth: '900px',
        height: 'auto',
        maxHeight: '600px',
      });
    } catch (e) {
      console.log('Error loading plan Advisor search: ', e);
    }
  }

  public addNote(): void {
    try {
      const dialogRef = this.dialog.open(AddNoteComponent, {
        data: this.notesGroup,
        width: '95vw',
        maxWidth: '900px',
        height: '95vw',
        maxHeight: '640px',
      });
      dialogRef.afterClosed().subscribe(  result => {
          this.caseService.addNewNotes = new MatTableDataSource(this.caseService.tempNotes);
        }
      );
    } catch (e) {
      console.log('Error loading plan Advisor search: ', e);
    }
  }

  public deleteNote(value: any):  void  {
    const index = this.caseService.tempNotes.indexOf(value);
    this.caseService.tempNotes.splice(index, 1);
    this.caseService.addNewNotes = new MatTableDataSource(this.caseService.tempNotes);
  }

  public previewAdminClaim(): void {
    try {
      this.dialog.open(PreviewAdminClaimComponent, {
        data: this.adminClaimData,
        width: '95vw',
        maxWidth: '900px',
        height: 'auto',
        maxHeight: '600px',
      });
    } catch (e) {
      console.log('Error loading Administrative Claim Preview: ', e);
    }
  }

  public updateField(
    group: FormGroup,
    controlName = '',
    value: any,
    name: string = ''
  ): void {
    try {
      if ( controlName === 'classification'  && name !== '') {
        this.listDataService.updateTypeSubtype(controlName, value, name);
        this.caseService.fullCaseForm.get('details.type')?.setValue(-1, { onlySelf: false, emitValue: true});
        this.caseService.fullCaseForm.get('details.subtypes')?.setValue([], { onlySelf: false, emitValue: true});
      }
      if ( controlName === 'type' && name !== '') {
        this.listDataService.updateTypeSubtype(controlName, value, name);
        this.caseService.fullCaseForm.get('details.subtypes')?.setValue([], { onlySelf: false, emitValue: true});
      }
      group.get(controlName)?.setValue(group.get(controlName)?.value);
      this.showSection();
    } catch (e) {
      console.log(`Error updating control ${controlName}`, group);
    }
  }

  public setAdminClaimData(data: any): void  {
    this.adminClaimData = data;
  }

  public addCase(): void  {
    this.hasAddedNotes = false;
    this.caseService.postNewData();
    this.caseService.newCaseID$.subscribe( caseID => {
      // if this.hasAddedNotes === false
      if (caseID > 0 && this.hasAddedNotes === false) {
          this.caseService.tempNotes.forEach( note => {
            note.fk_case_id = caseID;
            this.caseService.postNewNote(note);
          });
          this.hasAddedNotes = true;
        // this.caseService.tempNotes = [];
          // this.caseService.newCaseID.unsubscribe();
        }
      });

    this.router.navigate(['/search-cases']);
  }

  public validForm(): boolean {
    if (this.showAdministrativeClaims) {
      return <boolean>this.caseService.fullCaseForm.get('administrativeClaims.claim_number')?.valid && this.caseService.validForm();
    } else  {
      return this.caseService.validForm();
    }
  }

  public showSection() {
    this.showLitigations = this.detailsGroup.get('classification')?.value === 1 &&
      (this.detailsGroup.get('type')?.value === 4 ||
       this.detailsGroup.get('type')?.value === 5 ||
       this.detailsGroup.get('type')?.value === 6 ||
       this.detailsGroup.get('type')?.value === 7 ||
       this.detailsGroup.get('type')?.value === 8 ||
       this.detailsGroup.get('type')?.value === 9 ||
       this.detailsGroup.get('type')?.value === 10 ||
       this.detailsGroup.get('type')?.value === 11 ||
       this.detailsGroup.get('type')?.value === 12 ||
       this.detailsGroup.get('type')?.value === 13 ||
       this.detailsGroup.get('type')?.value === 14 ||
       this.detailsGroup.get('type')?.value === 15 ||
       this.detailsGroup.get('type')?.value === 86 ||
       this.detailsGroup.get('type')?.value === 16);

       this.showGrievances = this.detailsGroup.get('classification')?.value === 2 &&
       (this.detailsGroup.get('type')?.value === 17 ||
       this.detailsGroup.get('type')?.value === 18 ||
       this.detailsGroup.get('type')?.value === 19 ||
       this.detailsGroup.get('type')?.value === 20 ||
       this.detailsGroup.get('type')?.value === 21 ||
       this.detailsGroup.get('type')?.value === 22);

     this.showAdministrativeClaims = (this.detailsGroup.get('classification')?.value === 2 ||  this.detailsGroup.get('classification')?.value === 3) &&
       (this.detailsGroup.get('type')?.value === 23 ||
       this.detailsGroup.get('type')?.value === 27 ||
       this.detailsGroup.get('type')?.value === 26 ||
       this.detailsGroup.get('type')?.value === 29 ||
       this.detailsGroup.get('type')?.value === 25 ||
       this.detailsGroup.get('type')?.value === 30 ||
       this.detailsGroup.get('type')?.value === 28 ||
       this.detailsGroup.get('type')?.value === 83 ||
       this.detailsGroup.get('type')?.value === 86 ||
       this.detailsGroup.get('type')?.value === 82 ||
       this.detailsGroup.get('type')?.value === 84);

     this.showAppeals = this.detailsGroup.get('classification')?.value === 2 &&
       this.detailsGroup.get('type')?.value === 24;
  }


}
