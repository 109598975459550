<div class="case-details-container" [class.white-background]="spinnerShow">

  <ng-template cdkConnectedOverlay [cdkConnectedOverlayOpen]="spinnerShow">
    <mat-progress-spinner *ngIf="spinnerShow"
                          class="spinner-overlay"
                          [color]="color"
                          [mode]="mode">
    </mat-progress-spinner>
  </ng-template>

  <ng-container *ngIf="!spinnerShow">
    <ng-container *ngIf="(caseService?.details?.pk_case_id ?? -1) > 0; else noCase">
    <!-- Case Header -->
    <p class="flex-row flex-end margin-sm-bottom flex-space-between">
        <button mat-stroked-button routerLink="/search-cases" color="primary">Back to My Cases</button>
        <button mat-stroked-button *ngIf="currentUserService.canDeleteCase$ | async; else cannotDeleteCase" (click)="openDialog('0ms', '0ms')" color="warn">Delete Case</button>
        <ng-template #cannotDeleteCase>
          <button mat-stroked-button (click)="deleteCaseRequestDialog('0ms', '0ms')" color="warn">Delete Case Request</button>
        </ng-template>
    </p>
    <section class="margin-sm-bottom margin-sm-right case-details-heading" [formGroup]="detailsGroup">
        <h1 class="bold-headline--serif">{{ caseService.details.title }}</h1>
        <div>
            <p>Created {{ caseService.formatDate(caseService.details.created_at) }} by {{ caseService.details.created_by_firstname }} {{ caseService.details.created_by_lastname }}</p>
            <mat-chip-set aria-label="Case Details">
                <mat-chip disabled
                    [ngClass]="{
                    'classification-value__litigation__chip' : caseService.details.classification_value === 'Litigation',
                    'classification-value__internal__chip' : caseService.details.classification_value === 'Internal Complaints',
                    'classification-value__external__chip' : caseService.details.classification_value === 'External Complaints'}"
                >{{ caseService.details.classification_value }}</mat-chip>
                <mat-chip disabled
                    [ngClass]="{
                    'status-value__open__chip' : status === 'Open',
                    'status-value__closed__chip' : status === 'Closed'}"
                >Status: {{ status }}</mat-chip>
            </mat-chip-set>
        </div>
    </section>

    <!-- Case Details -->
    <section class="case-details" [formGroup]="detailsGroup">
        <div class="flex-row flex-space-between">
            <h2>Case Details</h2>
            <ng-container *ngIf="(currentUserService.canAddUpdateViewOwnCase$ | async) || (currentUserService.canEditCase$ | async) || (currentUserService.canAddEditAllUI$ | async)">
              <button mat-stroked-button *ngIf="!editingDetails" (click)="setEditStatus('Case Details')" color="primary" class="margin-left">
                  <mat-icon matIconButton>edit</mat-icon> Edit
              </button>
              <button mat-stroked-button *ngIf="editingDetails" (click)="setEditStatus('Case Details', true); getValues()" color="primary"
                      [disabled]="!caseService.validForm()" class="margin-left">
                  <mat-icon matIconButton>save</mat-icon> Save
              </button>
            </ng-container>
        </div>
        <hr>
        <ng-container *ngIf="!editingDetails">
            <section>
                <h3>Case Type</h3>
                <p *ngIf="caseService.details.type_value">{{ caseService.details.type_value }}</p>
                <p *ngIf="!caseService.details.type_value">{{ type.name }}</p>
            </section>
            <section>
            <h3>Subtype(s)</h3>
            <ul *ngFor="let subtype of subtypes">
                <li> {{ subtype.name }}</li>
            </ul>
              <p *ngIf="subtypes.length === 0"><i>(none)</i></p>
            </section>
            <section>
                <h3>Description</h3>
                <p *ngIf="caseService.details.description">{{ caseService.details.description }}</p>
            </section>
            <section>
                <h3>Institution(s)</h3>
            <ul *ngFor="let institution of institutions">
                <li> {{ institution.name }}</li>
            </ul>
            </section>
            <section>
                <h3>Date Filed / Submitted</h3>
                <p>{{ caseService.formatDate(caseService.details.filed_date) }}</p>
            </section>
            <section>
                <h3>Date Resolution / Denial</h3>
                <p *ngIf="caseService.details.resolution_date">{{ caseService.formatDate(caseService.details.resolution_date) }}</p>
                <p *ngIf="!caseService.details.resolution_date"><i>(none)</i></p>
            </section>
        </ng-container>

        <ng-container *ngIf="editingDetails">
            <div class="flex-column">
              <section>
                <h3 class="offscreen">Closed</h3>
                <mat-checkbox
                  [checked]="(detailsGroup.get('is_closed')?.value ?? 0) === 1"
                  (change)="updateCb(detailsGroup,'is_closed', $event)"
                  color="primary"
                  class="margin-sm-bottom"
                >
                  Closed
                </mat-checkbox>
              </section>

              <section>
                <h3 class="offscreen">Case Title</h3>
                <mat-form-field class="width-md-50">
                  <mat-label>Case Title</mat-label>
                  <input
                      matInput
                      (change)="updateInput(detailsGroup,'title', title.value)"
                      #title
                      [value]="caseService.details.title"
                      formControlName="title"
                  />
                  <mat-error>Required</mat-error>
                </mat-form-field>
              </section>

              <section>
                <h3 class="offscreen">Case Type</h3>
                <mat-form-field class="width-md-50">
                  <mat-label>Case Type</mat-label>
                  <mat-select
                    formControlName="type"
                    [value]="caseService.details.type"
                    #type
                    (keyup.enter)="updateInput(detailsGroup,'type', type.value)"
                  >
                    <mat-option *ngFor="let data of listDataService.typeValues$ | async" [value]="data.value"
                                (click)="updateInput(detailsGroup,'type', data.value, data.name)"
                    >
                      {{ data.name }}
                    </mat-option>
                  </mat-select>
                  <mat-error>Required</mat-error>
                </mat-form-field>
              </section>

              <section *ngIf="currentUserService.canAddEditUsers$ | async">
                <h3 class="offscreen">{{ subTypesAvailable ? 'Subtype(s)' : 'No Subtypes Available'}}</h3>
                <mat-form-field class="width-md-50">
                  <mat-label>{{ subTypesAvailable ? 'Subtype(s)' : 'No Subtypes Available'}}</mat-label>
                  <mat-select
                    multiple
                    [value]="caseService.details.subtypes"
                    #subtypes
                    (keyup.enter)="updateOther(detailsGroup,'subtypes', subtypes.value)"
                    formControlName="subtypes"
                  >
                    <mat-option *ngFor="let data of listDataService.subtypeValues$ | async" [value]="data.value"
                                (click)="updateOther(detailsGroup,'subtypes', data.value, data.name)"
                    >
                      {{ data.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </section>

              <section>
                <h3 class="offscreen">Description</h3>
                <mat-form-field class="width-md-50">
                    <mat-label>Description</mat-label>
                    <textarea
                        matInput
                        (change)="updateInput(detailsGroup,'description', description.value)"
                        #description
                        formControlName="description"
                        [value]="caseService.details.description">
                    </textarea>
                  <mat-error>Required</mat-error>
                </mat-form-field>
              </section>

              <section *ngIf="(currentUserService.canAddAssignAllCase$ | async) || (currentUserService.canAssignOwnCase$ | async) || (currentUserService.canAddEditAllUI$ | async)">
                <h3 class="offscreen">Institution(s)</h3>
                <mat-form-field class="width-md-50">
                    <mat-label>Institution(s)</mat-label>
                    <mat-select multiple [value]="caseService.details.institutions" formControlName="institutions"
                                #institutions
                                (keyup.enter)="updateOther(detailsGroup,'institutions', institutions.value)">
                      <ng-container *ngFor="let data of listDataService.institutions$.value">
                        <mat-option [value]="data.value"
                                    (click)="updateOther(detailsGroup,'institutions')" *ngIf="currentUserService.canAssignInstitution(data.value)"
                        >
                        {{ data.name }}
                        </mat-option>
                      </ng-container>
                    </mat-select>
                  <mat-error>Required</mat-error>
                </mat-form-field>
              </section>

              <section>
                <h3 class="offscreen">Date Filed / Submitted</h3>
                <mat-form-field class="margin-sm-bottom width-md-50" *ngIf="caseService.details.resolution_date">
                    <mat-label>Date Filed / Submitted</mat-label>
                    <input matInput [matDatepicker]="filedPicker" [max]="caseService.formatDate(caseService.details.resolution_date, 'edit')" formControlName="filed_date"
                           (dateChange)="updateOther(detailsGroup,'filed_date')" (keyup.backspace)="clearField(detailsGroup,'filed_date')"
                        [value]="caseService.formatDate(caseService.details.filed_date, 'edit')"
                    >
                    <mat-hint>MM/DD/YYYY</mat-hint>
                  <mat-error>Required</mat-error>
                    <mat-datepicker-toggle matIconSuffix [for]="filedPicker"></mat-datepicker-toggle>
                    <mat-datepicker #filedPicker></mat-datepicker>
                </mat-form-field>
              <mat-form-field class="margin-sm-bottom width-md-50" *ngIf="!caseService.details.resolution_date">
                <mat-label>Date Filed / Submitted</mat-label>
                <input matInput [matDatepicker]="filedPicker2" formControlName="filed_date"
                       (dateChange)="updateOther(detailsGroup,'filed_date')" (keyup.backspace)="clearField(detailsGroup,'filed_date')"
                       [value]="caseService.formatDate(caseService.details.filed_date, 'edit')"
                >
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-error>Required</mat-error>
                <mat-datepicker-toggle matIconSuffix [for]="filedPicker2"></mat-datepicker-toggle>
                <mat-datepicker #filedPicker2></mat-datepicker>
              </mat-form-field>
              </section>

              <section>
                <h3 class="offscreen">Date Resolution / Denial</h3>
                <mat-form-field class="width-md-50" *ngIf="caseService.details.resolution_date">
                    <mat-label>Date Resolution / Denial</mat-label>
                    <input matInput [matDatepicker]="resPicker" [min]="caseService.formatDate(caseService.details.filed_date, 'edit')" formControlName="resolution_date"
                           (dateChange)="updateOther(detailsGroup,'resolution_date')" (keyup.backspace)="clearField(detailsGroup,'resolution_date')"
                        [value]="caseService.formatDate(caseService.details.resolution_date, 'edit')"
                    >
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="resPicker"></mat-datepicker-toggle>
                    <mat-datepicker #resPicker></mat-datepicker>
                </mat-form-field>
              <mat-form-field class="width-md-50" *ngIf="!caseService.details.resolution_date">
                <mat-label>Date Resolution / Denial</mat-label>
                <input matInput [matDatepicker]="resPicker2" [min]="caseService.formatDate(caseService.details.filed_date, 'edit')" formControlName="resolution_date"
                       (dateChange)="updateOther(detailsGroup,'resolution_date')" value=""  (keyup.backspace)="clearField(detailsGroup,'resolution_date')"
                >
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matIconSuffix [for]="resPicker2"></mat-datepicker-toggle>
                <mat-datepicker #resPicker2></mat-datepicker>
              </mat-form-field>
              </section>
            </div>
        </ng-container>
    </section>

    <!-- Litigation: Lawsuit & Tort Details -->
    <section class="case-type" [formGroup]="litigationsGroup" *ngIf="showLitigations">
        <div class="flex-row flex-space-between">
            <h2>Lawsuit & Tort Details</h2>
          <ng-container *ngIf="(currentUserService.canAddUpdateViewOwnCase$ | async) || (currentUserService.canEditCase$ | async) || (currentUserService.canAddEditAllUI$ | async)">
            <button mat-stroked-button *ngIf="!editingLitigations" (click)="setEditStatus('Litigation Details')" color="primary" class="flex-end">
                <mat-icon matIconButton>edit</mat-icon> Edit
            </button>
            <button mat-stroked-button *ngIf="editingLitigations" (click)="setEditStatus('Litigation Details', true); getValues()" color="primary" class="flex-end">
                <mat-icon matIconButton>save</mat-icon> Save
            </button>
          </ng-container>
        </div>
        <hr>
        <ng-container *ngIf="!editingLitigations">
            <mat-tab-group [selectedIndex]="selected.value"
                            (selectedIndexChange)="selected.setValue($event)"
                            mat-align-tabs="start" mat-stretch-tabs="false">
                <mat-tab label="Identifying Info">
                    <section class="margin-top margin-sm-bottom">
                        <h3>Opposing Counsel</h3>
                        <p>
                            <span *ngIf="caseService.litigations.opposing_counsel_law_firm">{{ caseService.litigations.opposing_counsel_law_firm }}</span>
                            <span *ngIf="caseService.litigations.opposing_counsel_law_firm && caseService.litigations.opposing_counsel_attorney">, </span>

                            <span *ngIf="caseService.litigations.opposing_counsel_attorney">{{ caseService.litigations.opposing_counsel_attorney }} <br /></span>
                            <span *ngIf="caseService.litigations.opposing_counsel_law_firm && !caseService.litigations.opposing_counsel_attorney"><br /></span>

                            <span *ngIf="caseService.litigations.opposing_counsel_phone_number">{{ caseService.litigations.opposing_counsel_phone_number }} <br /></span>

                            <span *ngIf="caseService.litigations.opposing_counsel_address">{{ caseService.litigations.opposing_counsel_address }}</span>
                            <span *ngIf="caseService.litigations.opposing_counsel_address && caseService.litigations.opposing_counsel_city || caseService.litigations.opposing_counsel_address && caseService.litigations.opposing_counsel_state">, </span>

                            <span *ngIf="caseService.litigations.opposing_counsel_city">{{ caseService.litigations.opposing_counsel_city }}</span>
                            <span *ngIf="caseService.litigations.opposing_counsel_city && caseService.litigations.opposing_counsel_state">, </span>

                            <span *ngIf="caseService.litigations.opposing_counsel_state">{{ caseService.litigations.opposing_counsel_state }} </span>
                        </p>
                        <p *ngIf="!caseService.litigations.opposing_counsel_attorney && !caseService.litigations.opposing_counsel_phone_number &&
                                !caseService.litigations.opposing_counsel_law_firm && !caseService.litigations.opposing_counsel_city &&
                                !caseService.litigations.opposing_counsel_state && !caseService.litigations.opposing_counsel_address"><i>(none)</i></p>
                    </section>
                    <div class="flex-sm-row margin-sm-bottom">
                        <section class="margin-sm-right width-sm-25">
                            <h3>Plaintiff</h3>
                            <p *ngIf="caseService.litigations.plaintiff">{{ caseService.litigations.plaintiff }}</p>
                            <p *ngIf="!caseService.litigations.plaintiff"><i>(none)</i></p>
                        </section>
                        <section class="margin-sm-left width-sm-25">
                            <h3>Other Plaintiff</h3>
                            <p *ngIf="caseService.litigations.other_plaintiff">{{ caseService.litigations.other_plaintiff }}</p>
                            <p *ngIf="!caseService.litigations.other_plaintiff"><i>(none)</i></p>
                        </section>
                    </div>
                    <div class="flex-sm-row margin-sm-bottom">
                        <section class="margin-sm-right width-sm-25">
                            <h3>Defendant</h3>
                            <p *ngIf="caseService.litigations.defendant">{{ caseService.litigations.defendant }}</p>
                            <p *ngIf="!caseService.litigations.defendant"><i>(none)</i></p>
                        </section>
                        <section class="margin-sm-left width-sm-25">
                            <h3>Other Defendant</h3>
                            <p *ngIf="caseService.litigations.other_defendant">{{ caseService.litigations.other_defendant }}</p>
                            <p *ngIf="!caseService.litigations.other_defendant"><i>(none)</i></p>
                        </section>
                    </div>
                </mat-tab>

                <mat-tab label="Claim Details">
                    <section class="margin-top margin-sm-bottom">
                        <h3>Court Assigned Number</h3>
                        <p *ngIf="caseService.litigations.court_assigned_number">{{ caseService.litigations.court_assigned_number }}</p>
                        <p *ngIf="!caseService.litigations.court_assigned_number"><i>(none)</i></p>
                    </section>
                    <section class="margin-sm-bottom">
                        <h3>Claim Number</h3>
                        <a class="pointer" (click)="previewAdminClaim()" (keyup)="previewAdminClaim()" *ngIf="adminClaimData.claim_number">{{ adminClaimData.claim_number }}</a>
                        <p *ngIf="!adminClaimData.claim_number"><i>(none)</i></p>
                    </section>
                    <div class="flex-sm-row margin-sm-bottom">
                        <section class="margin-sm-right width-sm-25">
                          <h3>Mediation Date</h3>
                          <p *ngIf="caseService.litigations.mediation_date">{{ caseService.formatDate(caseService.litigations.mediation_date) }}</p>
                          <p *ngIf="!caseService.litigations.mediation_date"><i>(none)</i></p>
                        </section>
                        <section class="margin-sm-left width-sm-25">
                          <h3>Trial Date</h3>
                          <p *ngIf="caseService.litigations.trial_date">{{ caseService.formatDate(caseService.litigations.trial_date) }}</p>
                          <p *ngIf="!caseService.litigations.trial_date"><i>(none)</i></p>
                        </section>
                    </div>
                </mat-tab>

                <mat-tab label="Venue / Jurisdiction / Remedy">
                    <div class="margin-top margin-sm-bottom">
                      <section class="margin-sm-right width-sm-25">
                        <h3>Appeal to Court Filed</h3>
                          <p *ngIf="(litigationsGroup.get('is_court_appeal_filed')?.value ?? 0) === 1">Filled</p>
                          <p *ngIf="(litigationsGroup.get('is_court_appeal_filed')?.value ?? 1) === 0">Not Filled</p>
                          <p *ngIf="litigationsGroup.get('is_court_appeal_filed')?.value === ''"><i>(none)</i></p>
                      </section>
                    </div>
                    <div class="grid-2-25-sm-row grid-col-gap-sm margin-sm-bottom">
                        <section class="width-100">
                            <h3>Venue</h3>
                            <p *ngIf="caseService.litigations.venue">{{ caseService.litigations.venue }}</p>
                            <p *ngIf="!caseService.litigations.venue"><i>(none)</i></p>
                        </section>
                        <section class="width-100">
                          <h3>Jurisdiction</h3>
                          <p *ngIf="caseService.litigations.jurisdiction">{{ caseService.litigations.jurisdiction }}</p>
                          <p *ngIf="!caseService.litigations.jurisdiction"><i>(none)</i></p>
                        </section>
                    </div>
                    <div class="grid-2-25-sm-row grid-col-gap-sm margin-sm-bottom">
                        <section class="width-100">
                            <h3>Remedy Sought</h3>
                            <p *ngIf="caseService.litigations.remedy_sought">{{ caseService.litigations.remedy_sought }}</p>
                            <p *ngIf="!caseService.litigations.remedy_sought"><i>(none)</i></p>
                        </section>
                        <section class="width-100">
                            <h3>Remedy Amount</h3>
                            <p *ngIf="caseService.litigations.remedy_amount">{{ caseService.litigations.remedy_amount | currency }}</p>
                            <p *ngIf="!caseService.litigations.remedy_amount"><i>(none)</i></p>
                        </section>
                    </div>
                    <div class="grid-2-25-sm-row grid-col-gap-sm margin-sm-bottom">
                      <section class="width-100">
                        <h3>Settlement Funding Source</h3>
                        <p *ngIf="caseService.litigations.settlement_funding_src">{{ caseService.litigations.settlement_funding_src }}</p>
                        <p *ngIf="!caseService.litigations.settlement_funding_src"><i>(unknown)</i></p>
                      </section>
                      <section class="width-100">
                        <h3>Settlement Amount Paid</h3>
                        <p *ngIf="caseService.litigations.settlement_amount_paid">{{ caseService.litigations.settlement_amount_paid | currency }}</p>
                        <p *ngIf="!caseService.litigations.settlement_amount_paid"><i>(none)</i></p>
                      </section>
                    </div>
                    <div class="flex-sm-row margin-sm-bottom">
                        <section class="margin-sm-right width-sm-25">
                            <h3>Case Disposition</h3>
                            <p *ngIf="caseService.litigations.case_disposition">{{ caseService.litigations.case_disposition }}</p>
                            <p *ngIf="!caseService.litigations.case_disposition"><i>(none)</i></p>
                        </section>
                    </div>
                </mat-tab>

                <mat-tab label="Counsel">
                    <section class="margin-top margin-sm-bottom">
                        <h3>Outside Counsel Required</h3>
                        <p *ngIf="(litigationsGroup.get('is_outside_counsel_required')?.value ?? 0) === 1">Required</p>
                        <p *ngIf="(litigationsGroup.get('is_outside_counsel_required')?.value ?? 1) === 0">Not Required</p>
                        <p *ngIf="litigationsGroup.get('is_outside_counsel_required')?.value === ''"><i>(none)</i></p>
                    </section>
                    <section class="margin-sm-bottom">
                        <h3>Recommended Outside Counsel</h3>
                        <p *ngIf="caseService.litigations.recommended_outside_counsel">{{ caseService.litigations.recommended_outside_counsel }}</p>
                        <p *ngIf="!caseService.litigations.recommended_outside_counsel"><i>(none)</i></p>
                    </section>
                    <section class="margin-sm-bottom">
                        <h3>Justification of Outside Counsel</h3>
                        <p *ngIf="caseService.litigations.outside_counsel_justification">{{ caseService.litigations.outside_counsel_justification }}</p>
                        <p *ngIf="!caseService.litigations.outside_counsel_justification"><i>(none)</i></p>
                    </section>
                    <div class="flex-sm-row margin-sm-bottom">
                        <section class="margin-sm-right width-sm-25">
                            <h3>Outside Counsel Fees Paid/Rate</h3>
                            <p *ngIf="caseService.litigations.outside_counsel_fees">{{ caseService.litigations.outside_counsel_fees }}</p>
                            <p *ngIf="!caseService.litigations.outside_counsel_fees"><i>(none)</i></p>
                        </section>
                        <section class="margin-sm-left width-sm-25">
                            <h3 >Amount Paid to Outside Counsel</h3>
                            <p *ngIf="caseService.litigations.outside_counsel_amount_paid">{{ caseService.litigations.outside_counsel_amount_paid | currency }}</p>
                            <p *ngIf="!caseService.litigations.outside_counsel_amount_paid"><i>(none)</i></p>
                        </section>
                    </div>
                    <div class="flex-sm-row margin-sm-bottom">
                        <section class="margin-sm-right width-sm-25">
                            <h3>In House Attorney</h3>
                            <ul *ngIf="inHouseAttorneys">
                                <li *ngFor="let attorney of inHouseAttorneys"> {{ attorney.name }} </li>
                            </ul>
                            <p *ngIf="inHouseAttorneys?.length === 0"><i>(none)</i></p>
                        </section>
                        <section class="margin-sm-left width-sm-25">
                          <h3>Attorney General Assigned</h3>
                          <p *ngIf="caseService.litigations.attorney_general_names">{{ caseService.litigations.attorney_general_names }}</p>
                          <p *ngIf="!caseService.litigations.attorney_general_names"><i>(none)</i></p>
                          <!-- <ul *ngIf="attorneyGenerals">
                            <li *ngFor="let attorney of attorneyGenerals"> {{ attorney.name }} </li>
                          </ul>
                          <p *ngIf="attorneyGenerals?.length === 0"><i>(none)</i></p> -->
                        </section>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </ng-container>

        <ng-container *ngIf="editingLitigations">
            <mat-tab-group  [selectedIndex]="selected.value"
                            (selectedIndexChange)="selected.setValue($event)"
                            mat-align-tabs="start" mat-stretch-tabs="false">
                <mat-tab label="Identifying Info">
                    <section class="margin-top">
                      <h3 class="margin-sm-bottom">Opposing Counsel</h3>
                      <div class="flex-md-row">
                        <section class="width-md-50 margin-sm-right">
                          <h4 class="offscreen">Law Firm</h4>
                          <mat-form-field class="width-100">
                              <mat-label>Law Firm</mat-label>
                              <input
                                formControlName="opposing_counsel_law_firm"
                              matInput
                              (change)="updateInput(litigationsGroup,'opposing_counsel_law_firm', opposing_counsel_law_firm?.value)"
                              #opposing_counsel_law_firm
                              [value]="caseService.litigations.opposing_counsel_law_firm"
                              />
                          </mat-form-field>
                        </section>
                        <div class="flex-md-row width-md-50">
                          <section class="width-md-50 margin-sm-right">
                            <h4 class="offscreen">Attorney</h4>
                          <mat-form-field class="margin-sm-right width-md-100">
                              <mat-label>Attorney</mat-label>
                              <input
                                formControlName="opposing_counsel_attorney"
                              matInput
                              (change)="updateInput(litigationsGroup,'opposing_counsel_attorney', opposing_counsel_attorney?.value)"
                              #opposing_counsel_attorney
                              [value]="caseService.litigations.opposing_counsel_attorney"
                              />
                          </mat-form-field>
                          </section>

                          <section class="width-md-50">
                            <h4 class="offscreen">Phone Number</h4>
                          <mat-form-field class="width-md-100">
                              <mat-label>Phone Number</mat-label>
                              <input
                              matInput
                              formControlName="opposing_counsel_phone_number"
                              (change)="updateInput(litigationsGroup,'opposing_counsel_phone_number', opposing_counsel_phone_number?.value)"
                              #opposing_counsel_phone_number
                              [value]="caseService.litigations.opposing_counsel_phone_number"
                              appPhone
                              />
                            <mat-error>Invalid phone number</mat-error>
                          </mat-form-field>
                          </section>
                        </div>
                      </div>
                    </section>
                    <section class="flex-md-row">
                      <h3 class="offscreen">Opposing Counsel Address</h3>
                      <section class="margin-sm-right width-md-50">
                        <h4 class="offscreen">Address</h4>
                        <mat-form-field class="width-md-100">
                          <mat-label>Address</mat-label>
                          <input
                            formControlName="opposing_counsel_address"
                            matInput
                            (change)="updateInput(litigationsGroup,'opposing_counsel_address', opposing_counsel_address?.value)"
                            #opposing_counsel_address
                            [value]="caseService.litigations.opposing_counsel_address"
                          />
                        </mat-form-field>
                      </section>
                      <div class="flex-md-row width-md-50">
                        <section class="width-md-50 margin-sm-right">
                          <h4 class="offscreen">City</h4>
                        <mat-form-field class="margin-sm-right width-md-100">
                            <mat-label>City</mat-label>
                            <input
                              formControlName="opposing_counsel_city"
                            matInput
                            (change)="updateInput(litigationsGroup,'opposing_counsel_city', opposing_counsel_city?.value)"
                            #opposing_counsel_city
                            [value]="caseService.litigations.opposing_counsel_city"
                            />
                        </mat-form-field>
                        </section>
                        <section class="width-md-50">
                          <h4 class="offscreen">State</h4>
                        <mat-form-field class="width-md-100">
                            <mat-label>State</mat-label>
                            <input
                              formControlName="opposing_counsel_state"
                            matInput
                            (change)="updateInput(litigationsGroup,'opposing_counsel_state', opposing_counsel_state?.value)"
                            #opposing_counsel_state
                            [value]="caseService.litigations.opposing_counsel_state"
                            />
                        </mat-form-field>
                        </section>
                      </div>
                    </section>
                    <section class="flex-md-row">
                      <h3 class="offscreen">Plaintiffs</h3>
                        <section class="margin-sm-right width-md-50">
                          <h4 class="offscreen">Plaintiff</h4>
                          <mat-form-field class="width-100">
                            <mat-label>Plaintiff</mat-label>
                            <input
                              matInput
                              (change)="updateInput(litigationsGroup,'plaintiff', plaintiff?.value)"
                              #plaintiff
                              [value]="caseService.litigations.plaintiff"
                              formControlName="plaintiff"
                            />
                          </mat-form-field>
                        </section>
                        <section class="width-md-50">
                          <h4 class="offscreen">Other Plaintiff</h4>
                            <mat-form-field class="width-100">
                                <mat-label>Other Plaintiff</mat-label>
                                <input
                                  matInput
                                  (change)="updateInput(litigationsGroup,'other_plaintiff', other_plaintiff?.value)"
                                  #other_plaintiff
                                  [value]="caseService.litigations.other_plaintiff"
                                  formControlName="other_plaintiff"
                                />
                            </mat-form-field>
                        </section>
                    </section>
                    <section class="flex-md-row">
                      <h3 class="offscreen">Defendants</h3>
                        <section class="margin-sm-right width-md-50">
                          <h4 class="offscreen">Defendant</h4>
                            <mat-form-field class="width-100">
                                <mat-label>Defendant</mat-label>
                                <mat-select [value]="caseService.litigations.defendant"
                                            formControlName="defendant"
                                            (keyup.enter)="updateInput(litigationsGroup,'defendant', defendant.value)"
                                            #defendant>
                                    <mat-option
                                      (click)="updateInput(litigationsGroup,'defendant', '')"
                                      [value]="">
                                    </mat-option>
                                    <mat-option *ngFor="let data of listDataService.institutions$.value" [value]="data.name"
                                                (click)="updateInput(litigationsGroup,'defendant', data.name)">
                                    {{ data.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </section>
                        <section class="width-md-50">
                          <h4 class="offscreen">Other Defendant</h4>
                            <mat-form-field class="width-100">
                                <mat-label>Other Defendant</mat-label>
                                <input
                                  formControlName="other_defendant"
                                  matInput
                                  (change)="updateInput(litigationsGroup,'other_defendant', other_defendant?.value)"
                                  #other_defendant
                                  [value]="caseService.litigations.other_defendant"
                                />
                            </mat-form-field>
                        </section>
                    </section>
                </mat-tab>

                <mat-tab label="Claim Details">
                    <section class="margin-top flex-md-row">
                      <h3 class="offscreen">Court Assigned Number</h3>
                        <mat-form-field class="width-50">
                            <mat-label>Court Assigned Number</mat-label>
                            <input
                              matInput
                              (change)="updateInput(litigationsGroup,'court_assigned_number', court_assigned_number?.value)"
                              #court_assigned_number
                              [value]="caseService.litigations.court_assigned_number"
                              formControlName="court_assigned_number"
                            />
                        </mat-form-field>
                    </section>
                    <section class="flex-md-row">
                      <h3 class="offscreen">Claim Number</h3>
                      <div class="margin-sm-right width-md-25">
                          <mat-form-field class="width-md-100">
                              <mat-label>Claim Number</mat-label>
                              <mat-select [value]="caseService.litigations.fk_admin_claim_id" formControlName="fk_admin_claim_id"
                                          (keyup.enter)="updateInput(litigationsGroup,'fk_admin_claim_id', claim_number.value); setAdminClaimData(claim_number)"
                                          #claim_number>
                                <mat-option
                                  (click)="updateInput(litigationsGroup,'fk_admin_claim_id', ''); setAdminClaimData(blankAdminClaimData)"
                                  [value]="">
                                </mat-option>
                                  <mat-option *ngFor="let data of listDataService.claimNumbers$?.value"
                                      [value]="data.pk_administrative_claim_id"
                                      (click)="updateInput(litigationsGroup,'fk_admin_claim_id', data.pk_administrative_claim_id); setAdminClaimData(data)">
                                    <mat-list>
                                      <mat-list-item class="pointer">
                                        <span matListItemTitle>{{ data.claim_number }} </span>
                                        <span matListItemLine class="option-ellipsis">{{ data.case_title }}</span>
                                      </mat-list-item>
                                    </mat-list>
                                  </mat-option>
                              </mat-select>
                          </mat-form-field>
                        </div>

                        <div class="width-md-25">
                          <p *ngIf="adminClaimData.claim_number"><button mat-button color="primary" (click)="previewAdminClaim()" (keyup)="previewAdminClaim()">Preview Claim</button></p>
                        </div>
                    </section>

                    <div class="flex-md-row">
                      <div class="flex-md-row width-md-50">
                        <section class="width-md-50 margin-sm-right">
                          <h3 class="offscreen">Mediation Date</h3>
                          <mat-form-field class="width-100" *ngIf="caseService.litigations.mediation_date">
                            <mat-label>Mediation Date</mat-label>
                            <input matInput [matDatepicker]="mediationDate" formControlName="mediation_date"
                                   (dateChange)="updateOther(litigationsGroup,'mediation_date')"
                                   [value]="caseService.formatDate(caseService.litigations.mediation_date, 'edit')"
                                   (keyup.backspace)="clearField(litigationsGroup,'mediation_date')"
                            >
                            <mat-hint>MM/DD/YYYY</mat-hint>
                            <mat-datepicker-toggle matIconSuffix [for]="mediationDate"></mat-datepicker-toggle>
                            <mat-datepicker #mediationDate></mat-datepicker>
                          </mat-form-field>
                          <mat-form-field class="width-100" *ngIf="!caseService.litigations.mediation_date">
                            <mat-label>Mediation Date</mat-label>
                            <input matInput [matDatepicker]="mediationDate" formControlName="mediation_date"
                                   (dateChange)="updateOther(litigationsGroup,'mediation_date')"
                                   [value]=""
                                   (keyup.backspace)="clearField(litigationsGroup,'mediation_date')"
                            >
                            <mat-hint>MM/DD/YYYY</mat-hint>
                            <mat-datepicker-toggle matIconSuffix [for]="mediationDate"></mat-datepicker-toggle>
                            <mat-datepicker #mediationDate></mat-datepicker>
                          </mat-form-field>
                        </section>

                        <section class="width-md-50">
                          <h3 class="offscreen">Trial Date</h3>
                          <mat-form-field class="width-100" *ngIf="caseService.litigations.trial_date">
                            <mat-label>Trial Date</mat-label>
                            <input matInput [matDatepicker]="trialDate" formControlName="trial_date"
                                   (dateChange)="updateOther(litigationsGroup,'trial_date')"
                                   [value]="caseService.formatDate(caseService.litigations.trial_date, 'edit')"
                                   (keyup.backspace)="clearField(litigationsGroup,'trial_date')"
                            >
                            <mat-hint>MM/DD/YYYY</mat-hint>
                            <mat-datepicker-toggle matIconSuffix [for]="trialDate"></mat-datepicker-toggle>
                            <mat-datepicker #trialDate></mat-datepicker>
                          </mat-form-field>
                          <mat-form-field class="width-100" *ngIf="!caseService.litigations.trial_date">
                            <mat-label>Trial Date</mat-label>
                            <input matInput [matDatepicker]="trialDate" formControlName="trial_date"
                                   (dateChange)="updateOther(litigationsGroup,'trial_date')"
                                   [value]=""
                                   (keyup.backspace)="clearField(litigationsGroup,'trial_date')"
                            >
                            <mat-hint>MM/DD/YYYY</mat-hint>
                            <mat-datepicker-toggle matIconSuffix [for]="trialDate"></mat-datepicker-toggle>
                            <mat-datepicker #trialDate></mat-datepicker>
                          </mat-form-field>
                        </section>
                      </div>
                    </div>
                </mat-tab>

                <mat-tab label="Venue / Jurisdiction / Remedy">
                    <section class="flex-md-row margin-top margin-sm-bottom">
                      <h3 class="offscreen">Appeal to Court Filed</h3>
                      <mat-checkbox
                        formControlName="is_court_appeal_filed"
                        [checked]="(litigationsGroup.get('is_court_appeal_filed')?.value ?? 0) === 1"
                        (change)="updateCb(litigationsGroup,'is_court_appeal_filed', $event)"
                        color="primary"
                      >
                        Appeal to Court Filed
                      </mat-checkbox>
                    </section>
                    <div class="grid-2-50-row grid-col-gap-sm">
                        <section class="width-100">
                          <h3 class="offscreen">Venue</h3>
                            <mat-form-field class="width-100">
                                <mat-label>Venue</mat-label>
                                <input
                                  matInput
                                  (change)="updateInput(litigationsGroup,'venue', venue?.value)"
                                  #venue
                                  [value]="caseService.litigations.venue"
                                  formControlName="venue"
                                />
                            </mat-form-field>
                        </section>
                        <section class="width-100">
                          <h3>Jurisdiction</h3>
                          <mat-radio-group formControlName="jurisdiction">
                            <mat-radio-button
                              color="primary"
                              *ngFor="let jurisdiction of jurisdictions"
                              [value]="jurisdiction"
                              (change)="updateInput(litigationsGroup,'jurisdiction', jurisdiction)"
                              [checked]="(litigationsGroup.get('jurisdiction')?.value ?? '') === jurisdiction"
                            >
                              {{ jurisdiction }}
                            </mat-radio-button>
                          </mat-radio-group>
                        </section>
                    </div>
                    <div class="grid-2-50-row grid-col-gap-sm">
                        <section class="width-100">
                          <h3 class="offscreen">Remedy Sought</h3>
                            <mat-form-field class="width-100">
                                <mat-label>Remedy Sought</mat-label>
                                <input
                                  matInput
                                  (change)="updateInput(litigationsGroup,'remedy_sought', remedy_sought?.value)"
                                  #remedy_sought
                                  [value]="caseService.litigations.remedy_sought"
                                  formControlName="remedy_sought"
                                />
                            </mat-form-field>
                        </section>
                        <section class="width-md-50">
                          <h3 class="offscreen">Remedy Amount</h3>
                            <mat-form-field class="width-100" floatLabel="always">
                                <mat-label>Remedy Amount</mat-label>
                                <input
                                  matInput
                                  (change)="updateInput(litigationsGroup,'remedy_amount', remedy_amount?.value)"
                                  #remedy_amount
                                  value="parseFloat(caseService.litigations.remedy_amount).toFixed(2)"
                                  type="number"
                                  placeholder="0"
                                  class="currency-input"
                                  formControlName="remedy_amount"
                                />
                                <span matTextPrefix>$&nbsp;</span>
                            </mat-form-field>
                        </section>
                    </div>
                    <div class="grid-2-50-row grid-col-gap-sm">
                      <section class="width-100">
                        <h3 class="offscreen">Settlement Funding Source</h3>
                        <mat-form-field class="width-100">
                          <mat-label>Settlement Funding Source</mat-label>
                          <input
                            matInput
                            #settlement_funding_src
                            (change)="updateInput(litigationsGroup, 'settlement_funding_src', settlement_funding_src?.value)"
                            formControlName="settlement_funding_src"
                            [value]="caseService.litigations.settlement_funding_src"
                            type="text"
                          />
                        </mat-form-field>
                      </section>
                      <section class="width-md-50">
                        <h3 class="offscreen">Settlement Amount</h3>
                          <mat-form-field class="width-100" floatLabel="always">
                              <mat-label>Settlement Amount</mat-label>
                              <input
                                matInput
                                (change)="updateInput(litigationsGroup,'settlement_amount_paid', settlement_amount_paid?.value)"
                                #settlement_amount_paid
                                [value]="caseService.litigations.settlement_amount_paid"
                                type="number"
                                placeholder="0"
                                class="currency-input"
                                formControlName="settlement_amount_paid"
                              />
                              <span matTextPrefix>$&nbsp;</span>
                          </mat-form-field>
                      </section>
                    </div>
                    <div class="grid-2-50-row grid-col-gap-sm">
                      <section class="width-100">
                        <h3 class="offscreen">Case Disposition</h3>
                        <mat-form-field class="width-100">
                          <mat-label>Case Disposition</mat-label>
                          <textarea
                            matInput
                            (change)="updateInput(litigationsGroup,'case_disposition', case_disposition?.value)"
                            #case_disposition
                            [value]="caseService.litigations.case_disposition"
                            formControlName="case_disposition">
                              </textarea>
                        </mat-form-field>
                      </section>
                    </div>
                </mat-tab>

                <mat-tab label="Counsel">
                    <section class="margin-top margin-sm-bottom">
                      <h3 class="offscreen">Outside Counsel Required</h3>
                        <mat-checkbox
                            [checked]="(litigationsGroup.get('is_outside_counsel_required')?.value ?? 0) === 1"
                            (change)="updateCb(litigationsGroup,'is_outside_counsel_required', $event)"
                            color="primary"
                            formControlName="is_outside_counsel_required"
                        >
                            Outside Counsel Required
                        </mat-checkbox>
                    </section>
                    <section class="margin-sm-bottom">
                      <h3 class="offscreen">Recommended Outside Counsel</h3>
                      <mat-form-field class="width-100">
                          <mat-label>Recommended Outside Counsel</mat-label>
                          <input
                            matInput
                            (change)="updateInput(litigationsGroup,'recommended_outside_counsel', recommended_outside_counsel?.value)"
                            #recommended_outside_counsel
                            [value]="caseService.litigations.recommended_outside_counsel"
                            formControlName="recommended_outside_counsel"
                          />
                      </mat-form-field>
                    </section>
                    <section class="margin-sm-bottom">
                      <h3 class="offscreen">Justification of Outside Counsel</h3>
                      <mat-form-field class="width-100">
                          <mat-label>Justification of Outside Counsel</mat-label>
                          <textarea
                            matInput
                            (change)="updateInput(litigationsGroup,'outside_counsel_justification', outside_counsel_justification?.value)"
                            #outside_counsel_justification
                            [value]="caseService.litigations.outside_counsel_justification"
                            formControlName="outside_counsel_justification">
                          </textarea>
                      </mat-form-field>
                    </section>
                    <section class="flex-sm-row">
                      <h3 class="offscreen">Outside Counsel Fees</h3>
                        <section class="margin-sm-right width-md-50">
                          <h4 class="offscreen">Outside Counsel Fees Paid/Rate</h4>
                            <mat-form-field class="width-100">
                                <mat-label>Outside Counsel Fees Paid/Rate</mat-label>
                                <input
                                  matInput
                                  (change)="updateInput(litigationsGroup,'outside_counsel_fees', outside_counsel_fees?.value)"
                                  #outside_counsel_fees
                                  [value]="caseService.litigations.outside_counsel_fees"
                                  formControlName="outside_counsel_fees"
                                />
                            </mat-form-field>
                        </section>
                        <section class="width-md-50">
                          <h4 class="offscreen">Amount Paid to Outside Counsel</h4>
                            <mat-form-field class="width-50" floatLabel="always">
                                <mat-label>Amount Paid to Outside Counsel</mat-label>
                                <input
                                  matInput
                                  (change)="updateInput(litigationsGroup,'outside_counsel_amount_paid', outside_counsel_amount_paid?.value)"
                                  #outside_counsel_amount_paid
                                  [value]="caseService.litigations.outside_counsel_amount_paid"
                                  type="number"
                                  placeholder="0"
                                  class="currency-input"
                                  formControlName="outside_counsel_amount_paid"
                                />
                                <span matTextPrefix>$&nbsp;</span>
                            </mat-form-field>
                        </section>
                    </section>
                    <section class="flex-sm-row">
                      <h3 class="offscreen">Attorneys</h3>
                        <section class="margin-sm-right width-md-50" [formGroup]="detailsGroup">
                          <h4 class="offscreen">In House Attorney(s)</h4>
                          <mat-form-field class="width-100">
                            <mat-label>In House Attorney(s)</mat-label>
                            <mat-select multiple [value]="caseService.details.in_house_attorneys" formControlName="in_house_attorneys"
                                        (keyup.enter)="updateOther(detailsGroup,'in_house_attorneys', in_house_attorneys.value)"
                            #in_house_attorneys>
                              <mat-option *ngFor="let data of listDataService.attorneys$?.value" [value]="data.value"
                                          (click)="updateOther(detailsGroup,'in_house_attorneys', data.value)">
                                {{ data.name }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </section>
                        <section class="width-sm-50">
                          <h4 class="offscreen">Attorney General Assigned</h4>
                            <mat-form-field class="width-50">
                                <mat-label>Attorney General Assigned</mat-label>
                                <input
                                matInput
                                (change)="updateInput(litigationsGroup, 'attorney_general_names', attorney_general_names?.value)"
                                #attorney_general_names
                                [value]="caseService.litigations.attorney_general_names"
                                formControlName="attorney_general_names"
                                />
                                <!-- <mat-select multiple [value]="caseService.details.attorney_generals" formControlName="attorney_generals"
                                            (keyup.enter)="updateOther(detailsGroup,'attorney_generals', attorney_generals.value)"
                                #attorney_generals>
                                  <mat-option *ngFor="let data of listDataService.attorneyGenerals$?.value" [value]="data.value"
                                              (click)="updateOther(detailsGroup,'attorney_generals', data.value)">
                                    {{ data.name }}
                                  </mat-option>
                                </mat-select> -->
                            </mat-form-field>
                        </section>
                    </section>
                </mat-tab>
            </mat-tab-group>
        </ng-container>
    </section>

    <!-- Grievance Details -->
    <section class="case-type" [formGroup]="grievancesGroup" *ngIf="showGrievances">
        <div class="flex-row flex-space-between">
            <h2>Grievance Details</h2>
          <ng-container *ngIf="(currentUserService.canAddUpdateViewOwnCase$ | async) || (currentUserService.canEditCase$ | async) || (currentUserService.canAddEditAllUI$ | async)">
            <button mat-stroked-button *ngIf="!editingGrievances" (click)="setEditStatus('Grievance Details')" color="primary" class="flex-end">
                <mat-icon matIconButton>edit</mat-icon> Edit
            </button>
            <button mat-stroked-button *ngIf="editingGrievances" (click)="setEditStatus('Grievance Details', true)" color="primary" class="flex-end">
                <mat-icon matIconButton>save</mat-icon> Save
            </button>
          </ng-container>
        </div>
        <hr>
        <ng-container *ngIf="!editingGrievances">
            <mat-tab-group [selectedIndex]="selected.value"
                            (selectedIndexChange)="selected.setValue($event)"
                            mat-align-tabs="start" mat-stretch-tabs="false">
                <mat-tab label="Identifying Info">
                    <div class="flex-sm-row margin-top margin-sm-bottom">
                        <section class="margin-sm-right width-md-50">
                            <h3>Grievant Number</h3>
                            <p *ngIf="caseService.grievances.grievant_number">{{caseService.grievances.grievant_number}}</p>
                            <p *ngIf="!caseService.grievances.grievant_number"><i>(none)</i></p>
                        </section>
                        <section class="margin-sm-left width-md-50">
                            <h3>Grievant</h3>
                            <p *ngIf="caseService.grievances.grievant_name">{{caseService.grievances.grievant_name}}</p>
                            <p *ngIf="!caseService.grievances.grievant_name"><i>(none)</i></p>
                        </section>
                    </div>
                    <div class="flex-sm-row margin-sm-bottom">
                        <section class="margin-sm-right width-md-50">
                            <h3>Grievant Associated with Institution</h3>
                            <p><i>(Person who is filing the claim)</i></p>
                            <p *ngIf="caseService.grievances.association">{{ caseService.grievances.association }}</p>
                            <p *ngIf="!caseService.grievances.association"><i>(none)</i></p>
                        </section>
                        <section class="margin-sm-left width-md-50">
                            <h3>Bargaining Unit</h3>
                            <p *ngIf="caseService.grievances.bargaining_unit">{{ caseService.grievances.bargaining_unit }}</p>
                            <p *ngIf="!caseService.grievances.bargaining_unit"><i>(none)</i></p>
                        </section>
                    </div>
                    <div class="flex-sm-row margin-sm-bottom">
                        <section class="margin-sm-right width-md-50">
                            <h3>Article & Section of Alleged Violation</h3>
                            <p *ngIf="caseService.grievances.violation_article_section">{{ caseService.grievances.violation_article_section }}</p>
                            <p *ngIf="!caseService.grievances.violation_article_section"><i>(none)</i></p>
                        </section>
                        <section class="margin-sm-left width-md-50">
                            <h3>3rd Step Description</h3>
                            <p *ngIf="caseService.grievances.third_step_description">{{ caseService.grievances.third_step_description }}</p>
                            <p *ngIf="!caseService.grievances.third_step_description"><i>(none)</i></p>
                        </section>
                    </div>
                </mat-tab>

                <mat-tab label="Claim Details">
                    <div class="flex-sm-row margin-top margin-sm-bottom">
                      <div class="flex-md-row margin-sm-right width-sm-50">
                        <section class="width-md-50 margin-sm-bottom">
                          <h3>
                            Appeal to Court Filed
                          </h3>
                          <p *ngIf="(grievancesGroup.get('is_court_appeal_filed')?.value ?? 0) === 1">Yes</p>
                          <p *ngIf="(grievancesGroup.get('is_court_appeal_filed')?.value ?? 1) === 0">No</p>
                          <p *ngIf="grievancesGroup.get('is_court_appeal_filed')?.value === ''"><i>(none)</i></p>
                        </section>
                        <section class="width-md-50">
                          <h3>Appeal to Arbitration</h3>
                          <p *ngIf="(grievancesGroup.get('is_arbitration_appeal')?.value ?? 0) === 1">Yes</p>
                          <p *ngIf="(grievancesGroup.get('is_arbitration_appeal')?.value ?? 1) === 0">No</p>
                          <p *ngIf="grievancesGroup.get('is_arbitration_appeal')?.value === ''"><i>(none)</i></p>
                        </section>
                      </div>
                      <section class="width-md-50">
                        <h3>
                            Appeal to BOR
                        </h3>
                        <p *ngIf="(grievancesGroup.get('is_bor_appeal')?.value ?? 0) === 1">Yes</p>
                        <p *ngIf="(grievancesGroup.get('is_bor_appeal')?.value ?? 1) === 0">No</p>
                        <p *ngIf="grievancesGroup.get('is_bor_appeal')?.value === ''"><i>(none)</i></p>
                      </section>
                    </div>
                    <div class="margin-sm-right flex-sm-row margin-sm-bottom">
                      <section class="margin-sm-right width-md-50 margin-sm-bottom">
                        <h3>Assessment of Arbitrator</h3>
                        <p *ngIf="caseService.grievances.arbitration_assessment">{{ caseService.grievances.arbitration_assessment }}</p>
                        <p *ngIf="!caseService.grievances.arbitration_assessment"><i>(none)</i></p>
                      </section>
                      <section class="width-md-50 margin-sm-left">
                        <h3>BOR Decision</h3>
                        <p *ngIf="caseService.grievances.bor_decision">{{ caseService.grievances.bor_decision }}</p>
                        <p *ngIf="!caseService.grievances.bor_decision"><i>(none)</i></p>
                      </section>
                    </div>
                    <div class="margin-sm-right flex-sm-row margin-sm-bottom">
                      <section class="margin-sm-right width-md-50 margin-sm-bottom">
                        <h3>Settlement Amount</h3>
                        <p *ngIf="caseService.grievances.settlement_amount">{{ caseService.grievances.settlement_amount | currency }}</p>
                        <p *ngIf="!caseService.grievances.settlement_amount"><i>(none)</i></p>
                      </section>
                      <section class="width-md-50 margin-sm-left">
                        <h3>Settlement Funding Source</h3>
                        <p *ngIf="caseService.grievances.settlement_funding_src">{{ caseService.grievances.settlement_funding_src}}</p>
                        <p *ngIf="!caseService.grievances.settlement_funding_src"><i>(unknown)</i></p>
                      </section>
                    </div>
<!--                    <div class="flex-sm-row margin-sm-bottom">-->
<!--                      <section class="margin-sm-right width-md-50">-->
<!--                        <h3>Arbitration Assignment</h3>-->
<!--                        <p *ngIf="caseService.grievances.arbitration_assignment">{{ caseService.grievances.arbitration_assignment }}</p>-->
<!--                        <p *ngIf="!caseService.grievances.arbitration_assignment"><i>(none)</i></p>-->
<!--                      </section>-->
<!--                      <div class="margin-sm-left width-md-50">-->
<!--                      </div>-->
<!--                    </div>-->
                </mat-tab>
            </mat-tab-group>
        </ng-container>

        <ng-container *ngIf="editingGrievances">
            <mat-tab-group  [selectedIndex]="selected.value"
                            (selectedIndexChange)="selected.setValue($event)"
                            mat-align-tabs="start" mat-stretch-tabs="false">
                <mat-tab label="Identifying Info">
                    <div class="margin-top flex-md-row">
                      <section class="margin-sm-right width-md-50">
                        <h3 class="offscreen">Grievant Number</h3>
                        <mat-form-field class="width-md-100">
                          <mat-label>Grievant Number</mat-label>
                          <input
                            matInput
                            (change)="updateInput(grievancesGroup,'grievant_number', grievant_number?.value)"
                            #grievant_number
                            [value]="caseService.grievances.grievant_number"
                            formControlName="grievant_number"
                          />
                        </mat-form-field>
                      </section>
                      <section class="width-md-50">
                        <h3 class="offscreen">Grievant</h3>
                        <mat-form-field class="width-100">
                          <mat-label>Grievant</mat-label>
                          <input
                            matInput
                            (change)="updateInput(grievancesGroup,'grievant_name', grievant_name?.value)"
                            #grievant_name
                            [value]="caseService.grievances.grievant_name"
                            formControlName="grievant_name"
                          />
                        </mat-form-field>
                      </section>
                    </div>
                    <div class="flex-md-row">
                        <section class="margin-sm-right width-md-50">
                          <h3 class="offscreen">Grievant Associated with Institution</h3>
                          <mat-form-field class="width-100">
                            <mat-label>Grievant Associated with Institution</mat-label>
                            <mat-select [value]="caseService.grievances.association" formControlName="association"
                                        (keyup.enter)="updateInput(grievancesGroup,'association', association.value)"
                            #association>
                              <mat-option
                                (click)="updateInput(grievancesGroup,'association', '')"
                                [value]="">
                              </mat-option>
                              <mat-option *ngFor="let data of listDataService.grievantAssoc$?.value" [value]="data"
                                          (click)="updateInput(grievancesGroup,'association', data)"
                              >
                                {{ data }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </section>
                        <section class="width-md-50">
                          <h3 class="offscreen">Bargaining Unit</h3>
                          <mat-form-field class="width-100">
                            <mat-label>Bargaining Unit</mat-label>
                            <mat-select [value]="caseService.grievances.bargaining_unit" formControlName="bargaining_unit"
                                        (keyup.enter)="updateInput(grievancesGroup,'bargaining_unit', bargaining_unit.value)"
                            #bargaining_unit>
                              <mat-option
                                (click)="updateInput(grievancesGroup,'bargaining_unit', '')"
                                [value]="">
                              </mat-option>
                              <mat-option *ngFor="let data of listDataService.bargainingUnit$?.value" [value]="data"
                                          (click)="updateInput(grievancesGroup,'bargaining_unit', data)"
                              >
                                {{ data }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </section>
                    </div>
                    <div class="flex-md-row">
                      <section class="margin-sm-right width-md-50">
                        <h3 class="offscreen">Article & Section of Alleged Violation</h3>
                        <mat-form-field class="width-100">
                          <mat-label>Article & Section of Alleged Violation</mat-label>
                          <textarea
                            matInput
                            (change)="updateInput(grievancesGroup,'violation_article_section', violation_article_section?.value)"
                            #violation_article_section
                            [value]="caseService.grievances.violation_article_section" formControlName="violation_article_section">
                          </textarea>
                        </mat-form-field>
                      </section>
                      <section class="width-md-50">
                        <h3 class="offscreen">3rd Step Description</h3>
                        <mat-form-field class="width-100">
                          <mat-label>3rd Step Description</mat-label>
                          <textarea
                            matInput
                            (change)="updateInput(grievancesGroup,'third_step_description', third_step_description?.value)"
                            #third_step_description
                            [value]="caseService.grievances.third_step_description" formControlName="third_step_description">
                          </textarea>
                        </mat-form-field>
                      </section>
                    </div>
                </mat-tab>

                <mat-tab label="Claim Details">
                  <div class="margin-top margin-sm-bottom flex-md-row">
                    <div class="margin-sm-right width-md-50 flex-md-row">
                      <section class="width-md-50">
                        <h3 class="offscreen">Appeal to Court Filed</h3>
                      <mat-checkbox
                        [checked]="(grievancesGroup.get('is_court_appeal_filed')?.value ?? 0) === 1"
                        (change)="updateCb(grievancesGroup,'is_court_appeal_filed', $event)"
                        color="primary"
                        formControlName="is_court_appeal_filed"
                      >
                        Appeal to Court Filed
                      </mat-checkbox>
                      </section>
                      <section class="margin-sm-right width-md-50">
                        <h3 class="offscreen">Appeal to Arbitration</h3>
                      <mat-checkbox
                        [checked]="(grievancesGroup.get('is_arbitration_appeal')?.value ?? 0) === 1"
                        (change)="updateCb(grievancesGroup,'is_arbitration_appeal', $event)"
                        color="primary"
                        formControlName="is_arbitration_appeal"
                      >
                        Appeal to Arbitration
                      </mat-checkbox>
                      </section>
                    </div>
                    <section class="width-md-50">
                      <h3 class="offscreen">Appeal to BOR</h3>
                      <mat-checkbox
                        [checked]="(grievancesGroup.get('is_bor_appeal')?.value ?? 0) === 1"
                        (change)="updateCb(grievancesGroup,'is_bor_appeal', $event)"
                        color="primary"
                        formControlName="is_bor_appeal"
                      >
                        Appeal to BOR
                      </mat-checkbox>
                    </section>
                  </div>
                  <div class="flex-md-row">
                    <section class="margin-sm-right width-md-50">
                      <h3 class="offscreen">Assessment of Arbitrator</h3>
                      <mat-form-field class="width-md-100">
                        <mat-label>Assessment of Arbitrator</mat-label>
                        <textarea
                          matInput
                          (change)="updateInput(grievancesGroup,'arbitration_assessment', arbitration_assessment?.value)"
                          #arbitration_assessment
                          [value]="caseService.grievances.arbitration_assessment"
                          formControlName="arbitration_assessment"
                        ></textarea>
                      </mat-form-field>
                    </section>
                    <section class="width-md-50">
                      <h3 class="offscreen">BOR Decision</h3>
                      <mat-form-field class="width-md-100">
                        <mat-label>BOR Decision</mat-label>
                        <textarea
                          matInput
                          (change)="updateInput(grievancesGroup,'bor_decision', bor_decision?.value)"
                          #bor_decision
                          [value]="caseService.grievances.bor_decision"
                          formControlName="bor_decision"
                        ></textarea>
                      </mat-form-field>
                    </section>
                  </div>
                  <div class="flex-md-row">
                    <section class="margin-sm-right width-md-50">
                      <h3 class="offscreen">Settlement Amount</h3>
                      <mat-form-field class="width-md-100" floatLabel="always">
                        <mat-label>Settlement Amount</mat-label>
                        <input
                          matInput
                          (change)="updateInput(grievancesGroup,'settlement_amount', settlement_amount?.value)"
                          #settlement_amount
                          type="number"
                          placeholder="0"
                          class="currency-input"
                          formControlName="settlement_amount"
                          [value]="caseService.grievances.settlement_amount"
                        />
                        <span matTextPrefix>$&nbsp;</span>
                      </mat-form-field>
                    </section>
                    <section class="width-md-50">
                      <h3 class="offscreen">Settlement Funding Source</h3>
                      <mat-form-field class="width-md-100">
                        <mat-label>Settlement Funding Source</mat-label>
                        <input
                          matInput
                          #settlement_funding_src
                          (change)="updateInput(grievancesGroup,'settlement_funding_src', settlement_funding_src?.value)"
                          formControlName="settlement_funding_src"
                          [value]="caseService.grievances.settlement_funding_src"
                          type="text"
                        />
                      </mat-form-field>
                    </section>
                  </div>
<!--                  <div class="flex-md-row">-->
<!--                    <section class="margin-sm-right width-md-50">-->
<!--                      <h3 class="offscreen">Arbitration Assignment</h3>-->
<!--                      <mat-form-field class="width-md-100">-->
<!--                      <mat-label>Arbitration Assignment</mat-label>-->
<!--                        <input-->
<!--                          matInput-->
<!--                          (change)="updateInput(grievancesGroup,'arbitration_assignment', arbitration_assignment?.value)"-->
<!--                          #arbitration_assignment-->
<!--                          [value]="caseService.grievances.arbitration_assignment"-->
<!--                          formControlName="arbitration_assignment"-->
<!--                        />-->
<!--                      </mat-form-field>-->
<!--                    </section>-->
<!--                    <div class="width-md-50">-->
<!--                    </div>-->
<!--                  </div>-->
                </mat-tab>
            </mat-tab-group>
        </ng-container>
    </section>

    <!-- Claim Details -->
    <section class="case-type" [formGroup]="administrativeClaimsGroup" *ngIf="showAdministrativeClaims">
        <div class="flex-row flex-space-between">
            <h2>Claim Details</h2>
          <ng-container *ngIf="(currentUserService.canAddUpdateViewOwnCase$ | async) || (currentUserService.canEditCase$ | async) || (currentUserService.canAddEditAllUI$ | async)">
            <button mat-stroked-button *ngIf="!editingAdministrativeClaims" (click)="setEditStatus('Administrative Claims Details')" color="primary" class="flex-end">
                <mat-icon matIconButton>edit</mat-icon> Edit
            </button>
            <button mat-stroked-button *ngIf="editingAdministrativeClaims" (click)="setEditStatus('Administrative Claims Details', true); setAgencyName()" color="primary" class="flex-end">
                <mat-icon matIconButton>save</mat-icon> Save
            </button>
          </ng-container>
        </div>
        <hr>
        <ng-container *ngIf="!editingAdministrativeClaims">
            <mat-tab-group [selectedIndex]="selected.value"
                            (selectedIndexChange)="selected.setValue($event)"
                            mat-align-tabs="start" mat-stretch-tabs="false">
                <mat-tab label="Identifying Info">
                  <div class="margin-top grid-3-25-sm-row grid-col-gap-sm margin-sm-bottom">
                    <section class="width-100">
                      <h3>Date Claim Submitted</h3>
                      <p *ngIf="caseService.administrativeClaims.claim_submitted_date">{{ caseService.formatDate(caseService.administrativeClaims.claim_submitted_date)  }}</p>
                      <p *ngIf="!caseService.administrativeClaims.claim_submitted_date"><i>(none)</i></p>
                    </section>
                    <section class="width-100">
                        <h3>Agency</h3>
                        <p *ngIf="agency">{{ agency }}</p>
                        <p *ngIf="!agency"><i>(none)</i></p>
                    </section>
                    <section class="width-100">
                        <h3>Claim Number</h3>
                        <p *ngIf="caseService.administrativeClaims.claim_number">{{ caseService.administrativeClaims.claim_number }}</p>
                        <p *ngIf="!caseService.administrativeClaims.claim_number"><i>(none)</i></p>
                    </section>
                  </div>
                  <div class="grid-2-25-50-sm-row grid-col-gap-sm margin-sm-bottom">
                    <section class="width-100">
                      <h3>Settlement Amount</h3>
                      <p *ngIf="caseService.administrativeClaims.settlement_amount">{{ caseService.administrativeClaims.settlement_amount | currency }}</p>
                      <p *ngIf="!caseService.administrativeClaims.settlement_amount"><i>(none)</i></p>
                    </section>
                    <section class="width-100">
                      <h3>Settlement Funding Source</h3>
                      <p *ngIf="caseService.administrativeClaims.settlement_funding_src">{{ caseService.administrativeClaims.settlement_funding_src}}</p>
                      <p *ngIf="!caseService.administrativeClaims.settlement_funding_src"><i>(unknown)</i></p>
                    </section>
                  </div>
                </mat-tab>
            </mat-tab-group>
        </ng-container>

        <ng-container *ngIf="editingAdministrativeClaims">
            <mat-tab-group  [selectedIndex]="selected.value"
                            (selectedIndexChange)="selected.setValue($event)"
                            mat-align-tabs="start" mat-stretch-tabs="false">
                <mat-tab label="Identifying Info">
                  <div class="margin-top grid-3-25-sm-row grid-col-gap-sm">
                    <section class="width-100">
                      <h3 class="offscreen">Date Claim Submitted</h3>
                      <mat-form-field class="width-md-100" *ngIf="caseService.administrativeClaims.claim_submitted_date">
                        <mat-label>Date Claim Submitted</mat-label>
                        <input matInput [matDatepicker]="claimSubmittdPicker" (dateChange)="updateOther(administrativeClaimsGroup,'claim_submitted_date')"
                               [value]="caseService.formatDate(caseService.administrativeClaims.claim_submitted_date, 'edit')" formControlName="claim_submitted_date"
                               (keyup.backspace)="clearField(administrativeClaimsGroup,'claim_submitted_date')"
                        >
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matIconSuffix [for]="claimSubmittdPicker"></mat-datepicker-toggle>
                        <mat-datepicker #claimSubmittdPicker></mat-datepicker>
                      </mat-form-field>
                      <mat-form-field class="width-md-100" *ngIf="!caseService.administrativeClaims.claim_submitted_date">
                        <mat-label>Date Claim Submitted</mat-label>
                        <input matInput
                               [matDatepicker]="claimSubmittdPicker"
                               (dateChange)="updateOther(administrativeClaimsGroup,'claim_submitted_date')"
                               [value]="" formControlName="claim_submitted_date" (keyup.backspace)="clearField(administrativeClaimsGroup,'claim_submitted_date')"
                        >
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matIconSuffix [for]="claimSubmittdPicker"></mat-datepicker-toggle>
                        <mat-datepicker #claimSubmittdPicker></mat-datepicker>
                      </mat-form-field>
                    </section>
                    <section class="width-100">
                      <h3 class="offscreen">Agency</h3>
                        <mat-form-field class="form-field-flex width-md-100">
                          <mat-label>Agency</mat-label>
                          <mat-select [value]="caseService.administrativeClaims.agency"
                                      formControlName="agency"
                                      [compareWith]="compareAgency"
                                      (keyup.enter)="updateInput(administrativeClaimsGroup,'agency', agency.value)"
                                      #agency>
                            <mat-option
                              (click)="updateInput(administrativeClaimsGroup,'agency', '')"
                              [value]="">
                            </mat-option>
                            <ng-container *ngFor="let data of listDataService.typeValues$ | async">
                              <mat-option *ngIf="data.name !== 'Tort'"
                                          [value]="data.value"
                                          (click)="updateInput(administrativeClaimsGroup,'agency', data.value); setAgencyName()"
                              >
                                {{ data.name }}
                              </mat-option>
                          </ng-container>
                          </mat-select>
                        </mat-form-field>
                    </section>
                    <section class="width-100">
                      <h3 class="offscreen">Claim Number</h3>
                      <mat-form-field class="form-field-flex width-md-100">
                        <mat-label>Claim Number</mat-label>
                        <input
                          matInput
                          formControlName="claim_number"
                          (change)="updateInput(administrativeClaimsGroup,'claim_number', claim_number?.value)"
                          #claim_number
                          [value]="caseService.administrativeClaims.claim_number"
                        />
                        <mat-error>Required</mat-error>
                      </mat-form-field>
                    </section>
                  </div>
                  <div class="grid-2-25-50-sm-row grid-col-gap-sm">
                    <section class="width-100">
                      <h3 class="offscreen">Settlement Amount</h3>
                      <mat-form-field class="form-field-flex width-md-100" floatLabel="always">
                        <mat-label>Settlement Amount</mat-label>
                        <input
                          matInput
                          (change)="updateInput(administrativeClaimsGroup,'settlement_amount', settlement_amount?.value)"
                          #settlement_amount
                          type="number"
                          placeholder="0"
                          class="currency-input"
                          formControlName="settlement_amount"
                          [value]="caseService.administrativeClaims.settlement_amount"
                        />
                        <span matTextPrefix>$&nbsp;</span>
                      </mat-form-field>
                    </section>
                    <section class="width-100">
                      <h3 class="offscreen">Settlement Funding Source</h3>
                      <mat-form-field class="width-100">
                        <mat-label>Settlement Funding Source</mat-label>
                        <input
                          matInput
                          #settlement_funding_src
                          (change)="updateInput(administrativeClaimsGroup, 'settlement_funding_src', settlement_funding_src?.value)"
                          formControlName="settlement_funding_src"
                          [value]="caseService.administrativeClaims.settlement_funding_src"
                          type="text"
                        />
                      </mat-form-field>
                    </section>
                  </div>
                </mat-tab>
            </mat-tab-group>
        </ng-container>
    </section>

    <!-- Appeals Details -->
    <section class="case-type" [formGroup]="appealsGroup" *ngIf="showAppeals">
        <div class="flex-row flex-space-between">
            <h2>Appeal Details</h2>
          <ng-container *ngIf="(currentUserService.canAddUpdateViewOwnCase$ | async) || (currentUserService.canEditCase$ | async) || (currentUserService.canAddEditAllUI$ | async)">
            <button mat-stroked-button *ngIf="!editingAppeals" (click)="setEditStatus('Appeals Details')" color="primary" class="flex-end">
                <mat-icon matIconButton>edit</mat-icon> Edit
            </button>
            <button mat-stroked-button *ngIf="editingAppeals" (click)="setEditStatus('Appeals Details', true)" color="primary" class="flex-end">
                <mat-icon matIconButton>save</mat-icon> Save
            </button>
          </ng-container>
        </div>
        <hr>
        <ng-container *ngIf="!editingAppeals">
            <mat-tab-group [selectedIndex]="selected.value"
                            (selectedIndexChange)="selected.setValue($event)"
                            mat-align-tabs="start" mat-stretch-tabs="false">
                <mat-tab label="Identifying Info">
                    <section class="margin-top margin-sm-bottom">
                      <h3>Action Appealed</h3>
                      <p *ngIf="caseService.appeals.action_appealed">{{caseService.appeals.action_appealed}}</p>
                      <p *ngIf="!caseService.appeals.action_appealed"><i>(none)</i></p>
                    </section>
                </mat-tab>

                <mat-tab label="Claim Details">
                    <section class="margin-top margin-sm-bottom">
                        <h3>Appeal Presented to Board</h3>
                      <p *ngIf="(appealsGroup.get('has_been_presented_to_board')?.value ?? 0) === 1">Yes</p>
                      <p *ngIf="(appealsGroup.get('has_been_presented_to_board')?.value ?? 1) === 0">No</p>
                      <p *ngIf="appealsGroup.get('has_been_presented_to_board')?.value === ''"><i>(none)</i></p>
                    </section>
                    <section class="margin-sm-bottom">
                        <h3>Date Presented</h3>
                        <p *ngIf="caseService.appeals.presented_date">{{ caseService.formatDate(caseService.appeals.presented_date) }}</p>
                        <p *ngIf="!caseService.appeals.presented_date"><i>(none)</i></p>
                    </section>
                    <section class="margin-sm-bottom">
                        <h3>Appeal Decision</h3>
                      <p *ngIf="(appealsGroup.get('appeal_decision')?.value ?? '0') === '1'">Approved</p>
                      <p *ngIf="(appealsGroup.get('appeal_decision')?.value ?? '1') === '0'">Denied</p>
                      <p *ngIf="appealsGroup.get('appeal_decision')?.value === ''"><i>(none)</i></p>
                    </section>
                </mat-tab>
            </mat-tab-group>

        </ng-container>

        <ng-container *ngIf="editingAppeals">
            <mat-tab-group  [selectedIndex]="selected.value"
                            (selectedIndexChange)="selected.setValue($event)"
                            mat-align-tabs="start" mat-stretch-tabs="false">
                <mat-tab label="Identifying Info">
                  <section class="margin-top">
                    <h3 class="offscreen">Action Appealed</h3>
                    <mat-form-field class="width-md-50">
                      <mat-label>Action Appealed</mat-label>
                      <textarea
                        matInput
                        (change)="updateInput(appealsGroup,'action_appealed', action_appealed?.value)"
                        #action_appealed
                        formControlName="action_appealed"
                        [value]="caseService.appeals.action_appealed">
                      </textarea>
                    </mat-form-field>
                  </section>
                </mat-tab>

                <mat-tab label="Claim Details">
                  <section class="margin-top margin-sm-bottom">
                    <h3>Appeal Presented to Board</h3>
                    <mat-radio-group formControlName="has_been_presented_to_board">
                      <mat-radio-button
                        color="primary"
                        *ngFor="let item of items"
                        [value]="item.value"
                        (change)="updateInput(appealsGroup,'has_been_presented_to_board', item.value)"
                        [checked]="(appealsGroup.get('has_been_presented_to_board')?.value ?? '') === item.value"
                      >
                        {{ item.label }}
                      </mat-radio-button>
                    </mat-radio-group>
                  </section>
                  <section>
                    <h3 class="offscreen">Date Presented</h3>
                    <mat-form-field class="margin-sm-bottom width-md-25" *ngIf="caseService.appeals.presented_date">
                      <mat-label>Date Presented</mat-label>
                      <input matInput [matDatepicker]="presentedPicker" (dateChange)="updateOther(appealsGroup,'presented_date')"
                             formControlName="presented_date" [value]="caseService.formatDate(caseService.appeals.presented_date, 'edit')"
                             (keyup.backspace)="clearField(appealsGroup,'presented_date')"
                      >
                      <mat-hint>MM/DD/YYYY</mat-hint>
                      <mat-datepicker-toggle matIconSuffix [for]="presentedPicker"></mat-datepicker-toggle>
                      <mat-datepicker #presentedPicker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field class="margin-sm-bottom width-md-25" *ngIf="!caseService.appeals.presented_date">
                      <mat-label>Date Presented</mat-label>
                      <input matInput [matDatepicker]="presentedPicker" (dateChange)="updateOther(appealsGroup,'presented_date')"
                             formControlName="presented_date" [value]="" (keyup.backspace)="clearField(appealsGroup,'presented_date')"
                      >
                      <mat-hint>MM/DD/YYYY</mat-hint>
                      <mat-datepicker-toggle matIconSuffix [for]="presentedPicker"></mat-datepicker-toggle>
                      <mat-datepicker #presentedPicker></mat-datepicker>
                    </mat-form-field>
                  </section>
                  <section class="margin-bottom">
                    <h3>Appeal Decision</h3>
                    <mat-radio-group formControlName="appeal_decision">
                      <mat-radio-button
                        color="primary"
                        *ngFor="let option of optionStrings"
                        [value]="option.value"
                        (change)="updateInput(appealsGroup,'appeal_decision', option.value)"
                        [checked]="(appealsGroup.get('appeal_decision')?.value ?? '') === option.value"
                      >
                        {{ option.label }}
                      </mat-radio-button>
                    </mat-radio-group>
                  </section>
                </mat-tab>
            </mat-tab-group>
        </ng-container>
    </section>

    <!-- Case Notes -->
    <section class="case-notes">
        <div class="flex-row flex-space-between">
            <h2>Notes & Attachments</h2>
          <ng-container *ngIf="(currentUserService.canAddUpdateViewOwnCase$ | async) || (currentUserService.canEditCase$ | async) || (currentUserService.canAddEditAllUI$ | async)">
            <button mat-stroked-button color="primary" (click)="addNote()"><mat-icon>add</mat-icon>Add Note</button>
          </ng-container>
        </div>
        <hr>
        <mat-table [dataSource]="caseService.detailsNotes" matSort>
            <!-- Date Column -->
            <ng-container matColumnDef="created_at">
                <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Date"> Date </mat-header-cell>
                <mat-cell tabindex="0" *matCellDef="let element"> {{ caseService.formatDate(element.created_at) }} </mat-cell>
            </ng-container>

            <!-- Details Column -->
            <ng-container matColumnDef="note">
              <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Details"> Details </mat-header-cell>
              <mat-cell tabindex="0" class="table-case-note" *matCellDef="let element">
                <span *ngIf="element.note">{{ element.note }}</span>
                <span *ngIf="!element.note"><i>(none)</i></span>
              </mat-cell>
            </ng-container>

            <!-- File Name Column -->
            <ng-container matColumnDef="filename">
              <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by File Name"> File Name </mat-header-cell>
              <mat-cell tabindex="0" *matCellDef="let element">
                <ng-container *ngFor="let file of element.attachments">
                  <div class="multiple-line-cell padding-none blue-text pointer" (click)="download(file)" (keyup)="download(file)">
                    {{ file.filename }}
                  </div>
                </ng-container>
              </mat-cell>
          </ng-container>

          <!-- View Column -->
          <ng-container matColumnDef="view">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let element" class="flex-row flex-end">
              <button
                class="margin-sm-right"
                tabindex="0"
                mat-stroked-button
                (click)="viewNote(element.note)"
                color="primary"
                [disabled]="!element.note"
              >
                View
              </button> </mat-cell>
          </ng-container>

          <!-- Delete Column -->
          <ng-container matColumnDef="delete">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let element" class="flex-row flex-end">
              <button
                tabindex="0"
                *ngIf="(currentUserService.canAddUpdateViewOwnCase$ | async) || (currentUserService.canEditCase$ | async) || (currentUserService.canAddEditAllUI$ | async)"
              mat-stroked-button
              (click)="deleteNote(element)"
              (keypress.enter)="deleteNote(element)"
              color="warn"
            >
              Delete
            </button> </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="notesColumns" class="matHeaderRow"></mat-header-row>
          <mat-row *matRowDef="let row; columns: notesColumns;"></mat-row>

          <div class="mat-row no-table-data" role="row" *matNoDataRow>
            <div role="cell" class="mat-cell">No Records Found</div>
          </div>
        </mat-table>
    </section>

    </ng-container>
    <ng-template #noCase>
        <p>This case does not exist.</p>
    </ng-template>
  </ng-container>
</div>
