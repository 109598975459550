import {Attachments} from "./attachments";

export class Notes {
  public pk_note_id: number = -1;
  public fk_case_id: number = -1;
  public note: string = '';
  public created_by: number = -1;
  public created_at: string = '';
  public updated_by: number = -1;
  public updated_at: string = '';
  public attachments: Attachments[]= [];
}
