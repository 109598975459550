import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import { DropdownSelectService } from 'src/app/shared/services/dropdown-select.service';
import { DateRangePickerService } from 'src/app/shared/services/date-range-picker.service';
import {ListDataService} from "../../../services/list-data/list-data.service";
import {DateRangeOutput} from "../date-range-picker/date-range-picker.component";
import {pairwise} from "rxjs";
import {tap} from "rxjs/operators";
import {DropdownSelectComponent} from "../dropdown-select/dropdown-select.component";

@Component({
  selector: 'app-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss']
})
export class ExpansionPanelComponent implements OnInit {
  @Output() dropdownOutput = new EventEmitter<any>();
  // @Output() startDateOutput = new EventEmitter<any>();
  // @Output() endDateOutput = new EventEmitter<any>();
  @Output() dateOutput = new EventEmitter<any>();
  @Output() resetFiltersOutput = new EventEmitter<null>();

  @ViewChild(DropdownSelectComponent) allDropdowns!: DropdownSelectComponent[];

  public dropdownSelects: any;
  public dateRangePickers: any;


  constructor(
    public dropdownSelectService: DropdownSelectService,
    public listDataService: ListDataService,
    public dateRangePickerService: DateRangePickerService
  ) { }

  ngOnInit(): void {
    this.listDataService.classifications$.subscribe(data => {
      this.resetDropdowns();
    });

    this.listDataService.typeValues$.subscribe( data => {
      this.resetDropdowns();
    });

    this.listDataService.institutions$.subscribe(data => {
      this.resetDropdowns();
    })

    this.listDataService.attorneys$.subscribe(data => {
      this.resetDropdowns();
    });

    this.dropdownSelectService.setupDropdownSelects();
    this.dateRangePickerService.setupDateRangePickers();
    this.dateRangePickers = [
      this.dateRangePickerService.dateCreated,
      this.dateRangePickerService.dateModified,
      this.dateRangePickerService.dateFiledSubmitted,
      this.dateRangePickerService.trialDate,
      this.dateRangePickerService.mediationDate
    ]
  }

  public emitDropdown(value: unknown){
    console.log('dropdown emitted value', value);
    this.dropdownOutput.emit(value);
  }

  public emitDate(value: unknown){
    this.dateOutput.emit(value);
  }

  public clearAllFilters(): void {
    window.location.href='/search-cases';
    return;
    // this.listDataService.classifications$.next(this.listDataService.currentClassifications);
    // this.listDataService.typeValues$.next([]);
    // this.resetDropdowns();
    // // this.listDataService.subtypeValues$.next([]);
    // // this.listDataService.institutions$.next([]);
    // // this.listDataService.attorneys$.next([]);
    // this.dateRangePickerService.setupDateRangePickers();
    // this.dateRangePickers = [
    //   this.dateRangePickerService.dateCreated,
    //   this.dateRangePickerService.dateFiledSubmitted,
    //   this.dateRangePickerService.trialDate,
    //   this.dateRangePickerService.mediationDate
    // ];
    // this.resetFiltersOutput.emit();
  }

  private resetDropdowns(): void {
    this.dropdownSelects = [
      this.dropdownSelectService.caseClassification,
      this.dropdownSelectService.caseType,
      this.dropdownSelectService.institution,
      this.dropdownSelectService.attorney
    ];
  }
}
