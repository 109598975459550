import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CaseService} from "../../services/case.service";
import {Attachments} from "../../models/attachments";
import {MatTableDataSource} from "@angular/material/table";

@Component({
  selector: 'app-add-note',
  templateUrl: './add-note.component.html',
  styleUrls: ['./add-note.component.scss']
})
export class AddNoteComponent {

  public noteForm: FormGroup = new FormGroup<any>({
    attachments: this._fb.array([])
  });

  public attachment: Attachments = new Attachments();

  public id = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);

  public fileSizeValid = true;

  public invalidFileType = false;

  private maxFileSize = 75000000;

  private _ALLOWED_FILE_EXT = [
    'pdf',
    'doc',
    'docx'
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { [index: string]: any },
    public caseService: CaseService,
    private _fb: FormBuilder
  ) {
    this.fillNewNote();
  }

  public getDetails(): void {
    this.caseService.getDetails().subscribe( details => {
      this.caseService.detailsNotes = new MatTableDataSource(details.notes);
    });
  }

  public fillNewNote(): void {
    try {
      const newNote: FormGroup = this.noteForm as FormGroup;
      newNote.setControl(
        'pk_note_id',
        this._fb.control(-1, {
          nonNullable: true,
          validators: Validators.required,
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newNote.setControl(
        'fk_case_id',
        this._fb.control(parseInt(this.id), {
          nonNullable: true,
          validators: Validators.required,
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newNote.setControl(
        'note',
        this._fb.control('', {
          nonNullable: true,
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newNote.setControl(
        'created_by',
        this._fb.control('', {
          nonNullable: true,
          validators: Validators.required,
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newNote.setControl(
        'created_at',
        this._fb.control('', {
          nonNullable: true,
          validators: Validators.required,
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newNote.setControl(
        'updated_by',
        this._fb.control('', {
          nonNullable: true,
          validators: Validators.required,
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newNote.setControl(
        'updated_at',
        this._fb.control('', {
          nonNullable: true,
          validators: Validators.required,
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newNote.setControl(
        'attachments',
        this._fb.array(this.noteForm.get('attachments')?.value, {
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
    } catch (e) {
      console.log('Error filling full case: ', e);
    }
  }

  public fillNewAttachment(): void {
    try {
      this.attachment = new Attachments();
      const newAttachment: FormGroup = this.noteForm.get('attachments')?.value as FormGroup;
      console.log(newAttachment);
      if ((newAttachment as unknown as Array<any>).length === 0) {
        return;
      }
      newAttachment.setControl(
        'pk_attachment_id',
        this._fb.control(-1, {
          nonNullable: true,
          validators: Validators.required,
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newAttachment.setControl(
        'fk_note_id',
        this._fb.control(this.noteForm.get('fk_note_id')?.value, {
          nonNullable: true,
          validators: Validators.required,
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newAttachment.setControl(
        'filename',
        this._fb.control('', {
          nonNullable: true,
          validators: Validators.required,
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newAttachment.setControl(
        'filepath',
        this._fb.control('', {
          nonNullable: true,
          validators: Validators.required,
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newAttachment.setControl(
        'mimetype',
        this._fb.control('', {
          nonNullable: true,
          validators: Validators.required,
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newAttachment.setControl(
        'filesize',
        this._fb.control('', {
          nonNullable: true,
          validators: Validators.required,
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newAttachment.setControl(
        'created_by',
        this._fb.control('', {
          nonNullable: true,
          validators: Validators.required,
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newAttachment.setControl(
        'created_at',
        this._fb.control('', {
          nonNullable: true,
          validators: Validators.required,
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newAttachment.setControl(
        'updated_by',
        this._fb.control('', {
          nonNullable: true,
          validators: Validators.required,
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
      newAttachment.setControl(
        'updated_at',
        this._fb.control('', {
          nonNullable: true,
          validators: Validators.required,
          updateOn: 'change',
        }),
        { emitEvent: true }
      );
    } catch (e) {
      console.log('Error filling full case: ', e);
    }
  }


  public fillAttachments(data: any): void {
    try {
      (this.noteForm.get('attachments') as FormArray).clear();
      data.forEach((attachment: any) => {
        (this.noteForm.get('attachments') as FormArray).push(
          this._fb.group({
            filename: [attachment.filename ?? ''],
            filepath: [attachment.filepath ?? ''],
            filesize: [attachment.filesize ?? ''],
            mimetype: [attachment.mimetype ?? ''],
            pk_attachment_id: [attachment.pk_attachment_id ?? ''],
            fk_note_id: [attachment.fk_note_id ?? '']
          })
        );
      });
    } catch (e) {
      console.log('Error filling full case: ', e);
    }
  }

  public handleFileInputDialog(files: any): boolean {
    if (files.length === 0) {
      return false;
    }
    this.handleNewFile(files[0]);
    return true;
  }

  public uploadFiles(): boolean {
    const hiddeninput = document.getElementById('file-input-element') as HTMLInputElement;
    hiddeninput.click();
    return true;
  }

  public handleNewFile(file: any): boolean {
    const reader = new FileReader();
    const that = this;
    reader.onload = (event) => {
      let canDecode = true;
      if (file.size > that.maxFileSize) {
        this.fileSizeValid = false;
        canDecode = false;
      } else {
        this.fileSizeValid = true;
      }
      const ext = file.name.slice(file.name.lastIndexOf('.') + 1);
      if (!this._ALLOWED_FILE_EXT.includes(ext.toLowerCase())) {
        canDecode = false;
        this.invalidFileType = true;
      } else {
        this.invalidFileType = false;
      }
      if (canDecode) {
        that.decodeFileSetValues(event, reader, file);
      }
    };
    reader.readAsDataURL(file);
    return true;
  }

  public decodeFileSetValues(event: any, reader: FileReader | any, file: any): boolean {
    if (event.total > this.maxFileSize) {
      this.fileSizeValid = false;
      return false;
    }
    const fullEncodedFileString = reader.result.toString();
    const loc = fullEncodedFileString.search('base64,');
    const identifier = fullEncodedFileString.substring(0, loc + 6).split(';');

    if (identifier[1] !== 'base64') {
      this.fileSizeValid = false;
      return false;
    }

    const mimeType = identifier[0].split(':')[1];

    this.attachment  =
      {
        pk_attachment_id: -1,
        fk_note_id: this.noteForm.get('pk_note_id')?.value,
        filename: file.name,
        filepath: fullEncodedFileString,
        mimetype: mimeType,
        filesize: file.size,
        created_by: '1',
        created_at: new Date(),
        updated_by: '1',
        updated_at: new Date()
      };

    this.fillAttachments([this.attachment]);
    const tempInput = document.getElementById('file-input-element') as HTMLInputElement;
    tempInput.value = '';
    return true;
  }

  public add(): void {
    try {
        const today = new Date();
        const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
        this.noteForm.get('created_at')?.setValue(date);
        if (this.caseService.state === 'add-new-case') {
          this.caseService.tempNotes.push(this.noteForm.value);
          this.caseService.addNewNotes = new MatTableDataSource(this.caseService.tempNotes);
        }
        if (this.caseService.state === 'details') {
          this.caseService.postNewNote(this.noteForm.value);
          this.caseService.getNotes();
        }
        this.noteForm.reset();
        this.fillNewNote();
        this.fillNewAttachment();
    } catch (e) {
      console.log('Error in add: ', e);
    }
  }

}
