import { Component, AfterViewInit } from '@angular/core';
import { BreakpointService } from './shared/services/breakpoint.service';
import { ListDataService } from "./shared/services/list-data/list-data.service";
import { CurrentUserService } from './shared/services/current-user/current-user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
// export class AppComponent implements AfterViewInit {
  title = 'Litigation Tracking System';

  constructor(
    private BreakpointService: BreakpointService,
    private listDataService: ListDataService,
    private currentUserService: CurrentUserService
  ) {
  }

  // ngAfterViewInit(): void {
  //   // this.listDataService.getListData();
  // }
}
