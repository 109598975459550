import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { MaterialModule } from "../../modules/material/material.module";
// import {CurrentUserService} from "../current-user/current-user.service";
import {MatOption} from "@angular/material/core";

export interface ListDataAll {
  all_list_values: string[]
  [index: string]: ListDataEntry | string[]
}
export interface ListDataEntry {
  values: string[],
  details: ListDetails[]
}
export interface ListDetails {
  pk_list_id: number,
  value: string,
  sort_order: number,
  is_inactive: number,
  pk_list_type_id: number,
  type: string,
  list_type_is_inactive: number
}
export interface DropdownDetails {
  name: string,
  value: number
}

export interface Institution {
  pk_institution_id: number,
  institution_value: string,
  is_inactive: number,
  created_by: number,
  created_at: string,
  updated_by: string,
  updated_at: string
}

export interface Attorney {
  pk_user_id: number,
  institution_value: string,
  phone: string,
  email: string,
  firstname: string,
  lastname: string,
}

export interface Role {
  pk_role_id: number,
  role: string
}

@Injectable({
  providedIn: 'root'
})

export class ListDataService {

  public classifications$: BehaviorSubject<DropdownDetails[]> = new BehaviorSubject([] as DropdownDetails[]);
  public borAppealsCaseSubtype$: BehaviorSubject<DropdownDetails[]> = new BehaviorSubject([] as DropdownDetails[]);
  public type$: BehaviorSubject<DropdownDetails[]> = new BehaviorSubject([] as DropdownDetails[]);
  public subtypes$: BehaviorSubject<DropdownDetails[]> = new BehaviorSubject([] as DropdownDetails[]);
  public institutions$: BehaviorSubject<DropdownDetails[]> = new BehaviorSubject([] as DropdownDetails[]);
  public attorneys$: BehaviorSubject<DropdownDetails[]> = new BehaviorSubject([] as DropdownDetails[]);
  public attorneyGenerals$: BehaviorSubject<any> = new BehaviorSubject([]);
  public agency$: BehaviorSubject<any> = new BehaviorSubject([]);
  // public roles$: BehaviorSubject<any> = new BehaviorSubject([]);
  public grievantAssoc$: BehaviorSubject<any> = new BehaviorSubject([]);
  public bargainingUnit$: BehaviorSubject<any> = new BehaviorSubject([]);
  public claimNumbers$: BehaviorSubject<any> = new BehaviorSubject([]);
  public tortClaimIds$: BehaviorSubject<any> = new BehaviorSubject([]);
  public typeValues$: BehaviorSubject<DropdownDetails[]> = new BehaviorSubject<DropdownDetails[]>([]);
  public subtypeValues$: BehaviorSubject<DropdownDetails[]> = new BehaviorSubject<DropdownDetails[]>([]);
  public currentClassifications: DropdownDetails[] = [];


  // the different types and subtypes
  public externalTypes: DropdownDetails[] = [];
  public litigationTypes: DropdownDetails[] = [];
  public internalTypes: DropdownDetails[] = [];

  public borAppealsSubTypes: DropdownDetails[] = [];
  public discriminationSubTypes: DropdownDetails[] = [];
  public eeocSubTypes: DropdownDetails[] = [];
  public icrcSubTypes: DropdownDetails[] = [];
  public ipibSubTypes: DropdownDetails[] = [];
  public ocrSubTypes: DropdownDetails[] = [];
  public perbSubTypes: DropdownDetails[] = [];
  public tortSubTypes: DropdownDetails[] = [];
  public titleixSubTypes: DropdownDetails[] = [];

  // current-user details
  public roles$: BehaviorSubject<any> = new BehaviorSubject([]);
  public roleValues$: BehaviorSubject<any> = new BehaviorSubject<any>([]);

  public roles: DropdownDetails[] = [];

  public dropDownSelected: string = '';

  constructor(private http: HttpClient) {
    // this.classifications$.subscribe( d => { console.log('classifications', d);});
    // this.borAppealsCaseSubtype$.subscribe( d => { console.log('borAppealsCaseSubtype', d);});
    // this.institutions$.subscribe( d => { console.log('institutions', d);});
    // this.type$.subscribe( d => { console.log('types', d);});
    // this.subtypes$.subscribe( d => { console.log('subtypes', d);});
    // this.attorneys$.subscribe( d => { console.log('attorneys', d);});
    // this.attorneyGenerals$.subscribe( d => { console.log('attorney generals', d);});
    // this.agency$.subscribe( d => { console.log('agencies', d);});
    // this.grievantAssoc$.subscribe( d => { console.log('grievantAssoc', d);});
    // this.bargainingUnit$.subscribe( d => { console.log('bargainingUnit', d);});
    // this.claimNumbers$.subscribe( d => { console.log('claimNumbers', d);});
    // this.tortClaimIds$.subscribe( d => { console.log('tortClaimIds', d);});
    // this.roles$.subscribe( d => { console.log('roles', d);});
  }

  public getDropDownName(value: string): void  {
    this.dropDownSelected = value.toString().toLowerCase().trim();
  }

  public getListData(): void {
    try {
      let url = environment.apiUrl + environment.listDatatEndpoint;
      this.http.get<ListDataAll>(url).subscribe ( lists => {
        this.parseListData(lists);
      });
      url = environment.apiUrl + environment.institutionsEndpoint;
      this.http.get<Institution[]>(url).subscribe ( institutions => {
        this.parseInstitutions(institutions);
      });
      url = environment.apiUrl + environment.attorneysEndpoint;
      this.http.get<Attorney[]>(url).subscribe ( attorneys => {
        this.parseAttorneys(attorneys);
      });
    } catch(e) {
      console.log(e);
    }
  }

  public resetFilters(): void {
    this.typeValues$.next([]);
    this.subtypeValues$.next([]);
  }

  public updateTypeSubtypeMulti(type: string= '', event: any): void {
    const selectedValues = event.source.triggerValue.split(',').map( (x: string) => x.trim());
    if (type === 'classification') {
      if (selectedValues.length === 0) {
        this.typeValues$.next([]);
        this.subtypeValues$.next([]);
        return;
      }
      let temp: any[] = [];
      if (selectedValues.includes('Internal Complaints')) {
        temp = temp.concat(this.internalTypes);
      }
      if (selectedValues.includes('External Complaints')) {
        temp = temp.concat(this.externalTypes);
      }
      if (selectedValues.includes('Litigation')) {
        temp = temp.concat(this.litigationTypes);
      }
      temp = this.sortDropdown(temp);
      this.typeValues$.next(temp);
    } else if (type === 'type') {
      if (selectedValues.length === 0) {
        this.subtypeValues$.next([]);
        return;
      }
      let temp: any[] = [];
      if (selectedValues.includes('Appeals to BoR')) {
        temp = temp.concat(this.borAppealsSubTypes);
      }
      if (selectedValues.includes('EEOC')) {
        temp = temp.concat(this.eeocSubTypes);
      }
      if (selectedValues.includes('ICRC')) {
        temp = temp.concat(this.icrcSubTypes);
      }
      if (selectedValues.includes('Discrimination')) {
        temp = temp.concat(this.discriminationSubTypes);
      }
      if (selectedValues.includes('IPIB')) {
        temp = temp.concat(this.ipibSubTypes);
      }
      if (selectedValues.includes('OCR')) {
        temp = temp.concat(this.ocrSubTypes);
      }
      if (selectedValues.includes('PERB')) {
        temp = temp.concat(this.perbSubTypes);
      }
      if (selectedValues.some((value: string) => { return value.includes('Tort'); })) {
        temp = temp.concat(this.tortSubTypes);
      }
      if (selectedValues.includes('Title IX')) {
        temp = temp.concat(this.titleixSubTypes);
      }
      if (temp.length > 0) {
        temp = this.sortDropdown(temp);
      }
      this.subtypeValues$.next(temp);
    }
  }
  public updateTypeSubtype(type: string = '', value: string | number = -1, name: string = ''): void {
    value = parseInt(value.toString());
    if (!isNaN(value) || value > 0 || name !== '') {
      if (type === 'classification') {
        this.subtypeValues$.next([]);
        if (name === 'Internal Complaints') {
          this.typeValues$.next(this.internalTypes);
        } else if (name === 'External Complaints') {
          this.typeValues$.next(this.externalTypes);
        } else if (name === 'Litigation') {
          this.typeValues$.next(this.litigationTypes);
        } else {
          this.typeValues$.next([]);
        }
      } else if (type === 'type') {
        if (name === 'Appeals to BoR') {
          this.subtypeValues$.next(this.borAppealsSubTypes);
        } else if (name === 'EEOC') {
          this.subtypeValues$.next(this.eeocSubTypes);
        } else if (name === 'ICRC') {
          this.subtypeValues$.next(this.icrcSubTypes);
        } else if (name === 'Discrimination') {
          this.subtypeValues$.next(this.discriminationSubTypes);
        } else if (name === 'IPIB') {
          this.subtypeValues$.next(this.ipibSubTypes);
        } else if (name === 'OCR') {
          this.subtypeValues$.next(this.ocrSubTypes);
        } else if (name === 'PERB') {
          this.subtypeValues$.next(this.perbSubTypes);
        } else if (name.includes('Tort') === true) {
          this.subtypeValues$.next(this.tortSubTypes);
        } else if (name === 'Title IX') {
          this.subtypeValues$.next(this.titleixSubTypes);
        } else {
          this.subtypeValues$.next([]);
        }

      }
      return;
    }
  }

  private parseListData(lists: ListDataAll | null = null): void {
    if (lists === null) {
      return;
    }
    try {
      //const classificationDetails: ListDetails[] = (lists['case_classification']as ListDataEntry)['details'] ?? [];
      const classificationDetails: ListDetails[] = ( (lists['case_classification'] ?? []) as ListDataEntry)['details'] ?? [];
      const borAppealsCaseSubtypeDetails: ListDetails[] = (lists['bor_appeals_case_subtype']as ListDataEntry)['details'] ?? [];
      const externalDetails: ListDetails[] = (lists['external_complaints_case_type']as ListDataEntry)['details'] ?? [];
      const litigationDetails: ListDetails[] = (lists['litigation_case_type']as ListDataEntry)['details'] ?? [];
      const internalDetails: ListDetails[] = (lists['internal_complaints_case_type']as ListDataEntry)['details'] ?? [];
      const borAppealsDetails: ListDetails[] = (lists['bor_appeals_case_subtype']as ListDataEntry)['details'] ?? [];
      const discriminationDetails: ListDetails[] = (lists['discrimination_case_subtype']as ListDataEntry)['details'] ?? [];
      const eeocDetails: ListDetails[] = (lists['eeoc_case_subtype']as ListDataEntry)['details'] ?? [];
      const icrcDetails: ListDetails[] = (lists['icrc_case_subtype']as ListDataEntry)['details'] ?? [];
      const ipibDetails: ListDetails[] = (lists['ipib_case_subtype']as ListDataEntry)['details'] ?? [];
      const ocrDetails: ListDetails[] = (lists['ocr_case_subtype']as ListDataEntry)['details'] ?? [];
      const perbDetails: ListDetails[] = (lists['perb_case_subtype']as ListDataEntry)['details'] ?? [];
      const tortDetails: ListDetails[] = (lists['tort_case_subtype']as ListDataEntry)['details'] ?? [];
      const titleixDetails: ListDetails[] = (lists['titleix_case_subtype']as ListDataEntry)['details'] ?? [];

      this.externalTypes = this.parseDetails(externalDetails);
      this.litigationTypes = this.parseDetails(litigationDetails);
      this.internalTypes = this.parseDetails(internalDetails);

      this.borAppealsSubTypes = this.parseDetails(borAppealsDetails);
      this.discriminationSubTypes = this.parseDetails(discriminationDetails);
      this.eeocSubTypes = this.parseDetails(eeocDetails);
      this.icrcSubTypes = this.parseDetails(icrcDetails);
      this.ipibSubTypes = this.parseDetails(ipibDetails);
      this.ocrSubTypes = this.parseDetails(ocrDetails);
      this.perbSubTypes = this.parseDetails(perbDetails);
      this.tortSubTypes = this.parseDetails(tortDetails);
      this.titleixSubTypes = this.parseDetails(titleixDetails);

      const grievantAssocDetails: ListDataEntry = (lists['grievant-assoc']as ListDataEntry) ?? [];
      const bargainingUnitDetails: ListDataEntry = (lists['bargaining-unit']as ListDataEntry) ?? [];
      const claimNumbersDetails: ListDataEntry = (lists['claim-numbers']as ListDataEntry) ?? [];
      const tortClaimIdsDetails: ListDataEntry = (lists['tort-claim-ids']as ListDataEntry) ?? [];
      const agencyDetails: ListDataEntry = (lists['agency']as ListDataEntry) ?? [];
      const attorneyGeneralDetails: Attorney[] = (lists['all_attorney_generals'] as unknown as Attorney[]) ?? [];
      this.parseAttorneyGenerals(attorneyGeneralDetails);
      this.parseClassifications(classificationDetails);
      this.parseBorAppealsCaseSubtype(borAppealsCaseSubtypeDetails);
      this.parseTypes(externalDetails, internalDetails, litigationDetails);
      this.parseSubtypes(borAppealsDetails, discriminationDetails, eeocDetails, icrcDetails, ipibDetails, ocrDetails, perbDetails, tortDetails);
      this.agency$.next(agencyDetails);
      this.grievantAssoc$.next(grievantAssocDetails);
      this.bargainingUnit$.next(bargainingUnitDetails);
      this.claimNumbers$.next(claimNumbersDetails);
      this.tortClaimIds$.next(tortClaimIdsDetails);

      const rolesDetails: Role[] = (lists['roles'] as unknown as Role[]) ?? [];
      this.parseRoles(rolesDetails);

    } catch(e) {
      console.log(e);
    }

  }

  private parseInstitutions(institutions: Institution[] | null = null): void {
    if (institutions === null) {
      return;
    }
    try {
      let result: DropdownDetails[] = [];
      institutions.forEach( (institution: Institution) => {
        if ( (institution.is_inactive ?? 1) === 0) {
          result.push(
            {
              name: institution.institution_value ?? '',
              value: institution.pk_institution_id ?? 0
            }
          )
        }
      });
      this.institutions$.next(this.sortDropdown(result));
    } catch(e) {
      console.log(e);
    }
  }

  private parseAttorneys(attorneys: Attorney[] | null = null): void {
    if (attorneys === null) {
      return;
    }
    try {
      let result: DropdownDetails[] = [];
      attorneys.forEach( (attorney: Attorney) => {
          result.push(
            {
              name: (attorney.firstname + ' ' + attorney.lastname) ?? '',
              value: attorney.pk_user_id ?? 0
            }
          )
      });
      this.attorneys$.next(this.sortDropdown(result));
    } catch(e) {
      console.log(e);
    }
  }

  private parseAttorneyGenerals(attorneys: Attorney[] | null = null): void {
    if (attorneys === null) {
      return;
    }
    try {
      let result: DropdownDetails[] = [];
      attorneys.forEach( (attorney: Attorney) => {
        result.push(
          {
            name: (attorney.firstname + ' ' + attorney.lastname) ?? '',
            value: attorney.pk_user_id ?? 0
          }
        )
      });
      this.attorneyGenerals$.next(this.sortDropdown(result));
    } catch(e) {
      console.log(e);
    }
  }

  private parseClassifications(classificationDetails: ListDetails[] | null = null): void {
    if (classificationDetails === null) {
      return;
    }
    try {
      const result = this.parseDetails(classificationDetails);
      this.currentClassifications = this.sortDropdown(result);
      this.classifications$.next(this.currentClassifications);
    } catch(e) {
      console.log(e);
    }
  }

  private parseBorAppealsCaseSubtype(borAppealsCaseSubtypeDetails: ListDetails[] | null = null): void {
    if (borAppealsCaseSubtypeDetails === null) {
      return;
    }
    try {
      const result = this.parseDetails(borAppealsCaseSubtypeDetails);
      this.borAppealsCaseSubtype$.next(this.sortDropdown(result));
    } catch(e) {
      console.log(e);
    }
  }

  private parseTypes(external: ListDetails[] = [], internal: ListDetails[] = [], litigation: ListDetails[] = []): void {
    try {
      let result: DropdownDetails[] = [];
      result = result.concat(this.parseDetails(external));
      result = result.concat(this.parseDetails(internal));
      result = result.concat(this.parseDetails(litigation));
      this.type$.next(this.sortDropdown(result));
    } catch(e) {
      console.log(e);
    }
  }

  private parseSubtypes(borAppeals: ListDetails[] = [], discrimination: ListDetails[] = [], eeoc: ListDetails[] = [],
                        icrc: ListDetails[] = [], ipib: ListDetails[] = [], ocr: ListDetails[] = [],
                        perb: ListDetails[] = [], tort: ListDetails[] = []): void {
    try {

      let result: DropdownDetails[] = [];
      result = result.concat(this.parseDetails(borAppeals));
      result = result.concat(this.parseDetails(discrimination));
      result = result.concat(this.parseDetails(eeoc));
      result = result.concat(this.parseDetails(icrc));
      result = result.concat(this.parseDetails(ipib));
      result = result.concat(this.parseDetails(ocr));
      result = result.concat(this.parseDetails(perb));
      result = result.concat(this.parseDetails(tort));
      this.subtypes$.next(this.sortDropdown(result));
    } catch(e) {
      console.log(e);
    }
  }

  private parseRoles(roles: Role[]): void {
    try {
      let result: DropdownDetails[] = [];
      roles.forEach((role: Role) => {
        result.push({
          name: role.role,
          value: role.pk_role_id
        })
      });
      this.roles$.next(this.sortDropdown(result));
    } catch(e) {
      console.log(e);
    }
  }

  private parseDetails(details: ListDetails[] = []): DropdownDetails[] {
    let result: DropdownDetails[] = [];
    try {
      details.forEach( detail => {
        if ( (detail.is_inactive ?? 1) === 0) {
          result.push(
            {
              name: detail.value ?? '',
              value: detail.pk_list_id ?? 0
            }
          )
        }
      });
      return result;
    } catch(e) {
      console.log(e);
      return [];
    }
  }

  private sortDropdown(details: DropdownDetails[] = []): DropdownDetails[] {
    try {
      return this.removeDuplicates(details).sort( (a: DropdownDetails, b: DropdownDetails) => {
        return a.name < b.name ? -1 :
               (a.name > b.name ? 1 : 0);
      });
    } catch(e) {
      console.log(e);
      return [];
    }
  }

  private removeDuplicates(details: DropdownDetails[] = []): DropdownDetails[] {
    const mappedResult = new Map(details.map((m) => [m.name, m]));
    const iterator = mappedResult.values();
    return [...iterator];
  }



}
