import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import { Router } from '@angular/router';
import { Observable, map, take, tap } from 'rxjs';

import { CurrentUserService } from '../../services/current-user/current-user.service';
import { CurrentUser } from '../../models/current-user';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ValidUserGuard implements CanActivate, CanLoad {
  constructor (private currentUserService: CurrentUserService, private router: Router, private location: Location) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.currentUserService.setCurrentUser().pipe(
        map(result => {
          if (this.currentUserService.isLoggedIn === false) {
            this.router.navigate(['/discovery']);
          }
          return this.currentUserService.isLoggedIn;
        })
      );
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.currentUserService.setCurrentUser().pipe(
      map(result => {
        if (this.currentUserService.isLoggedIn === false) {
          this.router.navigate(['/discovery']);
        }
        return this.currentUserService.isLoggedIn;
      })
    );
  }


}
