import { Component, Output, EventEmitter } from '@angular/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent {
  
  filterText = '';
  spinnerShow = true;  
  color = 'primary';
  mode = 'indeterminate' as ProgressSpinnerMode;
  @Output() callParent = new EventEmitter();

  constructor() { }

  setFilterText() { 
    this.callParent.emit(this.filterText);
  }

}
