import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-view-note',
  templateUrl: './view-note.component.html',
  styleUrls: ['./view-note.component.scss']
})

export class ViewNoteComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { [index: string]: any }
  ) {}

}
