import { BaseModel } from "./base-model";

export class Case extends BaseModel {
  public classification_value: string = '';
  public type_value: string = '';
  public created_by_firstname: string = '';
  public created_by_lastname: string = '';
  public created_by_email: string = '';
  public updated_by_firstname: string = '';
  public updated_by_lastname: string = '';
  public updated_by_email: string = '';
  // public max_date: string = '';
  public delete_request_firstname: string = '';
  public delete_request_lastname: string = '';
  public delete_request_email: string = '';
  public pk_case_id: number = -1;
  public classification: number = -1;
  public type: number = -1;
  public title: string = '';
  public description: string = '';
  public is_closed: number = -1;
  public filed_date: string = '';
  public resolution_date: string = '';
  public is_delete_requested: number = 0;
  public delete_requested_by: number = -1;
  public created_by: number = -1;
  public created_at: string = '';
  public updated_by: number = -1;
  public updated_at: string = '';
  public max_date: string = ''; // the most recent time any part of the case was updated
  public delete_email_sent: number = -1;
  public trial_date: string = '';
  public mediation_date: string = '';
  public subtypes_values: Array<any> = [];
  public subtypes: Array<any> = [];
  public subtypes_full: Array<any> = [];
  public institutions: Array<any> = [];
  public institutions_values: Array<any> = [];
  public institutions_full: Array<any> = [];
  public in_house_attorneys: Array<any> = [];
  public in_house_attorneys_values: Array<any> = [];
  public in_house_attorneys_full: Array<any> = [];
  public attorney_generals: Array<any> = [];
  public attorney_generals_values: Array<any> = [];
  public attorney_generals_full: Array<any> = [];
  public notes: Array<any> = [];
  public notes_combined: string = '';
  public in_house_attorney_id: number = -1;
  public in_house_attorney_first: string = '';
  public in_house_attorney_last: string = '';
  public in_house_attorney_email: string = '';
  public in_house_attorney_phone: string = '';
  public in_house_attorney_institution: string = '';
  public attorney_general_assigned: number = -1;
}
