export class Appeals {
  public pk_appeal_id: number = -1;
  public fk_case_id: number = -1;
  public appeal_type: string = '';
  public action_appealed: string = '';
  public has_been_presented_to_board!: number;
  public presented_date: string = '';
  public appeal_decision: string = '';
  public created_by: number = -1;
  public created_at: string = '';
  public updated_by: number = -1;
  public updated_at: string = '';
  // public bor_appeals_case_subtype: string = '';
}
