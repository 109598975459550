import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Details} from "../../models/details";
import {tap} from "rxjs/operators";
import { Observable } from 'rxjs/internal/Observable';
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {CaseService} from "../../services/case.service";

@Component({
  selector: 'app-preview-tort-claim',
  templateUrl: './preview-tort-claim.component.html',
  styleUrls: ['./preview-tort-claim.component.scss']
})
export class PreviewTortClaimComponent {

  public detailsUrl = environment.apiUrl + environment.detailsEndpoint;

  public details: Details = new Details();

  constructor(
    public caseService: CaseService,
    private http: HttpClient,
      @Inject(MAT_DIALOG_DATA) public data: any = {
        // classification: data.classification,
        // type: data.type,
        // pk_case_id: data.pk_case_id,
        // title: data.title,
        // description: data.description,
        // claim_submitted_date: data.claim_submitted_date,
        // is_closed: data.is_closed,
        // filed_date: data.filed_date,
        // resolution_date: data.resolution_date,
        // is_delete_requested: data.is_delete_requested,
        // delete_requested_by: data.delete_requested_by,
        // created_by: data.created_by,
        // created_at: data.created_at,
        // updated_by: data.updated_by,
        // updated_at: data.updated_at,
        // delete_email_sent: data.delete_email_sent,
        tort_claim_id: caseService.litigations.tort_claim_id,
        // created_firstname: data.created_firstname,
        // created_lastname: data.created_lastname,
        // created_email: data.created_email,
        // updated_firstname: data.updated_firstname,
        // updated_lastname: data.updated_lastname,
        // updated_email: data.updated_email
      }
    ) {
    this.getDetails().subscribe();
  }

    public get status(): string  {
      try {
        let status = ''
        if (this.data.is_closed === 1) {
          status = 'Closed'
        } else {
          status = 'Open'
        }
        return status;
      } catch (e) {
        console.log(e);
        return '';
      }
    }

  public getDetails(): Observable<any>  {
    let url = this.detailsUrl + `/${this.data.tort_claim_id}`;
    return this.http.get<any>(url).pipe(
      tap(
        details => {
          if (details.cases.length > 0) {
            this.details = details.cases[0];
          }
        }));
  }

}
