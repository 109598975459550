import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CurrentUserService } from '../../services/current-user/current-user.service';

@Component({
  selector: 'lts-branding-bar',
  templateUrl: './lts-branding-bar.component.html',
  styleUrls: ['./lts-branding-bar.component.scss']
})
export class LtsBrandingBarComponent implements OnInit {

  constructor(public currentUserService: CurrentUserService, private router: Router) {}

  ngOnInit(): void {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }

  public get siteName(): string {
    return 'Litigation Tracking System';
  }

  public intro(): void {
    this.router.navigate(['/']);
  }

  public keyboardIntro(event: KeyboardEvent): void {
    if (event instanceof KeyboardEvent) {
      if (event.key.toLowerCase() === 'enter') {
        this.intro();
      }
    }
  }

  public logOut(): void {
    this.currentUserService.logOut(true);
  }

  public isLoggedIn(){
    return this.currentUserService.isLoggedIn;
  }

}
